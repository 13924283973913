import { StylesConfig } from 'react-select';
import palette from './palette';

const font = {
  fontFamily: "'Roboto', sans-serif",
  fontSize: '1.4rem',
  fontWeight: 300,
};

const selectorStyle: StylesConfig = {
  container: (base) => ({
    ...base,
    ...font,
    display: 'inline-block',
    width: '100%',
    marginBottom: '1.4rem',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: `8px`,
  }),
  control: (base) => ({
    ...base,
    ...font,
    boxShadow: 'none',
    border: `1px solid ${palette.lightGray}`,
    borderRadius: `8px`,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: palette.lightGray,
    },
  }),
  multiValue: (base) => ({
    ...base,
    ...font,
    padding: 0,
  }),
  menuList: (base) => ({
    ...base,
    ...font,
    padding: 0,
    borderRadius: `8px`,
  }),
  menu: (base) => ({
    ...base,
    ...font,
    boxShadow: 'none',
    padding: 0,
    border: `1px solid ${palette.lightGray}`,
    borderRadius: `8px`,
  }),
  input: (base) => ({
    ...base,
    ...font,
  }),
};

export default selectorStyle;
