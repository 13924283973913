import React, { useState } from 'react';
import { useFetchEvents, useFetchRegistrationsEventListByUser } from './EventList.hooks';
import EventList from './EventList';
import { asEvent } from '../../../../transformers/event';
import { SpinnerPage } from '../../../../components';
import { useAuth0 } from '../../../../react-auth0-spa';

const EventListController: React.FC = () => {
  const [today] = useState(new Date());
  const { user } = useAuth0();
  const { loading: loadingEventList, error: errorEventList, data: dateEventLis } = useFetchEvents(today);
  const {
    loading: loadingRegistrations,
    error: errorRegistrations,
    data: dataRegistrations,
  } = useFetchRegistrationsEventListByUser(today, user.sub);
  const registratedEventsIds = dataRegistrations?.grad_event_registration?.map((regis) => regis.event.event_id) || [];
  const events = dateEventLis?.recruit_event.map(asEvent);
  if (loadingEventList || loadingRegistrations) return <SpinnerPage />;
  if (errorEventList || errorRegistrations) return <div>Error</div>;
  return <EventList events={events} registratedEvents={registratedEventsIds} />;
};

export default EventListController;
