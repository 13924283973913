import React, { useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import QuerySelector from 'components/QuerySelector';

import { GET_CITY_STATE_SELECTIONS } from 'gql';
import { GetCityStateSelections } from 'generated/GetCityStateSelections';

export interface CityStatePickerOption {
  label: string;
  value: string | null;
  city: string | null;
  state: string | null;
  city_state_selection_id: string | null;
}

export interface CityStatePickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultCity?: string | null;
  defaultState?: string | null;
  defaultValue?: string | null;
  clearable?: boolean;
  required?: boolean;
}

const CityStatePicker: React.FC<CityStatePickerProps> = ({
  name,
  control,
  clearable = false,
  required = false,
  defaultValue = null,
  defaultCity = null,
  defaultState = null,
}) => {
  const [queryCities, { loading: loadingCities, data: dataCities }] = useLazyQuery<GetCityStateSelections>(
    GET_CITY_STATE_SELECTIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const defaultCityOption = useMemo(() => {
    const description = defaultValue
      ? defaultValue
      : `${defaultCity || ''}${(defaultCity || '') && (defaultState || '') && ', '}${defaultState || ''}`;

    return {
      label: description,
      value: description,
    };
  }, [defaultValue, defaultCity, defaultState]);

  const cityOptions = useMemo(
    () =>
      ((dataCities && dataCities.grad_city_state_selection) || []).map(({ city, state, city_state_selection_id }) => ({
        value: `${city}, ${state}`,
        label: `${city}, ${state}`,
        city,
        state,
        city_state_selection_id,
      })),
    [dataCities],
  );

  return (
    <QuerySelector
      searchResults={cityOptions}
      defaultOptions={defaultCityOption}
      loading={loadingCities}
      debounceTime={900}
      callFetchQuery={queryCities}
      name={name}
      control={control}
      isClearable={clearable}
      required={required}
    />
  );
};

export default CityStatePicker;
