import React from 'react';
import DetailViewHeader, { DetailHeaderProps } from '../../../../../components/common/DetailViewHeader';
import { TimeLeft } from './EventDetailHeader.styled';

export interface EventDetailHeaderProps extends DetailHeaderProps {
  fromNowStartTimeStamp: string;
}

const EventDetailHeader: React.FC<EventDetailHeaderProps> = ({ title, logo, tags, fromNowStartTimeStamp }) => {
  return (
    <DetailViewHeader title={title} logo={logo} tags={tags}>
      <TimeLeft>{`Starts ${fromNowStartTimeStamp}`}</TimeLeft>
    </DetailViewHeader>
  );
};

export default EventDetailHeader;
