import styled from 'styled-components';
import { palette } from '../../../theme';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  padding: 2.5rem;
  align-items: center;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: ${palette.clearGray};
  }
`;
export const ChildrenContainer = styled.div`
  margin: 1rem 1rem 1rem 0;
`;
export const BookmarkIcon = styled.div`
  position: absolute;
  padding-right: 10px;
  padding-top: 10px;
  top: 0;
  right: 0;

  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Body = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
`;

export const Info = styled.div`
  flex: 1;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;

  & > span {
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.3rem;
  }
`;

export const Logo = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  border: 2px solid ${palette.watermelon};
`;

export const NavLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-decoration: inherit;
  color: inherit;
`;

export const CardTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface CardTagProps {
  color?: string;
}

export const CardTag = styled.div<CardTagProps>`
  display: inline-block;

  padding: 0.1rem 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.7rem;
  border-radius: 6px;

  background: ${(props) => props.color || palette.blue};

  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${palette.white};
`;
