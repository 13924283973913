import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import { useMutation } from '@apollo/react-hooks';
import { useQuery } from 'react-apollo';

import { CellPhoneInput, CityStatePickerOption, FormValidationMessage } from 'components';
import { EmailPattern } from 'utils/validations';
import SpinnerPage from 'components/SpinnerPage';
import SelectorMultiCreateV2 from 'components/SelectorMultiCreateV2';
import { OptionId, createOptionId } from 'components/Option';
import { notify } from 'utils/notify';

import { CREATE_MY_PROFILE, UPDATE_MY_PROFILE, GET_MY_NAME, WIZARD_RELATED_PROFILE } from './gql';
import { WizardCreateProfile, WizardCreateProfileVariables } from 'generated/WizardCreateProfile';
import { WizardUpdateProfile, WizardUpdateProfileVariables } from 'generated/WizardUpdateProfile';
import { WizardGetProfile, WizardGetProfileVariables } from 'generated/WizardGetProfile';
import { GetStudentProfile_grad_profile_awards } from 'generated/GetStudentProfile';
import { WizardRelatedProfile, WizardRelatedProfileVariables } from 'generated/WizardRelatedProfile';

import { WizardProps } from '../components/interface';
import {
  WizardContainer,
  SUBMIT_GO_TO,
  CANCEL_GO_TO,
  WizardForm,
  WizardFormInputs,
  WizardStepTitle,
  WizardInputLabel,
  WizardInput,
  WizardFormButtons,
} from '../components';
import { AwardsTypes, PROFESSIONAL_DESIGNATIONS_OPTIONS } from 'data/awards';
import RelevantYearsSelector from 'components/RelevantYearsSelector';
import { WantToKnowSelector } from 'components';
import CityStatePicker from '../../../components/CityStatePicker';
import { WizardRequiredInputLabel } from '../components/library';

const DEFAULT_VALUES = {
  fullName: '',
  email: '',
  firstname: '',
  lastname: '',
  mobile: '',
  hometown: '',
  awards: [],
  years_of_relevant_work: null,
  fun_facts: [],
};

interface ProfilePersonalFormVariables {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: string;
  email: string;
  mobile: string;
  hometown: string;
  citySelectionOption: CityStatePickerOption[];
  years_of_relevant_work: { value: string; label: string };
  professionalDesignations: { optionsValue: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  funFacts: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
}

const ProfileStep: React.SFC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
}) => {
  const history = useHistory();
  const { user } = useAuth0();
  const { register, control, handleSubmit, errors, setValue } = useForm<ProfilePersonalFormVariables>();

  const { loading: getProfileLoading, data: getProfileData } = useQuery<WizardGetProfile, WizardGetProfileVariables>(
    GET_MY_NAME,
    {
      fetchPolicy: 'network-only',
      variables: { myUserId: user.sub },
    },
  );

  const [createMyProfile] = useMutation<WizardCreateProfile, WizardCreateProfileVariables>(CREATE_MY_PROFILE);
  const [updateGradProfile] = useMutation<WizardUpdateProfile, WizardUpdateProfileVariables>(UPDATE_MY_PROFILE);
  const [updateRelatedProfile, { loading: updateRelatedProfileLoading }] = useMutation<
    WizardRelatedProfile,
    WizardRelatedProfileVariables
  >(WIZARD_RELATED_PROFILE);

  if (getProfileLoading || updateRelatedProfileLoading) return <SpinnerPage />;

  const myInfo = getProfileData?.grad_profile?.[0] || DEFAULT_VALUES;

  const professionalDesignationsOptions = (myInfo.awards.length ? myInfo.awards : [])
    .filter(
      (award: GetStudentProfile_grad_profile_awards) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION,
    )
    .map((award: GetStudentProfile_grad_profile_awards) => createOptionId(award.award_name, award.award_id));

  const funFactsOptions = (myInfo?.fun_facts.length ? myInfo.fun_facts : []).map((funFact) =>
    createOptionId(funFact.description, funFact.fun_fact_id),
  );

  const onSubmit = async (data: ProfilePersonalFormVariables) => {
    const {
      firstName,
      lastName,
      email,
      mobile,
      years_of_relevant_work,
      professionalDesignations: {
        optionsValue: designationsValue,
        newOptions: newDesignationsOptions,
        deletedOptionsIds: deletedDesignationsIds,
      },
      funFacts: { optionsValue: funFactsOptions, deletedOptionsIds: deletedFunFactsIds }, // HERE
      citySelectionOption,
    } = data;
    const fullName = `${firstName} ${lastName}`;

    const professionalDesignationForUpsert = newDesignationsOptions.map(({ id, value }) => ({
      award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
      award_name: value,
      user_id: user.sub,
      ...(id && { award_id: id }),
    }));

    const funFactsForUpsert = funFactsOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { fun_fact_id: id }),
    }));

    const variables = {
      userId: user.sub,
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      email: email,
      title: designationsValue[0] ? designationsValue[0].value : '',
      mobile: mobile,
      years_of_relevant_work: (years_of_relevant_work || {}).value,
      hometown: citySelectionOption?.[0]?.value || null,
    };

    const updateProfile = async () => {
      await updateRelatedProfile({
        variables: {
          awardsForUpsert: professionalDesignationForUpsert,
          awardIdsForDelete: deletedDesignationsIds,
          funFactsForUpsert: funFactsForUpsert,
          funFactIdsForDelete: deletedFunFactsIds,
        },
      });
      history.push(nextGoTo);
    };

    if (getProfileData && getProfileData.grad_profile[0]) {
      try {
        await updateGradProfile({ variables });
        await updateProfile();
      } catch (error) {
        const queryToastId = 'updateMyProfileErrorEmailRecruiter';
        notify(
          queryToastId,
          'The email address used to sign in is already taken by a recruiter. Please use another to sing in as Student.',
          'top-center',
        );
      }
    } else {
      try {
        await createMyProfile({ variables });
        await updateProfile();
      } catch (error) {
        const queryToastId = 'createMyProfileErrorEmailRecruiter';
        notify(
          queryToastId,
          'The email address used to sign in is already taken by a recruiter. Please use another to sing in as Student.',
          'top-center',
        );
      }
    }
  };

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <WizardForm onSubmit={handleSubmit(onSubmit)}>
        <WizardFormInputs>
          <WizardStepTitle>Personal Information</WizardStepTitle>

          <WizardRequiredInputLabel>First Name</WizardRequiredInputLabel>
          <WizardInput
            type="text"
            name="firstName"
            placeholder="e.g: John"
            ref={register({ required: true })}
            defaultValue={myInfo.firstname || ''}
          />
          {errors.firstName && <FormValidationMessage message="First name is required." />}

          <WizardRequiredInputLabel>Last Name</WizardRequiredInputLabel>
          <WizardInput
            type="text"
            name="lastName"
            placeholder="e.g: Doe"
            ref={register({ required: true })}
            defaultValue={myInfo.lastname || ''}
          />
          {errors.fullName && <FormValidationMessage message="Last name is required." />}

          <WizardRequiredInputLabel>{"What's your email address?"}</WizardRequiredInputLabel>
          <WizardInput
            type="email"
            name="email"
            placeholder="e.g: jdoe@email.com"
            ref={register({ required: true, pattern: EmailPattern })}
            defaultValue={myInfo.email || ''}
          />
          {errors.email && <FormValidationMessage message={errors.email.message || 'A valid email is required.'} />}

          <WizardInputLabel>Cell phone</WizardInputLabel>
          <CellPhoneInput control={control} required={true} defaultValue={myInfo.mobile || null} name="mobile" />
          {errors.mobile && (
            <FormValidationMessage message={errors.mobile.message || 'A valid cell phone number is required.'} />
          )}
          <WizardInputLabel>How many years of relevant work experience/internships do you have?</WizardInputLabel>
          <RelevantYearsSelector
            name={'years_of_relevant_work'}
            defaultValue={myInfo.years_of_relevant_work}
            control={control}
            required={true}
          />
          {errors.years_of_relevant_work && (
            <FormValidationMessage message="Specify your relevant work experience/internship" />
          )}
          <WizardInputLabel>Hometown</WizardInputLabel>
          <CityStatePicker
            defaultValue={myInfo?.hometown}
            name="citySelectionOption"
            control={control}
            clearable
            required={false}
          />

          <WizardInputLabel>Do you have any professional designations?</WizardInputLabel>
          <SelectorMultiCreateV2
            showMenu={true}
            dropDownIndicator={true}
            dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
            defaultOptionsValue={professionalDesignationsOptions}
            name="professionalDesignations"
            placeholder="Professional Designations Earned"
            register={register}
            setValue={setValue}
            required={false}
          />
          {errors.professionalDesignations && (
            <FormValidationMessage message="Add at least one professional designation" />
          )}

          <WizardRequiredInputLabel>What I want an employer to know about me</WizardRequiredInputLabel>
          <WantToKnowSelector name="funFacts" register={register} setValue={setValue} values={funFactsOptions} />
          {errors.funFacts && <FormValidationMessage message="Add at least one thing you want the employer to know" />}
        </WizardFormInputs>
        <WizardFormButtons step={step} isLastStep={isLastStep} cancelSkipGoTo={cancelSkipGoTo} />
      </WizardForm>
    </WizardContainer>
  );
};

export default ProfileStep;
