import React from 'react';
import CompanyList from './CompanyList';
import MainViewLayout from '../../../components/common/MainViewLayout';

const Event: React.FC = () => {
  return (
    <MainViewLayout title="Companies">
      <CompanyList />
    </MainViewLayout>
  );
};

export default Event;
