import React, { useState, ReactElement } from 'react';
import styled from 'styled-components';
import { palette } from 'theme';

import { ChevronDown, ChevronUp } from 'theme/assets';

const COLLAPSED_ICON = ChevronDown;
const EXPANDED_ICON = ChevronUp;
const CollapsibleContainer: React.FC<{ children: ReactElement[] }> = ({ children }) => {
  const [FirstChild, ...rest] = children;
  const isExpandable = children.length >= 2;

  const [expanded, toggleExpanded] = useState(false);

  return (
    <>
      {FirstChild}
      {isExpandable && (
        <>
          <StyledChildrenContainer>{expanded && rest}</StyledChildrenContainer>
          <StyledCollapseOrExpand>
            <StyledIcon src={expanded ? EXPANDED_ICON : COLLAPSED_ICON} onClick={() => toggleExpanded(!expanded)} />
          </StyledCollapseOrExpand>
        </>
      )}
    </>
  );
};

const StyledIcon = styled.img`
  width: 1.6rem;

  vertical-align: middle;
`;

const StyledChildrenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledCollapseOrExpand = styled.div`
  color: ${palette.gray};
  text-align: center;
`;

export default CollapsibleContainer;
