import gql from 'graphql-tag';
import { RaisedHandParts, JobParts, BookMarkPart } from 'gql/fragments.gql';

const query = gql`
  query GetAllJobPostings($userId: String!, $dynamicQuery: recruit_job_posting_bool_exp) {
    recruit_job_posting(where: $dynamicQuery) {
      ...JobParts
      bookmark_jobs(where: { user_id: { _eq: $userId } }) @connection(key: "bookmark_jobs") {
        ...BookMarkPart
      }
      raise_hands(where: { user_id: { _eq: $userId } }) @connection(key: "raise_hands") {
        ...RaisedHandParts
      }
    }
  }
  ${RaisedHandParts}
  ${JobParts}
  ${BookMarkPart}
`;

export default query;
