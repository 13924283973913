import React from 'react';
import DetailViewMainButton, { DetailViewMainButtonProps } from '../DetailViewMainButton';
import { BodyContainer, BodyContent, Container, MainButtonContainer } from './withDetailView.styled';

type DetailViewProps = DetailViewMainButtonProps;

const DetailView = <HP, BP>(Header: React.FC<HP>, Body: React.FC<BP>) => {
  const View: React.FC<HP & BP & DetailViewProps> = (props) => {
    const {
      isButtonOn,
      isButtonVisible,
      onButtonOn,
      onButtonOff,
      buttonTextOn,
      buttonTextOff,
      buttonDisableText,
      isButtonDisable,
      iconButtonOnSrc,
      iconButtonOffSrc,
    } = props;
    return (
      <Container>
        <Header {...(props as HP)} />
        <MainButtonContainer>
          <DetailViewMainButton
            iconButtonOnSrc={iconButtonOnSrc}
            iconButtonOffSrc={iconButtonOffSrc}
            isButtonOn={isButtonOn}
            onButtonOff={onButtonOff}
            onButtonOn={onButtonOn}
            isButtonDisable={isButtonDisable}
            isButtonVisible={isButtonVisible}
            buttonDisableText={buttonDisableText}
            buttonTextOff={buttonTextOff}
            buttonTextOn={buttonTextOn}
          />
        </MainButtonContainer>
        <BodyContainer>
          <BodyContent>
            <Body {...(props as BP)} />
          </BodyContent>
        </BodyContainer>
      </Container>
    );
  };

  return View;
};

export default DetailView;
