import styled from 'styled-components';

export const RemovePhotoButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: inline-block;

  padding: 0.5rem 0.5rem 0 0;
  height: 3rem;
`;

export const TrashIcon = styled.img`
  display: inline-block;

  height: inherit;
`;
