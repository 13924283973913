import gql from 'graphql-tag';

const mutation = gql`
  query GetPhoto($userId: String!) {
    grad_profile(where: { user_id: { _eq: $userId } }) {
      profile_image
      user_id
    }
  }
`;

export default mutation;
