import React, { useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import QuerySelector from 'components/QuerySelector';

import { GET_DEGREE_SELECTIONS } from 'gql';
import { GetDegreeSelections } from 'generated/GetDegreeSelections';

export interface DegreePickerOption {
  label: string;
  value: string | null;
  degree: string;
  degree_level: string;
  description: string | null;
  degree_selection_id: string | null;
}

export interface DegreePickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultLevel?: string | null;
  defaultDegree?: string | null;
  clearable?: boolean;
  required?: boolean;
}

const DegreePicker: React.FC<DegreePickerProps> = ({
  name,
  control,
  clearable = false,
  required = false,
  defaultDegree = null,
  defaultLevel = null,
}) => {
  const [queryDegrees, { loading: loadingDegrees, data: dataDegrees }] = useLazyQuery<GetDegreeSelections>(
    GET_DEGREE_SELECTIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const defaultDegreeOption = useMemo(() => {
    const description = defaultDegree || '';

    return {
      label: description,
      value: description,
      degree: defaultDegree,
      degree_level: defaultLevel,
    };
  }, [defaultDegree, defaultLevel]);

  const degreeOptions = useMemo(
    () =>
      ((dataDegrees && dataDegrees.grad_degree_selection) || []).map(
        ({ degree, degree_level, degree_selection_id, description }) => ({
          value: degree,
          label: degree,
          degree,
          degree_level,
          degree_selection_id,
          description,
        }),
      ),
    [dataDegrees],
  );

  return (
    <QuerySelector
      searchResults={degreeOptions}
      defaultOptions={defaultDegreeOption}
      loading={loadingDegrees}
      debounceTime={900}
      callFetchQuery={queryDegrees}
      name={name}
      control={control}
      isClearable={clearable}
      required={required}
    />
  );
};

export default DegreePicker;
