import { GetEmployeerPreferenceSelection } from 'generated/GetEmployeerPreferenceSelection';
import React from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';
import { GET_EMPLOYER_PREFERENCE } from 'gql';
import { OptionId } from './Option';
import SelectorMultiCreateV2 from './SelectorMultiCreateV2';

interface EmployerPreferenceSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  values: OptionId[];
  name: string;
}

export const EmployerPreferenceSelector: React.FC<EmployerPreferenceSelectorProps> = ({
  values,
  name,
  register,
  setValue,
}) => {
  const { loading, data } = useQuery<GetEmployeerPreferenceSelection>(GET_EMPLOYER_PREFERENCE, {
    errorPolicy: 'all',
    onError: console.warn, // eslint-disable-line no-console
  });
  const selectorOptions = data?.grad_employer_preference_selection.map(({ value }) => ({
    value,
    label: value,
  }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={values}
      name={name}
      placeholder={'Select or type what your employeer preferences'}
      required={true}
      register={register}
      setValue={setValue}
      maxSelection={3}
    />
  );
};
