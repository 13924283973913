import React from 'react';
import { useMutation } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';

import { BookmarkRed, BookmarkLightGray } from 'theme/assets';

import { StyledBookmarkIcon } from './JobBookmark.styled';

import { GET_ALL_JOB_POSTINGS, DELETE_BOOKMARK, INSERT_BOOKMARK } from 'gql';
import { InsertBookmark, InsertBookmarkVariables } from 'generated/InsertBookmark';
import { DeleteBookmark, DeleteBookmarkVariables } from 'generated/DeleteBookmark';
import {
  GetAllJobPostings,
  GetAllJobPostingsVariables,
  GetAllJobPostings_recruit_job_posting_bookmark_jobs,
} from 'generated/GetAllJobPostings';

export interface JobBookmarkProps {
  jobId: string;
  bookmark_jobs: GetAllJobPostings_recruit_job_posting_bookmark_jobs[];
}

const JobBookmark: React.FC<JobBookmarkProps> = ({ jobId, bookmark_jobs }) => {
  const { user } = useAuth0();
  const bookmarked = bookmark_jobs.length > 0;

  const getPostingQuery = { userId: user.sub, dynamicQuery: { job_posting_id: { _eq: jobId } } };

  const [insertBookmarkMutation] = useMutation<InsertBookmark, InsertBookmarkVariables>(INSERT_BOOKMARK, {
    variables: { userId: user.sub, jobId },
    errorPolicy: 'all',
    refetchQueries: ['GetAllJobPostings'],

    update: (cache, { data: insertResultData }) => {
      const jobCache = cache.readQuery<GetAllJobPostings, GetAllJobPostingsVariables>({
        query: GET_ALL_JOB_POSTINGS,
        variables: getPostingQuery,
      });

      if (!insertResultData || !insertResultData.insert_grad_bookmark_job) {
        return;
      }

      if (!jobCache || !jobCache.recruit_job_posting) {
        return;
      }
      const oldJob = jobCache.recruit_job_posting[0];
      const newJob = {
        ...oldJob,
        bookmark_jobs: [...(oldJob.bookmark_jobs || []), ...insertResultData.insert_grad_bookmark_job.returning],
      };

      cache.writeQuery({
        query: GET_ALL_JOB_POSTINGS,
        variables: getPostingQuery,
        data: {
          recruit_job_posting: [newJob],
        },
      });
    },
    onError: () => undefined,
  });

  const [deleteBookmarkMutation] = useMutation<DeleteBookmark, DeleteBookmarkVariables>(DELETE_BOOKMARK, {
    variables: { userId: user.sub, jobId },
    errorPolicy: 'all',
    refetchQueries: ['GetAllJobPostings'],
    update: (cache, { data: deleteResultData }) => {
      const jobCache = cache.readQuery<GetAllJobPostings, GetAllJobPostingsVariables>({
        query: GET_ALL_JOB_POSTINGS,
        variables: getPostingQuery,
      });

      if (!deleteResultData || !deleteResultData.delete_grad_bookmark_job) return;
      if (!jobCache || !jobCache.recruit_job_posting) return;

      const oldJob = jobCache.recruit_job_posting[0];
      const deleteId = deleteResultData.delete_grad_bookmark_job.returning[0].bookmark_job_id;
      const newJob = {
        ...oldJob,
        bookmark_jobs: (oldJob.bookmark_jobs || []).filter(({ bookmark_job_id }) => bookmark_job_id !== deleteId),
      };

      cache.writeQuery({
        query: GET_ALL_JOB_POSTINGS,
        variables: getPostingQuery,

        data: {
          recruit_job_posting: [newJob],
        },
      });
    },
    onError: () => undefined,
  });

  const onClick = bookmarked ? () => deleteBookmarkMutation() : () => insertBookmarkMutation();
  const bookmarkIcon = bookmarked ? BookmarkRed : BookmarkLightGray;

  return <StyledBookmarkIcon onClick={onClick} src={bookmarkIcon} />;
};

export default JobBookmark;
