import React from 'react';
import styled from 'styled-components';

import { AwardsTypes, ACADEMIC_HONORS_OPTIONS } from 'data/awards';
import { convertMonthToString } from 'utils/dateUtils';
import { majorsAsDescription } from 'utils/degreeUtils';
import { palette } from 'theme';
import UserType from 'types/User';
import AcademicHonorTag from './AcademicHonorTag';
import { EditButton } from './library';
import { EGLogoGrayBackground as ProfilePicture } from 'theme/assets';

import { GetMyProfile_grad_profile } from 'generated/GetMyProfile';

import { StudentViewPaths } from '../StudentViewRoutes';

const StyledCardInfo = styled.div`
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin: 1.6rem 1rem;

  color: ${palette.darkGrey};
`;

const StyledInfo = styled.div`
  & > * {
    margin-bottom: 0.4rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledEditButton = styled(EditButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledNameAndTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8rem;
  color: ${palette.black};
`;

const StyledUniversity = styled.div`
  display: flex;
  align-items: baseline;

  & > * {
    margin-right: 0.4rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledUniversityName = styled.div`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  color: ${palette.darkGrey};
  line-height: 1.8rem;
`;

const StyledGraduationYear = styled.div`
  font-size: 1.2rem;
  font-style: normal;
  line-height: 1.8rem;
`;

const StyledMajorAndType = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;

  color: ${palette.darkGrey};
  line-height: 1.8rem;

  text-transform: capitalize;
`;

const StyledUserImage = styled.img`
  max-width: 15.2rem;
  max-height: 15.2rem;
  margin: 0 auto 0.6rem;

  border: 0.4rem solid ${palette.red};
  border-radius: 50%;
`;

export interface CardInfoProps {
  studentProfile: GetMyProfile_grad_profile;
  user: UserType;
}

const CardInfo: React.FC<CardInfoProps> = ({ studentProfile }) => {
  const picture = studentProfile.profile_image || ProfilePicture;
  const degree = studentProfile?.degrees.length ? studentProfile?.degrees[0] : null;
  const universityName = degree && degree.university && degree.university.location_name;
  const nameAndTitle = `${studentProfile.fullname}${studentProfile.title && `, ${studentProfile.title}`}`;

  const majorDegreesDescription = degree ? majorsAsDescription(degree) : '';

  const levelAndMajor = degree && `${degree.degree_level && `${degree.degree_level} in `}${majorDegreesDescription}`;

  const academicHonors = degree?.awards.filter(
    (award) =>
      award.award_category === AwardsTypes.ACADEMIC_HONOR &&
      ACADEMIC_HONORS_OPTIONS.some((option) => option.value === award.award_name),
  );
  const awardNameText = academicHonors?.length ? academicHonors[0].award_name : null;

  const monthYearText = degree
    ? `
    ${convertMonthToString(degree.complete_month_numeric)} \
    ${degree.complete_month_numeric ? ' ' : ''} \
    ${degree.complete_year || ''} \
  `
    : '';

  return (
    <StyledCardInfo>
      <StyledEditButton to={StudentViewPaths.profile} />

      <StyledUserImage src={picture} />

      <StyledInfo>
        <StyledNameAndTitle>{nameAndTitle}</StyledNameAndTitle>
        <StyledMajorAndType>{levelAndMajor}</StyledMajorAndType>
        <StyledUniversity>
          <StyledUniversityName>{universityName}</StyledUniversityName>
          <StyledGraduationYear>{monthYearText}</StyledGraduationYear>
        </StyledUniversity>

        {awardNameText && <AcademicHonorTag awardName={awardNameText} />}
      </StyledInfo>
    </StyledCardInfo>
  );
};

export default CardInfo;
