import React from 'react';
import { FormContextValues } from 'react-hook-form';

import regions from 'data/regions';
import SelectorMulti from 'components/SelectorMulti';
import { OptionId } from 'components/Option';

const regionsOptions = regions.map((region) => ({ value: region, label: region }));

export interface RegionsPickerProps extends Pick<FormContextValues, 'control'> {
  defaultValue: OptionId[] | null;
  name: string;
  required?: boolean;
}

const RegionsPicker: React.FC<RegionsPickerProps> = (props) => {
  return <SelectorMulti options={regionsOptions} {...props} />;
};

export default RegionsPicker;
