import gql from 'graphql-tag';
import { CompanySelectionParts } from './fragments.gql';

const query = gql`
  query GetCompanySelections($searchTerm: String!, $limit: Int = 10) {
    grad_company_selection(where: { company: { _ilike: $searchTerm } }, limit: $limit) {
      ...CompanySelectionParts
    }
  }
  ${CompanySelectionParts}
`;

export default query;
