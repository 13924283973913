import React from 'react';
import DetailViewHeader, { DetailHeaderProps } from '../../../../../components/common/DetailViewHeader';

export type CompanyDetailHeaderProps = DetailHeaderProps;

const EventDetailHeader: React.FC<CompanyDetailHeaderProps> = ({ title, logo, tags }) => {
  return <DetailViewHeader title={title} logo={logo} tags={tags} />;
};

export default EventDetailHeader;
