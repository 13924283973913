import gql from 'graphql-tag';
import { BookMarkPart } from 'gql/fragments.gql';

const query = gql`
  mutation InsertBookmark($userId: String!, $jobId: String!) {
    insert_grad_bookmark_job(objects: { job_posting_id: $jobId, user_id: $userId }) {
      returning {
        ...BookMarkPart
      }
    }
  }
  ${BookMarkPart}
`;

export default query;
