import gql from 'graphql-tag';
import { DegreeParts } from 'gql/fragments.gql';

const query = gql`
  query GetDegree($myDegreeId: String!) {
    grad_degree(where: { degree_id: { _eq: $myDegreeId } }) {
      ...DegreeParts
    }
  }
  ${DegreeParts}
`;

export default query;
