import styled from 'styled-components';
import { palette } from '../../../../../theme';

export const PublishTime = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${palette.gray};
`;

export const Location = styled.div`
  font-size: 1rem;
  line-height: 1.2rem;

  color: ${palette.gray};
`;

export const BookmarkIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  padding-top: 0px;
  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
