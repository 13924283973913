import gql from 'graphql-tag';
import { DegreeSelectionParts } from './fragments.gql';

const query = gql`
  query GetDegreeSelections($searchTerm: String!) {
    grad_degree_selection(where: { degree: { _ilike: $searchTerm } }, limit: 10) {
      ...DegreeSelectionParts
    }
  }
  ${DegreeSelectionParts}
`;

export default query;
