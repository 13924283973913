import styled from 'styled-components';
import { palette } from '../../../../theme';

export const Industry = styled.h1`
  font-weight: bold;
  color: ${palette.gray};
`;

export const ItemContainer = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${palette.gray};
  &:after {
    content: ': ';
  }
`;
