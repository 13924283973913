import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiControlled from './SelectorMultiControlled';
import { OptionId } from './Option';

import { GET_PROGRAMING_SELECTIONS } from 'gql';
import { GetProgrammingLanguageSelections } from 'generated/GetProgrammingLanguageSelections';
import { Spinner } from 'components';

export interface TechnicalLanguageSelectorProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue?: OptionId[];
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
}

const TechnicalLanguageSelector: React.FC<TechnicalLanguageSelectorProps> = ({
  name,
  clearable = false,
  required = false,
  defaultValue = [],
  placeholder = undefined,
  control,
  setValue,
  register,
}) => {
  const { loading, data } = useQuery<GetProgrammingLanguageSelections>(GET_PROGRAMING_SELECTIONS);

  const roleInterestOptions = useMemo(() => {
    return (data?.grad_computer_language_selection || []).map((fi) => ({
      label: fi.computer_language,
      value: fi.computer_language,
      id: fi.computer_language,
    }));
  }, [data]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <SelectorMultiControlled
          name={name}
          options={roleInterestOptions}
          control={control}
          defaultValue={defaultValue}
          isLoading={loading}
          placeholder={placeholder}
          isClearable={clearable}
          required={required}
          setValue={setValue}
          register={register}
        />
      )}
    </>
  );
};

export default TechnicalLanguageSelector;
