import gql from 'graphql-tag';
import { FunFactSelectionParts } from './fragments.gql';

const query = gql`
  query GetFunFactsSelections {
    grad_fun_fact_selection {
      ...FunFactSelectionParts
    }
  }
  ${FunFactSelectionParts}
`;

export default query;
