import React from 'react';
import styled from 'styled-components';
import { palette } from 'theme';
import {
  GetMyTestScores_grad_test_score,
  GetMyTestScores_grad_test_score_test_score_details,
} from 'generated/GetMyTestScores';

export interface TestProps {
  testScore: GetMyTestScores_grad_test_score;
}

const TestScoreCardWithDetails: React.FC<TestProps> = ({ testScore }) => {
  const details = testScore.test_score_details;

  return (
    <StyledTestScoreCard key={testScore.test_score_id}>
      <TestScoreTitle>{testScore.test}</TestScoreTitle>

      <StyledTestScore>
        <Numerator>{testScore.score}</Numerator>
        <Denominator>{`/${testScore.outof}`}</Denominator>
      </StyledTestScore>

      <StyledDetailList>
        {details.map((testDetail: GetMyTestScores_grad_test_score_test_score_details) => (
          <StyledDetail key={testDetail.test_score_detail_id}>
            <StyledDetailTitle>{testDetail.detail}</StyledDetailTitle>
            <StyledDetailScore>{testDetail.score}</StyledDetailScore>
          </StyledDetail>
        ))}
      </StyledDetailList>
    </StyledTestScoreCard>
  );
};

const StyledDetailList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledDetail = styled.div`
  width: 6rem;
  margin-right: 1rem;
`;

const StyledDetailTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2rem;

  text-transform: capitalize;
`;
const StyledDetailScore = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: ${palette.red};
`;

const StyledTestScore = styled.div`
  float: left;

  display: flex;
  flex-direction: column;

  width: 10rem;
`;

const StyledTestScoreCard = styled.div`
  width: 100%;
  height: 8rem;
  margin: 0.6rem 0;
  padding: 0.8rem;
  border-radius: 8px;

  background: ${palette.clearGray};
`;

const TestScoreTitle = styled.div`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2rem;
`;

const Denominator = styled.span`
  width: 5rem;

  font-size: 1.2rem;
  text-align: right;
  color: ${palette.brownGrey};
`;

const Numerator = styled.span`
  font-size: 3.2rem;
  font-weight: 700;
`;

export default TestScoreCardWithDetails;
