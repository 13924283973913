import gql from 'graphql-tag';
import { AwardParts } from './fragments.gql';

const mutation = gql`
  mutation InsertAwards($awards: [grad_award_insert_input!]!) {
    insert_grad_award(
      objects: $awards
      on_conflict: { constraint: award_award_id_key, update_columns: [year_received, award_name, award_category] }
    ) {
      returning {
        ...AwardParts
      }
    }
  }
  ${AwardParts}
`;
export default mutation;
