import React from 'react';
import CompanyDetail from './CompanyDetail';
import { useCompanyQuery } from './Company.hooks';
import { useParams } from 'react-router-dom';
import { asCompany } from '../../../transformers/company';
import { Spinner } from '../../../components';

const CompanyController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: dataCompany, loading: loadingCompany } = useCompanyQuery(+id);
  const rawCompany = dataCompany?.recruit_company_by_pk;
  const company = rawCompany ? asCompany(rawCompany) : null;

  if (!id || !company || loadingCompany) return <Spinner />;
  return (
    <CompanyDetail
      description={company?.description || ''}
      title={company.name}
      logo={company?.logo || ''}
      website={company?.website}
      employeesNumber={company.employeesNumber}
      address={company.address}
    />
  );
};

export default CompanyController;
