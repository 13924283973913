import gql from 'graphql-tag';

const mutation = gql`
  mutation DeleteUploadDocs($userId: String!) {
    delete_grad_upload_doc(where: { user_id: { _eq: $userId } }) {
      returning {
        filename
        hex_content
        upload_doc_id
        user_id
      }
    }
  }
`;

export default mutation;
