import { useQuery } from 'react-apollo';
import { GET_ALL_JOB_POSTINGS } from '../../../../gql';
import { GetAllJobPostings, recruit_job_posting_bool_exp } from '../../../../generated';

export const useJobsQuery = (dynamicQuery: recruit_job_posting_bool_exp | null, userId: string) => {
  return useQuery<GetAllJobPostings>(GET_ALL_JOB_POSTINGS, {
    variables: { userId, ...(dynamicQuery && { dynamicQuery }) },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: () => undefined,
  });
};
