import styled from 'styled-components';
import { palette } from 'theme';

export const Spacing = styled.div((props) => ({
  margin: `calc(1rem * ${props.property}) 0`,
}));

export const CheckboxLabel = styled.div`
  box-sizing: border-box;
  margin: 0 0.8rem 0 0;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGrey};
`;
