import gql from 'graphql-tag';

import { Achievement, Volunteer, ComputerLanguage, SpokenLanguage } from './ExtraStep.fragments.gql';

export const GET_STUDENT_ADITIONAL_INFO = gql`
  query GetProfileAditionalInfo($userID: String!) {
    grad_profile(where: { user_id: { _eq: $userID } }) {
      student_athlete
      achievements {
        ...Achievement
      }
      volunteers {
        ...Volunteer
      }
      computer_languages {
        ...ComputerLanguage
      }
      spoken_languages {
        ...SpokenLanguage
      }
    }
  }
  ${Achievement}
  ${Volunteer}
  ${ComputerLanguage}
  ${SpokenLanguage}
`;

export const UPDATE_STUDENT_ADITIONAL_INFO = gql`
  mutation UpdateStudentProfileAditionalInfo(
    $userID: String!
    $studentAthlete: Boolean!
    $addAchievements: [grad_achievement_insert_input!]!
    $deleteAchievementsIDs: [String!]!
    $addVolunteers: [grad_volunteer_insert_input!]!
    $deleteVolunteersIDs: [String!]!
    $addComputerLanguages: [grad_computer_language_insert_input!]!
    $deleteComputerLanguagesIDs: [String!]!
    $addSpokenLanguages: [grad_spoken_language_insert_input!]!
    $deleteSpokenLanguagesIDs: [String!]!
  ) {
    update_grad_profile(where: { user_id: { _eq: $userID } }, _set: { student_athlete: $studentAthlete }) {
      returning {
        student_athlete
      }
    }

    insert_grad_achievement(
      objects: $addAchievements
      on_conflict: { constraint: achievement_achievement_id_key, update_columns: [description] }
    ) {
      returning {
        ...Achievement
      }
    }

    delete_grad_achievement(where: { achievement_id: { _in: $deleteAchievementsIDs } }) {
      returning {
        ...Achievement
      }
    }

    insert_grad_volunteer(
      objects: $addVolunteers
      on_conflict: { constraint: volunteer_volunteer_id_key, update_columns: [description] }
    ) {
      returning {
        ...Volunteer
      }
    }

    delete_grad_volunteer(where: { volunteer_id: { _in: $deleteVolunteersIDs } }) {
      returning {
        ...Volunteer
      }
    }

    insert_grad_computer_language(objects: $addComputerLanguages) {
      returning {
        ...ComputerLanguage
      }
    }

    delete_grad_computer_language(where: { computer_language_id: { _in: $deleteComputerLanguagesIDs } }) {
      returning {
        ...ComputerLanguage
      }
    }

    insert_grad_spoken_language(
      objects: $addSpokenLanguages
      on_conflict: { constraint: spoken_language_pkey, update_columns: [] }
    ) {
      returning {
        ...SpokenLanguage
      }
    }

    delete_grad_spoken_language(where: { spoken_language_id: { _in: $deleteSpokenLanguagesIDs } }) {
      returning {
        ...SpokenLanguage
      }
    }
  }
  ${Achievement}
  ${Volunteer}
  ${ComputerLanguage}
  ${SpokenLanguage}
`;
