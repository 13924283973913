import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertJobInterests($jobs: [grad_interest_job_type_insert_input!]!) {
    insert_grad_interest_job_type(objects: $jobs) {
      returning {
        job_type
        interest_job_type_id
      }
    }
  }
`;

export default mutation;
