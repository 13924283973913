import React from 'react';
import styled, { css } from 'styled-components';
import { useAuth0 } from 'react-auth0-spa';
import { palette } from 'theme';

interface WizardButtonsProps {
  readonly cancelSkipGoTo?: string;
  readonly isLastStep: boolean;
  readonly step: number;
  onNext?: () => void;
  onSkip?: () => void;
}

export const WizardFormButtons: React.FC<WizardButtonsProps> = ({ isLastStep = false, step, onNext, onSkip }) => {
  const { logout } = useAuth0();

  const getSubmitText = () => {
    if (isLastStep) return 'Finish';
    return 'Save and Continue';
  };

  return (
    <FormWizardStyledButtons>
      <FlexContainer>
        {step === 1 && <LogoutButton onClick={logout}>Cancel and Logout</LogoutButton>}
        {Boolean(onSkip) && <Button onClick={onSkip}>Skip</Button>}
        <Button onClick={onNext}>{getSubmitText()}</Button>
      </FlexContainer>
    </FormWizardStyledButtons>
  );
};

export const FormWizardStyledButtons = styled.div`
  margin: 1.8rem 0 0 0;
  box-shadow: -3px 0px 18px 0px rgba(0, 0, 0, 0.3);
  width: 100%;

  background: ${palette.white};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 0.8rem 0;
  padding: 0 0.8rem;

  & > * {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 340px) {
    flex-direction: row;

    & > * {
      margin-bottom: 0rem;
      margin-right: 1rem;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const BtnStyle = css`
  height: 3.2rem;
  width: 100%;

  border-radius: 13px;
  border: 1px solid ${palette.red};

  line-height: 2;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration-line: none;
`;

const Button = styled.button`
  ${BtnStyle};

  color: ${palette.white};
  background: ${palette.red};
`;

const LogoutButton = styled.button.attrs({
  type: 'button',
})`
  ${BtnStyle};

  color: ${palette.red};
  background: ${palette.white};
`;
