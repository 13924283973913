import styled from 'styled-components';
import { palette } from 'theme';

export const Header = styled.div`
  flex-shrink: 0;

  height: 4.4rem;
  padding: 1.4rem 2rem;

  background: ${palette.red};

  line-height: 1.6rem;
`;

export const HeaderTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${palette.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;

  padding-bottom: 2rem;
`;
