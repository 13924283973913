import gql from 'graphql-tag';
import { InterestRoleSelectionParts } from './fragments.gql';

const query = gql`
  query GetInterestRoleSelections {
    grad_interest_role_selection(limit: 100) {
      ...InterestRoleSelectionParts
    }
  }
  ${InterestRoleSelectionParts}
`;

export default query;
