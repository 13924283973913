import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import { GetWizardComplete, GetWizardCompleteVariables } from 'generated/GetWizardComplete';
import UserType from 'types/User';

import { SpinnerPage } from 'components';
import WizardRoutes from './Wizard/WizardRoutes';
import SplashPage from 'components/SplashPage';
import { GET_WIZARD_COMPLETE } from 'gql';
import { StudentView } from './Student';
import { StudentViewPaths } from './Student/StudentViewRoutes';

export interface StudentRoutesProps {
  user: UserType;
  match?: { path: string };
}

const Routes: React.FC<StudentRoutesProps> = (props) => {
  const emailNotVerified = props.user.email_verified === false;
  const [queryWizard, { loading, data }] = useLazyQuery<GetWizardComplete, GetWizardCompleteVariables>(
    GET_WIZARD_COMPLETE,
    { fetchPolicy: 'no-cache' },
  );

  if (!props.match || loading) return <SpinnerPage />;

  if (!emailNotVerified && !loading && !data) queryWizard({ variables: { myUserId: props.user.sub } });
  const goToWizard = !data || !data.grad_profile.length || !data.grad_profile[0].wizard_completed;

  return (
    <>
      <Switch>
        {emailNotVerified ? (
          <Route component={SplashPage} />
        ) : goToWizard ? (
          <Route component={WizardRoutes} />
        ) : (
          <Route component={StudentView} />
        )}
        <Redirect to={StudentViewPaths.root}></Redirect>
      </Switch>
    </>
  );
};

export default Routes;
