import gql from 'graphql-tag';
import { FillInUniversityParts } from './fragments.gql';

const mutation = gql`
  mutation InsertFillUniversity($userId: String!, $degreeId: String!, $name: String!) {
    insert_grad_degree_fill_in_university(
      objects: { user_id: $userId, degree_id: $degreeId, institution_name: $name }
      on_conflict: { constraint: degree_fill_in_university_degree_id_key, update_columns: institution_name }
    ) {
      returning {
        ...FillInUniversityParts
      }
    }
  }
  ${FillInUniversityParts}
`;
export default mutation;
