import styled from 'styled-components';
import { palette } from 'theme';

export const Button = styled.button`
  height: 3.2rem;
  width: 100%;
  max-width: 42.5rem;

  margin: 2rem 0;

  border-radius: 13px;
  border: 1px solid ${palette.red};

  line-height: 2;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration-line: none;

  color: ${palette.white};
  background: ${palette.red};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  margin: 0 auto;

  padding: 0 2rem;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${palette.darkGrey};
`;
