import React from 'react';
import { FormContextValues } from 'react-hook-form';

import { languagesNames } from 'data/languages';
import SelectorMultiControlled from './SelectorMultiControlled';
import { OptionId } from './Option';

const options = languagesNames.map((name) => ({ label: name, value: name }));

export interface LanguageSelectorProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue: OptionId[] | null;
  required?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  defaultValue = null,
  required = false,
  name,
  control,
  setValue,
  register,
}) => {
  return (
    <SelectorMultiControlled
      name={name}
      options={options}
      control={control}
      defaultValue={defaultValue}
      required={required}
      setValue={setValue}
      register={register}
    />
  );
};

export default LanguageSelector;
