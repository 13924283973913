import React from 'react';
import styled from 'styled-components';

import { EditButton, NewButton } from './library';
import { abbreviateMonth, sortWorkExperiences } from 'utils/dateUtils';

import { GetMyProfile_grad_profile_work_experiences } from 'generated/GetMyProfile';
import CollapsibleContainer from './CollapseDiv';
import { StyledCardTextSmall, StyledCardTextMedium, StyledCardTextLarge, StyledCard } from 'components';
import { StudentViewPaths, worksEditPath } from '../StudentViewRoutes';

export interface WorkProps {
  workExperiences: GetMyProfile_grad_profile_work_experiences[];
}

const WorkExperienceList: React.SFC<WorkProps> = ({ workExperiences }) => {
  return (
    <StyledWorkDetails>
      <StyledWorkExperienceTitle>
        <StyledTitle>Relevant Work Experience/Internship</StyledTitle>
        <NewButton to={StudentViewPaths.worksNew}></NewButton>
      </StyledWorkExperienceTitle>

      <CollapsibleContainer>
        {sortWorkExperiences(workExperiences).map((workExperience) => (
          <StyledCard key={workExperience.work_experience_id}>
            <WorkExperienceItemContent>
              <StyledCardTextLarge>{workExperience.company}</StyledCardTextLarge>
              <StyledCardTextMedium>
                {`${workExperience.position} ${workExperience.division ? ` - ${workExperience.division}` : ''}`}
              </StyledCardTextMedium>
              <StyledCardTextSmall>
                {workExperience.city}
                {workExperience.city && workExperience.state && ', '}
                {workExperience.state}
              </StyledCardTextSmall>
              <StyledCardTextSmall>
                {abbreviateMonth(workExperience.start_month_numeric)} {workExperience.start_year}
                {(workExperience.end_month_numeric || workExperience.end_year) && ' - '}
                {workExperience.end_month_numeric && abbreviateMonth(workExperience.end_month_numeric)}{' '}
                {workExperience.end_year}
              </StyledCardTextSmall>
            </WorkExperienceItemContent>
            <EditButton to={worksEditPath(workExperience.work_experience_id)}> </EditButton>
          </StyledCard>
        ))}
      </CollapsibleContainer>
    </StyledWorkDetails>
  );
};

const StyledWorkDetails = styled.div`
  margin: 1.6rem 1rem;
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;

  line-height: 2.1rem;
`;

const StyledWorkExperienceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  line-height: 1;
`;

const WorkExperienceItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export default WorkExperienceList;
