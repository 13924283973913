import { useQuery, useMutation } from '@apollo/react-hooks';

import { GetProfileAditionalInfo, GetProfileAditionalInfoVariables } from 'generated/GetProfileAditionalInfo';
import {
  UpdateStudentProfileAditionalInfo,
  UpdateStudentProfileAditionalInfoVariables,
} from 'generated/UpdateStudentProfileAditionalInfo';
import { GET_STUDENT_ADITIONAL_INFO, UPDATE_STUDENT_ADITIONAL_INFO } from './gql/ExtraStep.gql';

interface useExtraStepQueriesProps {
  userID: string;
  onCompleted: (data: GetProfileAditionalInfo) => void;
}

export const useExtraStepQueries = ({ userID, onCompleted }: useExtraStepQueriesProps) => {
  const getAditionalInfo = useQuery<GetProfileAditionalInfo, GetProfileAditionalInfoVariables>(
    GET_STUDENT_ADITIONAL_INFO,
    {
      variables: { userID },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted,
    },
  );

  return getAditionalInfo;
};

export const useExtraStepMutations = () => {
  const [updateAditionalInfo] = useMutation<
    UpdateStudentProfileAditionalInfo,
    UpdateStudentProfileAditionalInfoVariables
  >(UPDATE_STUDENT_ADITIONAL_INFO);

  return { updateAditionalInfo };
};
