import React from 'react';
import styled from 'styled-components';

import { palette } from 'theme';
import GoBackLink from 'components/GoBackLink';

interface WizardContainerInterface {
  readonly step: number;
  readonly totalSteps: number;
}

const WizardContainer: React.FC<WizardContainerInterface> = ({ step, totalSteps = 0, children }) => {
  return (
    <StyledWizard>
      <WizardHeader>
        {step !== 1 && <GoBackLink />}
        <StepLegendContainer>
          <StyledLabel>Your sign up progress</StyledLabel>
          <StyledStepBar>
            {[...Array(totalSteps)].map((_val, index) => (
              <StyledStep key={index} active={index < step}></StyledStep>
            ))}
          </StyledStepBar>
        </StepLegendContainer>
      </WizardHeader>
      {children}
    </StyledWizard>
  );
};

interface StyledStepProps {
  active?: boolean;
}

const WizardHeader = styled.div`
  flex-direction: column;

  display: flex;

  height: 11rem;
  width: 100%;
  padding: 1.6rem 2rem;

  line-height: 1.6rem;

  background: ${palette.red};
`;

const StepLegendContainer = styled.div`
  margin-top: auto;
`;

const StyledLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${palette.white};
  text-align: left;
  margin: auto;
`;

const StyledStep = styled.div<StyledStepProps>`
  border-radius: 10px;
  width: 100%;
  height: 0.5rem;

  background: ${(props) => (props.active ? palette.white : palette.darkRed)};
`;

const StyledStepBar = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  height: 2.1rem;
  margin: auto;

  > * {
    margin-right: 2rem;
  }

  > :last-child {
    margin-right: 0rem;
  }
`;

const StyledWizard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  text-align: left;
`;

export default WizardContainer;
