import gql from 'graphql-tag';

const query = gql`
  query GetStudentGPA($userId: String!) {
    grad_degree(where: { user_id: { _eq: $userId } }) {
      gpa
      user_id
    }
  }
`;

export default query;
