import { Link } from 'react-router-dom';

import { palette } from 'theme';
import styled from 'styled-components';

const DefaultButton = styled.button`
  font-weight: 600;
  font-size: 1.4rem;
  border-radius: 13px;
  height: 3.2rem;
`;

export const PrimaryButton = styled(DefaultButton)`
  border: 1px solid ${palette.white};
  color: ${palette.white};
  background: ${palette.red};
`;

export const SecondaryButton = styled(DefaultButton)`
  border: 1px solid ${palette.red};
  color: ${palette.red};
  background: ${palette.white};
`;

export const LogoutButton = styled(PrimaryButton)`
  margin: 4vh;
  width: 70%;
  max-width: 36rem;
`;

export const SaveButton = styled(PrimaryButton).attrs({
  type: 'submit',
})`
  min-width: 45%;
  height: 3.2rem;
  padding: 0 1.3rem;

  border-radius: 10px;
`;

export const DefaultLink = styled(Link)`
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration-line: none;
  border: 1px 1px 1px 1px;
`;

export const SecondaryLink = styled(DefaultLink)`
  border: 1px solid ${palette.red};
  border-radius: 13px;
  color: ${palette.red};
  background: ${palette.white};
`;

export const CancelSkipLink = styled(SecondaryLink)`
  min-width: 45%;
  height: 3.2rem;
  margin: 0 0.8rem;
  border: 1px solid ${palette.red};
  line-height: 2;
  text-align: center;
`;

export const CancelButton = styled(SecondaryButton).attrs({
  type: 'button',
})`
  min-width: 45%;
  height: 3.2rem;
  padding: 0 1.3rem;

  border-radius: 10px;
`;

export const EliteGradLogo = styled.img``;

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 100%;
  min-height: 5.6rem;
  margin: 0.6rem 0;
  padding: 0.8rem;
  padding-right: 0;
  border-radius: 8px;

  background: ${palette.clearGray};
`;

export const StyledCardTextSmall = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${palette.brownGrey};
`;

export const StyledCardTextLarge = styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: 500;
  color: ${palette.red};
`;

export const StyledCardTextMedium = styled.div`
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: ${palette.black};
`;

export const StyledHeaderTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${palette.white};
`;

export const StyledHeader = styled.div`
  flex-shrink: 0;

  height: 4.4rem;
  padding: 1.4rem 2rem;

  background: ${palette.red};

  line-height: 1.6rem;
`;

export const StyledLeftH1 = styled.h1`
  margin: 1.6rem 2rem;

  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: left;
  color: ${palette.red};
`;

export const StyledForm = styled.form`
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  height: 100%;
`;

export const StyledFormInputs = styled.div`
  margin: 1.6rem 2rem;

  flex-grow: 1;
  overflow: auto;
`;

export const StyledInputLabel = styled.label`
  display: block;

  margin-bottom: 0.8rem;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGrey};
`;

export const StyledInputSmallLabel = styled(StyledInputLabel)`
  margin-bottom: 0;

  font-size: 1.2rem;
  font-weight: 500;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 3.3rem;

  margin-bottom: 1.4rem;
  padding: 0.9rem;

  border: 1px solid ${palette.lightGray};

  border-radius: 9px;

  font-size: 1.4rem;
  font-weight: 300;

  line-height: 1.9rem;

  ::placeholder {
    color: ${palette.lightGray};
  }

  :focus {
    outline: none;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;

  padding: 1.6rem 0;
  align-items: center;

  & > label {
    margin-bottom: 0;
    line-height: 1.5em;
  }
`;
export const StyledCheckbox = styled.input`
  height: 1.6rem;
  width: 1.6rem;
  display: inline-block;

  margin: 0 0.5em 0 0;
`;

export const StyledButtons = styled.div`
  flex-shrink: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  height: 6.4rem;
  padding: 0 2rem;
  box-shadow: -3px 0px 18px 0px rgba(0, 0, 0, 0.3);
  background: ${palette.white};
`;

export const StyledDeleteButton = styled.a`
  display: block;

  width: 100%;
  margin: 2.6rem 0rem;

  color: ${palette.red};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export const StyledNoWrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;

  padding-bottom: 2rem;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormValidationContainer = styled(ErrorMessageContainer)`
  margin-top: 1rem;
`;

export const StyledErrorIcon = styled.img`
  width: 2rem;
  margin-right: 1rem;
`;
