import styled, { css } from 'styled-components';
import { palette } from '../../../theme';
const StyledButton = styled.button`
  width: 100%;

  border-radius: 10px;
  border: 1px solid ${palette.red};

  font-size: 1.6rem;
  line-height: 4rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const StyledLowerHand = styled(StyledButton)`
  background: ${palette.red};
  color: ${palette.white};
`;

export const StyledRaiseHand = styled(StyledButton)`
  background: ${palette.white};
  color: ${palette.red};
`;

export const HandIcon = styled.img`
  display: inline-block;

  margin-right: 0.8rem;

  vertical-align: middle;
`;
