import gql from 'graphql-tag';
import { AwardParts } from './fragments.gql';

const mutation = gql`
  mutation DeleteAwards($ids: [String!]!) {
    delete_grad_award(where: { award_id: { _in: $ids } }) {
      returning {
        ...AwardParts
      }
    }
  }
  ${AwardParts}
`;

export default mutation;
