import gql from 'graphql-tag';
import { WorkExperienceParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation InsertMyWork(
    $myUserId: String!
    $company: String
    $position: String
    $division: String
    $jobType: String
    $startMonth: Int
    $startYear: numeric
    $current: Boolean
    $endMonth: Int
    $endYear: numeric
    $city: String
    $state: String
  ) {
    insert_grad_work_experience(
      objects: {
        user_id: $myUserId
        company: $company
        job_type: $jobType
        position: $position
        division: $division
        start_month_numeric: $startMonth
        start_year: $startYear
        end_month_numeric: $endMonth
        end_year: $endYear
        current: $current
        city: $city
        state: $state
      }
    ) {
      returning {
        ...WorkExperienceParts
      }
    }
  }
  ${WorkExperienceParts}
`;

export default mutation;
