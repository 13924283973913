import gql from 'graphql-tag';
import { WorkExperienceParts } from 'gql/fragments.gql';

const query = gql`
  query GetMyWork($myWorkId: String!) {
    grad_work_experience(where: { work_experience_id: { _eq: $myWorkId } }) {
      ...WorkExperienceParts
    }
  }
  ${WorkExperienceParts}
`;

export default query;
