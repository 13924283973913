import React from 'react';
import EventList from './EventList';
import MainViewLayout from '../../../components/common/MainViewLayout';

const EventListView: React.FC = () => {
  return (
    <MainViewLayout title="Events">
      <EventList />
    </MainViewLayout>
  );
};

export default EventListView;
