import gql from 'graphql-tag';
import { ConcentrationPart } from './fragments.gql';

const mutation = gql`
  mutation DeleteConcentrations($ids: [String!]!) {
    delete_grad_concentration(where: { concentration_id: { _in: $ids } }) {
      returning {
        ...ConcentrationPart
      }
    }
  }
  ${ConcentrationPart}
`;

export default mutation;
