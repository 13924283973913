import gql from 'graphql-tag';

const query = gql`
  mutation InsertPageView($userId: String!, $pathName: String, $json: jsonb) {
    insert_grad_page_view(objects: { user_id: $userId, pathname: $pathName, json: $json }) {
      returning {
        page_view_id
      }
    }
  }
`;

export default query;
