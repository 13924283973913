import gql from 'graphql-tag';
import { DegreeParts } from 'gql/fragments.gql';

const query = gql`
  query WizardGetMyLastDegree($userId: String!) {
    grad_degree(where: { user_id: { _eq: $userId } }, limit: 1) {
      ...DegreeParts
    }
  }
  ${DegreeParts}
`;

export default query;
