export const StudentViewPaths = {
  root: '/app/',
  home: '/app/home',
  jobs: '/app/jobs',
  job: '/app/jobs/:id',
  companies: '/app/companies',
  company: '/app/companies/:id',
  events: '/app/events',
  event: '/app/events/:id',
  profile: '/app/profile',
  profileCV: '/app/profile/cv',
  profilePhoto: '/app/profile/photo',
  testScoresEdit: '/app/tests/edit',
  degrees: '/app/degrees',
  degreeNew: '/app/degree/new',
  degreeEdit: '/app/degrees/:id',
  works: '/app/works',
  worksNew: '/app/works/new',
  worksEdit: '/app/works/:id',
};

export const jobPath = (id: string) => `${StudentViewPaths.jobs}/${id}`;
export const companyPath = (id: number) => `${StudentViewPaths.companies}/${id}`;
export const eventsPath = (id: string) => `${StudentViewPaths.events}/${id}`;
export const degreeEditPath = (id: string) => `${StudentViewPaths.degrees}/${id}`;
export const worksEditPath = (id: string) => `${StudentViewPaths.works}/${id}`;
