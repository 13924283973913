import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useQuery } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { useAuth0 } from 'react-auth0-spa';

import { FormValidationMessage } from 'components';
import SpinnerPage from 'components/SpinnerPage';

import { GET_MY_LAST_DEGREE, INSERT_MY_DEGREE, UPDATE_MY_DEGREE } from './gql';
import { WizardInsertMyDegree, WizardInsertMyDegreeVariables } from 'generated/WizardInsertMyDegree';
import { WizardUpdateMyDegree, WizardUpdateMyDegreeVariables } from 'generated/WizardUpdateMyDegree';
import { WizardGetMyLastDegree, WizardGetMyLastDegreeVariables } from 'generated/WizardGetMyLastDegree';

import GPAInput from 'components/GPAInput';
import { MonthYearPicker } from 'components/MonthYearPicker';
import DegreePicker, { DegreePickerOption } from 'components/DegreePicker';
import { WizardProps } from '../components/interface';
import {
  WizardContainer,
  SUBMIT_GO_TO,
  CANCEL_GO_TO,
  WizardForm,
  WizardFormInputs,
  WizardStepTitle,
  StepLegend,
  WizardCheckBoxRow,
  WizardCheckBox,
  WizardFormButtons,
} from '../components';
import { InsertConcentrations, InsertConcentrationsVariables } from 'generated/InsertConcentrations';
import { INSERT_CONCENTRATIONS, DELETE_CONCENTRATIONS, INSERT_FILL_UNIVERSITY } from '../../../gql';
import { DeleteConcentrations, DeleteConcentrationsVariables } from 'generated/DeleteConcentrations';
import { MajorSelector } from 'components/MajorMinorSelector/MajorSelector';
import { OptionId } from 'components/Option';
import { ConcentrationTypes } from 'data/degree';
import {
  prepareConcentrationForUpsert,
  createConcentrationOptions,
} from 'components/MajorMinorSelector/concentrationUtils';
import UniversitySelectorCreate from 'components/UniversitySelector/UniversitySelectorCreate';
import { InsertFillUniversity, InsertFillUniversityVariables } from 'generated/InsertFillUniversity';
import { WizardRequiredInputLabel } from '../components/library';

interface DegreeFormVariables {
  gradEndMonth: { value: number; label: string };
  gradEndYear: { value: number; label: string };
  GPA: string;
  gpaOutOf: { value: number; label: string };
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  completed: boolean;
  majors: { optionsValue: OptionId[] | null; deletedOptionsIds: string[] | null };
  gpaNotApplicable: boolean;
}

const AcademicStep: React.SFC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  totalSteps,
  step,
}) => {
  const { register, setValue, handleSubmit, watch, errors, control } = useForm<DegreeFormVariables>();

  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');
  const history = useHistory();
  const {
    user: { sub: myUserId },
  } = useAuth0();

  const [insertMyDegree] = useMutation<WizardInsertMyDegree, WizardInsertMyDegreeVariables>(INSERT_MY_DEGREE, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateMyDegree] = useMutation<WizardUpdateMyDegree, WizardUpdateMyDegreeVariables>(UPDATE_MY_DEGREE, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertFillUniversity] = useMutation<InsertFillUniversity, InsertFillUniversityVariables>(
    INSERT_FILL_UNIVERSITY,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const { loading: loadingMyDegree, error: errorMyDegree, data: dataMyDegree } = useQuery<
    WizardGetMyLastDegree,
    WizardGetMyLastDegreeVariables
  >(GET_MY_LAST_DEGREE, {
    fetchPolicy: 'network-only',
    variables: { userId: myUserId },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertConcentrations] = useMutation<InsertConcentrations, InsertConcentrationsVariables>(
    INSERT_CONCENTRATIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const [deleteConcentrations] = useMutation<DeleteConcentrations, DeleteConcentrationsVariables>(
    DELETE_CONCENTRATIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  if (loadingMyDegree) return <SpinnerPage />;
  if (errorMyDegree) return <div>Error</div>;

  const degreeId = dataMyDegree && dataMyDegree.grad_degree[0] && dataMyDegree.grad_degree[0].degree_id;
  const defaultDegreeValues = {
    university: { university_selection_id: '', location_name: '' },
    complete_year: null,
    complete_month_numeric: null,
    degree_level: '',
    gpa: null,
    gpa_outof: null,
    degree: '',
    completed: false,
    concentrations: [],
    gpa_not_applicable: false,
    fill_in_university: { institution_name: '' },
  };
  const degreeDetails = (dataMyDegree && dataMyDegree.grad_degree[0]) || defaultDegreeValues;
  const {
    university: myUniversity = defaultDegreeValues.university,
    complete_year,
    complete_month_numeric,
    gpa,
    gpa_outof,
    completed,
    concentrations,
    gpa_not_applicable = defaultDegreeValues.gpa_not_applicable,
    degree = defaultDegreeValues.degree,
    degree_level = defaultDegreeValues.degree_level,
    fill_in_university = defaultDegreeValues.fill_in_university,
  } = degreeDetails;
  const universityName = myUniversity ? myUniversity.location_name : '';
  const universityId = myUniversity ? myUniversity.university_selection_id : '';
  const fillInUniversity = fill_in_university ? fill_in_university.institution_name : '';

  const majorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MAJOR);

  const onSubmit = async (data: DegreeFormVariables) => {
    const {
      gradEndMonth,
      gradEndYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA = watchedNotApplicable ? null : data.GPA,
      gpaOutOf = watchedNotApplicable ? null : { value: data.gpaOutOf },
      majors: { optionsValue: optionsMajors, deletedOptionsIds: deletedMajorsIds },
      degreeSelectionOption,
      completed,
      gpaNotApplicable,
    } = data;
    const variables = {
      gradEndMonth: gradEndMonth.value,
      gradEndYear: gradEndYear.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degreeSelectionOption?.[0]?.degree || '',
      degreeLevel: degreeSelectionOption?.[0]?.degree_level || '',
      completed,
      gpaNotApplicable,
    };
    let insertDegreeResult;
    if (!degreeId) {
      insertDegreeResult = await insertMyDegree({ variables: { myUserId, ...variables } });
    } else {
      await updateMyDegree({ variables: { degreeId, ...variables } });
    }

    const persistedDegreeId =
      insertDegreeResult && insertDegreeResult.data && insertDegreeResult.data.insert_grad_degree
        ? insertDegreeResult.data.insert_grad_degree.returning[0].degree_id
        : degreeId;

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (persistedDegreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId: persistedDegreeId, userId: myUserId };
        await insertFillUniversity({ variables: fillInUniversity });
      } else {
      }
      if (optionsMajors) {
        const majorsForUpsert = prepareConcentrationForUpsert(
          optionsMajors,
          persistedDegreeId,
          myUserId,
          persistedDegreeId,
          ConcentrationTypes.MAJOR,
        );
        await insertConcentrations({ variables: { concentrations: majorsForUpsert } });
      }
    }
    if (deletedMajorsIds) await deleteConcentrations({ variables: { ids: deletedMajorsIds } });

    history.push(nextGoTo);
  };
  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <WizardForm onSubmit={handleSubmit(onSubmit)}>
        <WizardFormInputs>
          <WizardStepTitle>{'Academic information'}</WizardStepTitle>
          <StepLegend>
            <div>{'Create your account with your latest undergrad degree information. '}</div>
            <div>
              {
                'You will be able to add additional information to your extended profile after you have set up your account. '
              }
            </div>
          </StepLegend>
          <WizardRequiredInputLabel>{'Name of College or University'}</WizardRequiredInputLabel>

          <UniversitySelectorCreate
            required={true}
            fillInValue={fillInUniversity}
            value={{ id: universityId, label: universityName, value: universityName }}
            name={'universitySelectionIdOption'}
            control={control}
          ></UniversitySelectorCreate>

          {errors.universitySelectionIdOption && <FormValidationMessage message="University is required." />}
          <WizardCheckBoxRow>
            <WizardCheckBox type="checkbox" name="completed" ref={register} defaultChecked={!!completed} />
            {'Currently Enrolled'}
          </WizardCheckBoxRow>
          <WizardRequiredInputLabel>{"What's your graduation date?"}</WizardRequiredInputLabel>
          <MonthYearPicker
            required={true}
            monthFieldName="gradEndMonth"
            yearFieldName="gradEndYear"
            errors={errors}
            control={control}
            defaultMonth={complete_month_numeric}
            defaultYear={complete_year}
          />
          <WizardRequiredInputLabel>{"What's your Degree?"}</WizardRequiredInputLabel>
          <DegreePicker
            name={'degreeSelectionOption'}
            control={control}
            defaultDegree={degree}
            defaultLevel={degree_level}
            clearable
            required={true}
          />
          {errors.degreeSelectionOption && <FormValidationMessage message="Degree is required." />}
          <WizardCheckBoxRow>
            <WizardCheckBox
              type="checkbox"
              name="gpaNotApplicable"
              ref={register}
              defaultChecked={!!gpa_not_applicable}
            />
            {'GPA is not applicable'}
          </WizardCheckBoxRow>
          {!watchedNotApplicable && (
            <GPAInput
              required={true}
              defaultOutOf={gpa_outof}
              defaultGPA={gpa ? gpa : null}
              watchedOutOf={watchedOutOf}
              gpaFieldName="GPA"
              outOfFieldName="gpaOutOf"
              control={control}
              register={register}
            />
          )}
          {!watchedNotApplicable && errors.GPA && <FormValidationMessage message={errors.GPA.message || ''} />}
          <WizardRequiredInputLabel>Major</WizardRequiredInputLabel>
          <MajorSelector
            required={true}
            majorsValue={majorOptions}
            name={'majors'}
            register={register}
            setValue={setValue}
          />
          {errors.majors && <FormValidationMessage message="Major is required." />}
        </WizardFormInputs>
        <WizardFormButtons step={step} isLastStep={isLastStep} cancelSkipGoTo={cancelSkipGoTo} />
      </WizardForm>
    </WizardContainer>
  );
};

export default AcademicStep;
