import React from 'react';
import styled from 'styled-components';

import { ErrorExclamation } from 'theme/assets';
import { StyledErrorIcon } from 'components';
import { FieldError } from 'react-hook-form/dist/types';

export const DEFAULT_MESSAGE = 'This field requires input';

export const FormValidationMessage: React.SFC<{ message: string }> = ({ message }) => (
  <FormValidationContainer>
    <StyledErrorIcon src={ErrorExclamation} alt="logo" />
    {message}
  </FormValidationContainer>
);

export const generateErrorMessages = (error: FieldError | undefined) => {
  if (!error || !error.message) return;
  return <FormValidationMessage message={error.message} />;
};

export const FormValidationContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0.2rem 0 2.4rem 0;
  box-sizing: border-box;
`;
