import gql from 'graphql-tag';

import { EventParts } from '../../../../gql/fragments.gql';

export const GET_EVENT_LIST = gql`
  query GetEventListActive($today: timestamptz) {
    recruit_event(
      where: { show_event: { _eq: true }, end_timestamp: { _gte: $today } }
      order_by: { start_timestamp: asc }
    ) {
      ...EventParts
    }
  }
  ${EventParts}
`;

export const GET_REGISTRATIONS_EVENT_LIST_ACTIVE_BY_USER = gql`
  query GetRegistrationsEventListActiveByUser($today: timestamptz, $userId: String) {
    grad_event_registration(where: { event: { end_timestamp: { _gte: $today } }, user_id: { _eq: $userId } }) {
      event {
        event_id
      }
    }
  }
`;
