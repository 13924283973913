import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import styled, { css } from 'styled-components';

import { ButtonLight, FileInput, HorizontalSeparator, SaveButton, Spinner, TextInput } from 'components';
import { iOSNativeDownloadResume, requiresIOSNativeDownloadResume } from 'utils/iosUtils';
import { toBase64 } from 'utils/fileUtils';
import GoBackHeader from 'components/GoBackHeader';
import { StyledNoWrapContainer } from 'components/library';

import { DELETE_UPLOAD_DOCS, GET_UPLOAD_DOC, INSERT_UPLOAD_DOC, UPDATE_UPLOAD_DOC } from 'gql';
import { DeleteUploadDocs, DeleteUploadDocsVariables } from 'generated/DeleteUploadDocs';
import { GetUploadDoc, GetUploadDocVariables } from 'generated/GetUploadDoc';
import { InsertUploadDoc, InsertUploadDocVariables } from 'generated/InsertUploadDoc';
import { UpdateUploadDoc, UpdateUploadDocVariables } from 'generated/UpdateUploadDoc';
import { ButtonsProps } from 'views/Wizard/ResumeStep/ResumeStep';
import { palette } from '../../../theme';

const StyledButtonLight = styled(ButtonLight)`
  min-width: unset;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisclaimerContainer = styled.div`
  padding: 0 1rem 1rem 1rem;
  font-size: 1.2rem;
  color: ${palette.gray};
`;

const StyledCurrentResume = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 2rem 1.6rem;

  > * {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledHorizontalSeparator = styled(HorizontalSeparator)`
  margin: 1rem 0;
`;

const StyledSaveButton = styled(SaveButton)`
  margin: 0 auto;

  :focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const StyledTitle = styled.div`
  margin: 1.6rem 2rem;

  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;

  line-height: 1.8rem;
`;

const StyledFileInput = styled(FileInput)`
  margin: 0 2rem 1.6rem;
`;

interface ResumeUploaderProps {
  isSubComponent?: boolean;
  WizardButtons: React.FC<ButtonsProps>;
  nextPage: string;
}

const ResumeUploader: React.FC<ResumeUploaderProps> = ({ isSubComponent, WizardButtons, nextPage }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();

  const {
    user: { sub: userId },
  } = useAuth0();

  const history = useHistory();

  const [newFile, setNewFile] = useState<File | null>();

  const { loading, data } = useQuery<GetUploadDoc, GetUploadDocVariables>(GET_UPLOAD_DOC, {
    variables: { userId },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [deleteUploadDocsMutation] = useMutation<DeleteUploadDocs, DeleteUploadDocsVariables>(DELETE_UPLOAD_DOCS, {
    update: (cache) => {
      cache.writeQuery({
        query: GET_UPLOAD_DOC,
        variables: { userId },
        data: { grad_upload_doc: [] },
      });
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [insertUploadDocMutation] = useMutation<InsertUploadDoc, InsertUploadDocVariables>(INSERT_UPLOAD_DOC, {
    update: (cache, { data: mutationData }) => {
      if (mutationData && mutationData.insert_grad_upload_doc) {
        cache.writeQuery({
          query: GET_UPLOAD_DOC,
          variables: { userId },
          data: { grad_upload_doc: mutationData.insert_grad_upload_doc.returning },
        });
      }
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateUploadDocMutation] = useMutation<UpdateUploadDoc, UpdateUploadDocVariables>(UPDATE_UPLOAD_DOC, {
    update: (cache, { data: mutationData }) => {
      if (mutationData && mutationData.update_grad_upload_doc) {
        cache.writeQuery({
          query: GET_UPLOAD_DOC,
          variables: { userId },
          data: { grad_upload_doc: mutationData.update_grad_upload_doc.returning },
        });
      }
    },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const gradUploadDoc = data && data.grad_upload_doc.length ? data.grad_upload_doc[0] : null;

  const handleOnClickDownload = () => {
    if (!gradUploadDoc) {
      return;
    }

    const { hex_content: content, filename } = gradUploadDoc;

    if (requiresIOSNativeDownloadResume()) {
      iOSNativeDownloadResume(content, filename);
    } else {
      const a = document.createElement('a');
      a.href = content;
      a.download = filename;
      a.click();
    }
  };

  const onSubmit = async () => {
    if (!newFile) {
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    const hexContent = await toBase64(newFile);

    const { grad_upload_doc: [{ upload_doc_id } = { upload_doc_id: null }] = [] } = data || {};

    if (upload_doc_id) {
      await updateUploadDocMutation({
        variables: {
          uploadDocId: upload_doc_id,
          fileName: newFile.name,
          hexContent,
        },
      });
    } else {
      await insertUploadDocMutation({
        variables: {
          fileName: newFile.name,
          hexContent,
          userId,
        },
      });
    }

    setNewFile(null);
    !isSubComponent ? history.goBack() : history.push(nextPage);
  };

  const handleOnClickDelete = () => {
    deleteUploadDocsMutation({
      variables: { userId },
    });
  };

  return (
    <StyledNoWrapContainer>
      {!isSubComponent && <GoBackHeader />}
      {loading ? (
        <Spinner />
      ) : (
        <StyledContent>
          <StyledTitle>Current Resume{isSubComponent && `: ${gradUploadDoc?.filename || ''}`}</StyledTitle>
          {!isSubComponent && (
            <>
              <StyledCurrentResume>
                <TextInput value={gradUploadDoc ? gradUploadDoc.filename : ''} disabled />
                <StyledButtonLight text="Download" disabled={!gradUploadDoc} onClick={handleOnClickDownload} />
                <ButtonLight text="Delete" disabled={!gradUploadDoc} onClick={handleOnClickDelete} />
              </StyledCurrentResume>
              <StyledHorizontalSeparator />
            </>
          )}
          <StyledTitle>Upload Resume</StyledTitle>
          <StyledFileInput inputRef={fileInputRef} fileName={newFile && newFile.name} onChange={setNewFile} />
          <DisclaimerContainer>
            We recommend uploading a CV. Candidates without a CV are not shown to recruiters.
          </DisclaimerContainer>
          {!isSubComponent ? (
            <StyledSaveButton onClick={onSubmit} disabled={!newFile}>
              Upload
            </StyledSaveButton>
          ) : (
            <WizardButtons onNext={onSubmit} onSkip={() => history.push(nextPage)} />
          )}
        </StyledContent>
      )}
    </StyledNoWrapContainer>
  );
};

export default ResumeUploader;
