import React from 'react';
import { FormContextValues } from 'react-hook-form';

import { convertMonthToString } from 'utils/dateUtils';
import Selector from './Selector';
import { OptionMonth } from './Option';

const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

interface MonthPickerProps extends Pick<FormContextValues, 'control'> {
  required: boolean;
  defaultMonth: number | null;
  name: string;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({ defaultMonth, control, name, required }) => {
  const createMonthOption = (month: number): OptionMonth => ({
    value: month,
    label: convertMonthToString(month),
  });

  const options = MONTHS.map((month: number) => createMonthOption(month));

  return (
    <Selector
      options={options}
      defaultValue={defaultMonth ? createMonthOption(defaultMonth) : null}
      name={name}
      control={control}
      placeholder="Select Month"
      required={required}
    />
  );
};
