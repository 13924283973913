import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiControlled from './SelectorMultiControlled';
import { OptionId } from './Option';

import { GET_ROLE_INTEREST_SELECTIONS } from 'gql';
import { GetInterestRoleSelections } from 'generated/GetInterestRoleSelections';

export interface RoleInterestPickerProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  name: string;
  defaultValue?: OptionId[];
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
}

const RoleInterestPicker: React.FC<RoleInterestPickerProps> = ({
  name,
  clearable = false,
  required = false,
  defaultValue = [],
  placeholder = undefined,
  control,
  setValue,
  register,
}) => {
  const { loading: loadingRoleInterests, data: dataRoleInterests } = useQuery<GetInterestRoleSelections>(
    GET_ROLE_INTEREST_SELECTIONS,
  );

  const roleInterestOptions = useMemo(() => {
    if (!dataRoleInterests) {
      return [];
    }

    return (dataRoleInterests?.grad_interest_role_selection || []).map((fi) => ({
      label: fi.value,
      value: fi.value,
      id: fi.interest_role_selection_id,
    }));
  }, [dataRoleInterests]);

  return (
    <SelectorMultiControlled
      name={name}
      options={roleInterestOptions}
      control={control}
      defaultValue={defaultValue}
      isLoading={loadingRoleInterests}
      placeholder={placeholder}
      isClearable={clearable}
      required={required}
      setValue={setValue}
      register={register}
      maxSelection={3}
    />
  );
};

export default RoleInterestPicker;
