import React from 'react';
import { Card } from '../../../../components/common/CardList';
import moment from 'moment';
import { ItemContainer, Label, TimeLeft, RegisteredTag } from './EventListCard.styled';
import { eventsPath } from '../../StudentViewRoutes';

interface EventProps {
  title: string;
  id: string;
  image: string | null;
  eventType: string;
  endTimestamp: Date;
  startTimestamp: Date;
  capacity: number;
  isRegistrated: boolean;
}

const EventListCard: React.FC<EventProps> = ({
  title,
  image,
  capacity,
  endTimestamp,
  startTimestamp,
  eventType,
  id,
  isRegistrated,
}) => {
  const tags = [{ text: eventType }];
  return (
    <Card header={title} cardLink={eventsPath(id)} tags={tags} logo={image}>
      {isRegistrated && <RegisteredTag>Subscribed</RegisteredTag>}
      <ItemContainer>
        <Label>Starts: </Label> {moment(startTimestamp).format('MM/DD/YYYY hh:mm A')}
      </ItemContainer>
      <ItemContainer>
        <Label>Ends: </Label> {moment(endTimestamp).format('MM/DD/YYYY hh:mm A')}
      </ItemContainer>

      {capacity > 0 && (
        <ItemContainer>
          <Label>Capacity: </Label> {capacity}
        </ItemContainer>
      )}
      <TimeLeft>{`Starts ${moment(startTimestamp).fromNow()}`}</TimeLeft>
    </Card>
  );
};

export default EventListCard;
