import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertEmployerPreference($preferences: [grad_employer_preference_insert_input!]!) {
    insert_grad_employer_preference(objects: $preferences) {
      returning {
        employer_preference_id
        preference
      }
    }
  }
`;

export default mutation;
