import React from 'react';

export interface JobDetailContentProps {
  description: string;
}

const JobDetailContent: React.FC<JobDetailContentProps> = ({ description }) => {
  return <div>{description}</div>;
};

export default JobDetailContent;
