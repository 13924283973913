import React from 'react';
import { List } from './CardList.styled';

interface CardListProps {
  children: React.ReactNode;
}

const CardList: React.FC<CardListProps> = ({ children }) => {
  return <List>{children}</List>;
};

export default CardList;
