import gql from 'graphql-tag';
import { GradProfileParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation UpdateStudentLegal(
    $userId: String!
    $authorizedToWork: Boolean!
    $needSponsor: Boolean!
    $legalResidenceCountryId: String!
  ) {
    update_grad_profile(
      where: { user_id: { _eq: $userId } }
      _set: {
        authorized_to_work: $authorizedToWork
        need_sponsor: $needSponsor
        legal_residence_country_id: $legalResidenceCountryId
      }
    ) {
      returning {
        ...LegalParts
      }
    }
  }
  ${GradProfileParts}
`;

export default mutation;
