import { useQuery } from 'react-apollo';
import { GetUploadDoc, GetUploadDocVariables } from '../generated';
import { GET_UPLOAD_DOC } from '../gql';
import { useAuth0 } from '../react-auth0-spa';

const useUserHasResume = () => {
  const {
    user: { sub: userId },
  } = useAuth0();
  const { data: resume } = useQuery<GetUploadDoc, GetUploadDocVariables>(GET_UPLOAD_DOC, {
    variables: { userId },
    errorPolicy: 'all',
  });
  return Boolean(resume?.grad_upload_doc.length);
};

export default useUserHasResume;
