import styled from 'styled-components';

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  & > * {
    padding: 0.1rem 0;
  }
`;

export const Header = styled.div`
  padding: 0 2rem;
`;
