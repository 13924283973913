import gql from 'graphql-tag';
import { DegreeParts } from 'gql/fragments.gql';

const query = gql`
  query GetMyProfileDegrees($myUserId: String!) {
    grad_degree(
      where: { user_id: { _eq: $myUserId } }
      order_by: { complete_year: desc, complete_month_numeric: desc }
    ) {
      ...DegreeParts
    }
  }
  ${DegreeParts}
`;

export default query;
