import React from 'react';
import { HandIcon, StyledLowerHand, StyledRaiseHand } from './DetailViewMainButton.styled';

export interface DetailViewMainButtonProps {
  isButtonOn?: boolean;
  buttonTextOn?: string;
  buttonDisableText?: string;
  buttonTextOff?: string;
  iconButtonOnSrc?: string;
  iconButtonOffSrc?: string;
  isButtonVisible?: boolean;
  isButtonDisable?: boolean;
  onButtonOff?: () => void;
  onButtonOn?: () => void;
}

const DetailViewMainButton: React.FC<DetailViewMainButtonProps> = ({
  buttonTextOn,
  buttonTextOff,
  buttonDisableText = 'Set text to buttonTextOff',
  onButtonOff,
  onButtonOn,
  isButtonDisable,
  isButtonVisible,
  isButtonOn,
  iconButtonOnSrc,
  iconButtonOffSrc,
}) => {
  const isVisible = isButtonVisible && onButtonOff && onButtonOff;
  if (!isVisible) return null;
  return (
    <>
      {isButtonOn ? (
        <StyledLowerHand onClick={onButtonOff} disabled={isButtonDisable}>
          {iconButtonOnSrc && <HandIcon src={iconButtonOnSrc} />}
          {buttonTextOn}
        </StyledLowerHand>
      ) : (
        <StyledRaiseHand onClick={onButtonOn} disabled={isButtonDisable}>
          {iconButtonOffSrc && <HandIcon src={iconButtonOffSrc} />}
          {!isButtonDisable ? buttonTextOff : buttonDisableText}
        </StyledRaiseHand>
      )}
    </>
  );
};

export default DetailViewMainButton;
