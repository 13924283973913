import React from 'react';
import ResumeUploader from 'views/Student/Profile/ResumeUploader';
import { WizardProps } from '../components/interface';
import {
  WizardFormButtons as FormButtons,
  WizardContainer as Container,
  WizardInputLabel as Label,
  WizardStepTitle as Title,
  SUBMIT_GO_TO,
  CANCEL_GO_TO,
} from '../components';
import { Footer, Header } from './ResumeStep.styled';

export interface ButtonsProps {
  onNext?: () => void;
  onSkip?: () => void;
}

const ResumeStep: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
}) => {
  const Buttons: React.FC<ButtonsProps> = ({ onNext, onSkip }) => {
    return (
      <Footer>
        <FormButtons
          step={step}
          isLastStep={isLastStep}
          cancelSkipGoTo={cancelSkipGoTo}
          onNext={onNext}
          onSkip={onSkip}
        />
      </Footer>
    );
  };

  return (
    <Container step={step} totalSteps={totalSteps}>
      <Header>
        <Title>Upload a resume</Title>
        <Label>You will always be able to change your CV in you profile</Label>
      </Header>
      <ResumeUploader isSubComponent WizardButtons={Buttons} nextPage={nextGoTo} />
    </Container>
  );
};

export default ResumeStep;
