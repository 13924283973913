import gql from 'graphql-tag';
import { RaisedHandParts, JobParts, BookMarkPart } from 'gql/fragments.gql';

const query = gql`
  query GetJobPosting($jobId: String!, $userId: String!) {
    recruit_job_posting(where: { job_posting_id: { _eq: $jobId } }) {
      ...JobParts
      bookmark_jobs(where: { user_id: { _eq: $userId } }) {
        ...BookMarkPart
      }
      raise_hands(where: { user_id: { _eq: $userId } }) {
        ...RaisedHandParts
      }
    }
  }
  ${RaisedHandParts}
  ${JobParts}
  ${BookMarkPart}
`;

export default query;
