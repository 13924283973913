import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertUploadDoc($fileName: String!, $hexContent: String!, $userId: String!) {
    insert_grad_upload_doc(objects: [{ user_id: $userId, filename: $fileName, hex_content: $hexContent }]) {
      returning {
        upload_doc_id
        user_id
        filename
        hex_content
      }
    }
  }
`;

export default mutation;
