import { useQuery } from 'react-apollo';
import { GetAllCompanies } from '../../../../generated/GetAllCompanies';
import ALL_COMPANIES from '../../../../gql/Company/GetAllCompanies.gql';

export const useCompaniesQuery = () => {
  return useQuery<GetAllCompanies>(ALL_COMPANIES, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
};
