import gql from 'graphql-tag';
import { AwardParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation UpdateRelatedProfile(
    $languagesForUpsert: [grad_spoken_language_insert_input!]!
    $languageIdsForDelete: [String!]!
    $funFactsForUpsert: [grad_fun_fact_insert_input!]!
    $funFactIdsForDelete: [String!]!
    $volunteersForUpsert: [grad_volunteer_insert_input!]!
    $volunteerIdsForDelete: [String!]!
    $awardsForUpsert: [grad_award_insert_input!]!
    $awardIdsForDelete: [String!]!
    $achievementsForUpsert: [grad_achievement_insert_input!]!
    $achievementIdsForDelete: [String!]!
  ) {
    insert_grad_spoken_language(
      objects: $languagesForUpsert
      on_conflict: { constraint: spoken_language_pkey, update_columns: [] }
    ) {
      returning {
        spoken_language_id
        language
        user_id
      }
    }

    delete_grad_spoken_language(where: { spoken_language_id: { _in: $languageIdsForDelete } }) {
      returning {
        spoken_language_id
        language
        user_id
      }
    }

    insert_grad_fun_fact(
      objects: $funFactsForUpsert
      on_conflict: { constraint: fun_fact_fun_fact_id_key, update_columns: [description] }
    ) {
      returning {
        description
        fun_fact_id
        user_id
      }
    }

    delete_grad_fun_fact(where: { fun_fact_id: { _in: $funFactIdsForDelete } }) {
      returning {
        description
        fun_fact_id
        user_id
      }
    }

    insert_grad_volunteer(
      objects: $volunteersForUpsert
      on_conflict: { constraint: volunteer_volunteer_id_key, update_columns: [description] }
    ) {
      returning {
        description
        volunteer_id
        user_id
      }
    }

    delete_grad_volunteer(where: { volunteer_id: { _in: $volunteerIdsForDelete } }) {
      returning {
        description
        volunteer_id
        user_id
      }
    }

    insert_grad_award(
      objects: $awardsForUpsert
      on_conflict: { constraint: award_award_id_key, update_columns: [year_received, award_name, award_category] }
    ) {
      returning {
        ...AwardParts
      }
    }

    delete_grad_award(where: { award_id: { _in: $awardIdsForDelete } }) {
      returning {
        ...AwardParts
      }
    }

    insert_grad_achievement(
      objects: $achievementsForUpsert
      on_conflict: { constraint: achievement_achievement_id_key, update_columns: [description] }
    ) {
      returning {
        description
        achievement_id
        user_id
      }
    }

    delete_grad_achievement(where: { achievement_id: { _in: $achievementIdsForDelete } }) {
      returning {
        description
        achievement_id
        user_id
      }
    }
  }
  ${AwardParts}
`;

export default mutation;
