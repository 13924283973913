import React from 'react';
import EventDetailHeader, { EventDetailHeaderProps } from './EventDetailHeader';
import EventDetailContent, { EventDetailContentProps } from './EventDetailContent';
import withDetailView from '../../../../components/common/withDetailView';
import { DetailViewMainButtonProps } from '../../../../components/common/DetailViewMainButton';

interface EventDetailProps extends DetailViewMainButtonProps, EventDetailHeaderProps, EventDetailContentProps {}

const DetailViewHOC = withDetailView(EventDetailHeader, EventDetailContent);

const EventDetail: React.FC<EventDetailProps> = ({
  title,
  description,
  logo,
  tags,
  formattedStartTimestamp,
  formattedEndTimestamp,
  fromNowStartTimeStamp,
  capacity,
  address,
  event_url,
  onButtonOn,
  onButtonOff,
  companyName,
  buttonDisableText,
  buttonTextOff,
  buttonTextOn,
  isButtonDisable,
  isButtonOn,
  isButtonVisible,
}) => {
  return (
    <DetailViewHOC
      companyName={companyName}
      address={address}
      event_url={event_url}
      fromNowStartTimeStamp={fromNowStartTimeStamp}
      formattedEndTimestamp={formattedEndTimestamp}
      formattedStartTimestamp={formattedStartTimestamp}
      capacity={capacity}
      title={title}
      logo={logo}
      tags={tags}
      buttonDisableText={buttonDisableText}
      buttonTextOff={buttonTextOff}
      buttonTextOn={buttonTextOn}
      isButtonDisable={isButtonDisable}
      isButtonOn={isButtonOn}
      isButtonVisible={isButtonVisible}
      description={description}
      onButtonOn={onButtonOn}
      onButtonOff={onButtonOff}
    />
  );
};

export default EventDetail;
