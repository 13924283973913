import gql from 'graphql-tag';
import { BookMarkPart } from 'gql/fragments.gql';

const query = gql`
  mutation DeleteBookmark($userId: String!, $jobId: String!) {
    delete_grad_bookmark_job(where: { job_posting_id: { _eq: $jobId }, user_id: { _eq: $userId } }) {
      returning {
        ...BookMarkPart
      }
    }
  }
  ${BookMarkPart}
`;

export default query;
