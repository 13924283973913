import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { CellPhoneInput, FormValidationMessage, SaveButton, CancelButton, Spinner } from 'components';
import CityStatePicker, { CityStatePickerOption } from 'components/CityStatePicker';
import { EmailPattern } from 'utils/validations';
import { StyledForm, StyledFormInputs, StyledInputLabel, StyledInput, StyledButtons } from './library';
import SelectorMultiCreateV2 from 'components/SelectorMultiCreateV2';
import { OptionId, createOptionId } from 'components/Option';

import LanguageSelector from 'components/LanguageSelector';
import RelevantYearsSelector from 'components/RelevantYearsSelector';

import { GET_STUDENT_PROFILE, UPDATE_RELATED_PROFILE, UPDATE_STUDENT_PROFILE } from 'gql';
import { UpdateStudentProfile, UpdateStudentProfileVariables } from 'generated/UpdateStudentProfile';
import { GetStudentProfile, GetStudentProfileVariables } from 'generated/GetStudentProfile';
import { AwardsTypes, PROFESSIONAL_DESIGNATIONS_OPTIONS } from 'data/awards';
import { UpdateRelatedProfile, UpdateRelatedProfileVariables } from 'generated/UpdateRelatedProfile';
import CheckboxWithController from 'components/CheckboxWithController';
import styled from 'styled-components';
import { WantToKnowSelector } from 'components';

interface ProfilePersonalFormVariables {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: string;
  email: string;
  title: string;
  mobile: string;
  hometown: string;
  funFacts: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  yearsOfRelevantWork?: { value: string; label: string };
  volunteerActivities: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  professionalDesignations: { newOptions: OptionId[]; deletedOptionsIds: string[] };
  personalAchievements: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  languages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  citySelectionOption: CityStatePickerOption[];
  studentAthlete: boolean;
}

const StyledInlineCheckBox = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 1rem;
    margin-bottom: 0;
    line-height: 2rem;
  }

  margin-bottom: 1rem;
`;

const ProfilePersonal: React.SFC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { control, register, handleSubmit, errors, setValue } = useForm<ProfilePersonalFormVariables>();

  const { loading: getProfileLoading, data: getProfileData } = useQuery<GetStudentProfile, GetStudentProfileVariables>(
    GET_STUDENT_PROFILE,
    {
      variables: { myUserId: user.sub },
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const [updateGradProfile, { loading: updateLoading }] = useMutation<
    UpdateStudentProfile,
    UpdateStudentProfileVariables
  >(UPDATE_STUDENT_PROFILE, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [updateRelatedProfile, { loading: updateRelatedProfileLoading }] = useMutation<
    UpdateRelatedProfile,
    UpdateRelatedProfileVariables
  >(UPDATE_RELATED_PROFILE, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: updateRelatedData }) => {
      const profileCache = cache.readQuery<GetStudentProfile, GetStudentProfileVariables>({
        query: GET_STUDENT_PROFILE,
        variables: { myUserId: user.sub },
      });

      const [oldGradProfile] = profileCache?.grad_profile || [];

      if (!oldGradProfile) return;

      if (
        !updateRelatedData ||
        !updateRelatedData.insert_grad_spoken_language ||
        !updateRelatedData.delete_grad_spoken_language ||
        !updateRelatedData.insert_grad_fun_fact ||
        !updateRelatedData.delete_grad_fun_fact ||
        !updateRelatedData.insert_grad_volunteer ||
        !updateRelatedData.delete_grad_volunteer ||
        !updateRelatedData.insert_grad_award ||
        !updateRelatedData.delete_grad_award ||
        !updateRelatedData.insert_grad_achievement ||
        !updateRelatedData.delete_grad_achievement
      ) {
        return;
      }

      const deletedLanguageIds = updateRelatedData.delete_grad_spoken_language.returning.map(
        ({ spoken_language_id }) => spoken_language_id,
      );
      const newLanguages = [
        ...oldGradProfile.spoken_languages,
        ...updateRelatedData.insert_grad_spoken_language.returning,
      ].filter(({ spoken_language_id }) => !deletedLanguageIds.includes(spoken_language_id));

      const newFunFacts = updateRelatedData.insert_grad_fun_fact.returning;

      const newVolunteers = updateRelatedData.insert_grad_volunteer.returning;

      const deletedAwardIds = updateRelatedData.delete_grad_award.returning.map(({ award_id }) => award_id);
      const newAwards = [...oldGradProfile.awards, ...updateRelatedData.insert_grad_award.returning].filter(
        ({ award_id }) => !deletedAwardIds.includes(award_id),
      );

      const newAchievements = updateRelatedData.insert_grad_achievement.returning;

      const newGradProfile = {
        ...oldGradProfile,
        spoken_languages: newLanguages,
        fun_facts: newFunFacts,
        volunteers: newVolunteers,
        awards: newAwards,
        achievements: newAchievements,
      };

      cache.writeQuery({
        query: GET_STUDENT_PROFILE,
        variables: { myUserId: user.sub },
        data: {
          grad_profile: [newGradProfile],
        },
      });
    },
  });

  const myInfo = getProfileData?.grad_profile[0];

  if (getProfileLoading) return <Spinner />;

  const languagesOptions = (myInfo?.spoken_languages || []).map((language) =>
    createOptionId(language.language, language.spoken_language_id),
  );

  const funFactsOptions = (myInfo?.fun_facts || []).map((funFact) =>
    createOptionId(funFact.description, funFact.fun_fact_id),
  );

  const volunteerOptions = (myInfo?.volunteers || []).map((volunteer) =>
    createOptionId(volunteer.description, volunteer.volunteer_id),
  );

  const professionalDesignationsOptions = (myInfo?.awards.length ? myInfo.awards : [])
    .filter((award) => award.award_category === AwardsTypes.PROFESSIONAL_DESIGNATION)
    .map((award) => createOptionId(award.award_name, award.award_id));

  const personalAchievementsOptions = (myInfo?.achievements || []).map((achievement) =>
    createOptionId(achievement.description, achievement.achievement_id),
  );

  const onSubmit = async (data: ProfilePersonalFormVariables) => {
    const {
      firstName,
      lastName,
      email,
      title,
      mobile,
      yearsOfRelevantWork,
      funFacts: { optionsValue: funFactsOptions, deletedOptionsIds: deletedFunFactsIds },
      volunteerActivities: { optionsValue: volunteerOptions, deletedOptionsIds: deletedVolunteerIds },
      professionalDesignations: { newOptions: newDesignationsOptions, deletedOptionsIds: deletedDesignationsIds },
      personalAchievements: { optionsValue: personalAchievementsOptions, deletedOptionsIds: deletedAchievementIds },
      languages: { newOptions: newLanguagesOptions, deletedOptionsIds: deleteLanguagesIds },
      citySelectionOption,
      studentAthlete,
    } = data;

    const fullName = `${firstName} ${lastName}`;

    const languagesForUpsert = newLanguagesOptions.map(({ label }) => ({
      language: label,
      user_id: user.sub,
    }));

    const funFactsForUpsert = funFactsOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { fun_fact_id: id }),
    }));

    const volunteersForUpsert = volunteerOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { volunteer_id: id }),
    }));

    const professionalDesignationForUpsert = newDesignationsOptions.map(({ id, value }) => ({
      award_category: AwardsTypes.PROFESSIONAL_DESIGNATION,
      award_name: value,
      user_id: user.sub,
      ...(id && { award_id: id }),
    }));

    const grad_achievementForUpsert = personalAchievementsOptions.map(({ id, value }) => ({
      description: value,
      user_id: user.sub,
      ...(id && { achievement_id: id }),
    }));

    const profileVariablesForUpdate = {
      userId: user.sub,
      fullName,
      email,
      title,
      firstName,
      lastName,
      mobile,
      hometown: citySelectionOption?.[0]?.value || null,
      yearsOfRelevantWork: (yearsOfRelevantWork || {}).value,
      studentAthlete,
    };

    await updateRelatedProfile({
      variables: {
        languagesForUpsert: languagesForUpsert,
        languageIdsForDelete: deleteLanguagesIds,
        funFactsForUpsert: funFactsForUpsert,
        funFactIdsForDelete: deletedFunFactsIds,
        volunteersForUpsert: volunteersForUpsert,
        volunteerIdsForDelete: deletedVolunteerIds,
        awardsForUpsert: professionalDesignationForUpsert,
        awardIdsForDelete: deletedDesignationsIds,
        achievementsForUpsert: grad_achievementForUpsert,
        achievementIdsForDelete: deletedAchievementIds,
      },
    });

    await updateGradProfile({ variables: profileVariablesForUpdate });

    history.goBack();
  };

  if (updateLoading || updateRelatedProfileLoading) return <Spinner />;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledInputLabel>First Name</StyledInputLabel>
        <StyledInput
          type="text"
          name="firstName"
          placeholder="John"
          ref={register({ required: true })}
          defaultValue={myInfo?.firstname || ''}
        />
        {errors.firstName && <FormValidationMessage message="First name is required." />}
        <StyledInputLabel>Last Name</StyledInputLabel>
        <StyledInput
          type="text"
          name="lastName"
          placeholder="Doe"
          ref={register({ required: true })}
          defaultValue={myInfo?.lastname || ''}
        />
        {errors.fullName && <FormValidationMessage message="Last name is required." />}

        <StyledInputLabel>Email</StyledInputLabel>
        <StyledInput
          type="text"
          name="email"
          placeholder="jdoe@email.com"
          ref={register({ required: true, pattern: EmailPattern })}
          defaultValue={myInfo?.email || ''}
        />
        {errors.email && <FormValidationMessage message="Email is required." />}

        <StyledInputLabel>Cell phone</StyledInputLabel>
        <CellPhoneInput control={control} required={true} defaultValue={myInfo?.mobile || null} name="mobile" />
        {errors.mobile && (
          <FormValidationMessage message={errors.mobile.message || 'A valid cell phone number is required.'} />
        )}

        <StyledInlineCheckBox>
          <StyledInputLabel>Student Athlete</StyledInputLabel>
          <CheckboxWithController
            control={control}
            name={'studentAthlete'}
            defaultChecked={myInfo?.student_athlete || false}
          />
        </StyledInlineCheckBox>
        <StyledInputLabel>Preferred Professional Designation</StyledInputLabel>
        <StyledInput
          type="text"
          name="title"
          placeholder="CFA"
          ref={register({ required: false })}
          defaultValue={myInfo?.title || ''}
        />

        <StyledInputLabel>Professional Designations</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          dropDownIndicator={true}
          dropDownOptions={PROFESSIONAL_DESIGNATIONS_OPTIONS}
          defaultOptionsValue={professionalDesignationsOptions}
          name="professionalDesignations"
          placeholder="Professional Designations Earned"
          register={register}
          setValue={setValue}
        />
        {errors.professionalDesignations && (
          <FormValidationMessage message="Add at least one professional designation" />
        )}

        <StyledInputLabel>Languages</StyledInputLabel>
        <LanguageSelector
          setValue={setValue}
          control={control}
          defaultValue={languagesOptions}
          name={'languages'}
          register={register}
        />

        <StyledInputLabel>Years of Relevant Work Experience/Internship</StyledInputLabel>
        <RelevantYearsSelector
          name={'yearsOfRelevantWork'}
          defaultValue={myInfo?.years_of_relevant_work || null}
          control={control}
          required={false}
        />

        <StyledInputLabel>Hometown</StyledInputLabel>
        <CityStatePicker
          defaultValue={myInfo?.hometown}
          name="citySelectionOption"
          control={control}
          clearable
          required={false}
        />

        <StyledInputLabel>What I want an employer to know about me</StyledInputLabel>
        <WantToKnowSelector name="funFacts" register={register} setValue={setValue} values={funFactsOptions} />

        <StyledInputLabel>Personal Achievements/Awards/Scholarships</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          defaultOptionsValue={personalAchievementsOptions}
          name="personalAchievements"
          placeholder="John Harvard Scholar, Scholarship Athlete - Tennis"
          register={register}
          setValue={setValue}
          dropDownIndicator={false}
        />
        <StyledInputLabel>Volunteer/Clubs/Extracurricular Activities</StyledInputLabel>
        <SelectorMultiCreateV2
          showMenu={true}
          defaultOptionsValue={volunteerOptions}
          name="volunteerActivities"
          placeholder="Big Brothers Big Sisters, Camillus House, Salvation Army"
          register={register}
          setValue={setValue}
          dropDownIndicator={false}
        />
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={history.goBack}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfilePersonal;
