import React, { useCallback, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import QuerySelectorCreate from 'components/QuerySelectorCreate';

import { GET_COMPANY_SELECTIONS } from 'gql';
import { GetCompanySelections } from 'generated/GetCompanySelections';

const SEARCH_RESULTS_LIMIT = 6;

export interface CompanyPickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultValue?: string | null;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
}

const CompanyPicker: React.FC<CompanyPickerProps> = ({
  name,
  control,
  clearable = false,
  required = false,
  defaultValue = null,
  placeholder = undefined,
}) => {
  const [queryCompanies, { loading: loadingCompanies, data: dataCompanies }] = useLazyQuery<GetCompanySelections>(
    GET_COMPANY_SELECTIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const handleOnSearch = useCallback(
    (searchTerm) => {
      queryCompanies({
        variables: {
          searchTerm: `%${searchTerm}%`,
          limit: SEARCH_RESULTS_LIMIT,
        },
      });
    },
    [queryCompanies],
  );

  const defaultCompanyOption = useMemo(() => {
    return {
      label: defaultValue || '',
      value: defaultValue || '',
    };
  }, [defaultValue]);

  const companyOptions = useMemo(
    () =>
      ((dataCompanies && dataCompanies.grad_company_selection) || []).map(({ company, company_selection_id }) => ({
        value: company,
        label: company,
        id: company_selection_id,
      })),
    [dataCompanies],
  );

  return (
    <QuerySelectorCreate
      defaultOptions={companyOptions}
      defaultOptionValue={defaultCompanyOption}
      name={name}
      placeholder={placeholder}
      control={control}
      loading={loadingCompanies}
      isClearable={clearable}
      required={required}
      onSearch={handleOnSearch}
    />
  );
};

export default CompanyPicker;
