import React from 'react';
import styled from 'styled-components';

import RadioButton, { RadioButtonProps } from './RadioButton';

const StyledLabel = styled.label`
  display: inline-flex;

  align-items: center;
`;

const StyledLabelText = styled.span`
  padding-left: 1rem;
`;

export interface RadioButtonWithLabelProps extends RadioButtonProps {
  label: string;
}

const RadioButtonWithLabel: React.FC<RadioButtonWithLabelProps> = ({ label, ...props }) => {
  return (
    <StyledLabel>
      <RadioButton {...props} />
      <StyledLabelText>{label}</StyledLabelText>
    </StyledLabel>
  );
};

export default RadioButtonWithLabel;
