/* eslint-disable react/jsx-key */
import React, { useState, FunctionComponent } from 'react';
import styled from 'styled-components';

import { palette } from 'theme';

export type TabType = { component: FunctionComponent; tabName: string };
export type Tabs = TabType[];

const Tabs: React.SFC<{ tabs: Tabs }> = ({ tabs }) => {
  const [selected, setSelected] = useState<number>(0);
  const SelectedComponent = tabs[selected].component;

  const onClickOption = (option: number) => {
    return () => {
      setSelected(option);
    };
  };

  return (
    <>
      <TabsMenu>
        {tabs.map(({ tabName }, index) => {
          return (
            <TabOption key={index + '-' + tabName} active={selected === index} onClick={onClickOption(index)}>
              {tabName}
            </TabOption>
          );
        })}
      </TabsMenu>
      <TabSelector>
        <SelectedComponent />
      </TabSelector>
    </>
  );
};

const TabsMenu = styled.ol`
  flex-shrink: 0;

  width: inherit;

  padding: 0 2rem;
  border-top: 1px solid ${palette.lightGray};
  border-bottom: 1px solid ${palette.lightGray};
`;

interface TabOptionType {
  active: boolean;
}

const TabOption = styled.ul<TabOptionType>`
  display: inline-block;
  vertical-align: middle;

  height: 3.5rem;
  margin-bottom: -0.1rem;
  padding: 0.6rem 1rem 0.3rem;
  ${({ active }) => active && `border-bottom: 1px solid ${palette.red}`};

  font-size: 1.4rem;
  font-weight: ${({ active }) => (active ? 'bolder' : 'normal')};

  line-height: 3.5rem;
  list-style: none;
`;

const TabSelector = styled.div`
  flex-grow: 1;
  overflow: auto;

  width: inherit;
`;

export default Tabs;
