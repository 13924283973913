import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import Selector from './Selector';
import { OptionId } from './Option';

import { GET_INTEREST_JOB_TYPE_SELECTIONS } from 'gql';
import { GetJobTypeInterestSelections } from 'generated/GetJobTypeInterestSelections';

export interface JobTypeInterestPickerProps extends Pick<FormContextValues, 'control'> {
  name: string;
  defaultValue: OptionId | null;
  clearable?: boolean;
  required?: boolean;
  placeholder?: string;
}

const JobTypeInterestPicker: React.FC<JobTypeInterestPickerProps> = ({
  name,
  defaultValue,
  clearable = false,
  required = false,
  placeholder = undefined,
  control,
}) => {
  const { loading: loadingJobTypeInterests, data: dataJobTypeInterests } = useQuery<GetJobTypeInterestSelections>(
    GET_INTEREST_JOB_TYPE_SELECTIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const jobTypeInterestOptions = useMemo(() => {
    if (!dataJobTypeInterests) {
      return [];
    }

    return dataJobTypeInterests.grad_interest_job_type_selection.map((fi) => ({
      label: fi.value,
      value: fi.value,
      id: fi.interest_job_type_selection_id,
    }));
  }, [dataJobTypeInterests]);

  return (
    <Selector
      options={jobTypeInterestOptions}
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      required={required}
      isClearable={clearable}
      disabled={false}
      loading={loadingJobTypeInterests}
      control={control}
      isSearchable={false}
    />
  );
};

export default JobTypeInterestPicker;
