import { ConcentrationTypes } from 'data/degree';

import { GetMyProfile_grad_profile_degrees } from 'generated/GetMyProfile';

export const findConcentrationFromDegree = (
  studentDegree: GetMyProfile_grad_profile_degrees,
  concentrationType: ConcentrationTypes.MAJOR | ConcentrationTypes.MINOR,
) => (studentDegree.concentrations.find(({ type }) => type === concentrationType) || { val: '' }).val;

export const convertConcentrationsToString = (
  studentDegree: GetMyProfile_grad_profile_degrees,
  concentrationType: ConcentrationTypes.MAJOR | ConcentrationTypes.MINOR,
) => {
  const filteredConcentrations = studentDegree.concentrations.filter(({ type }) => type === concentrationType);
  const [{ val: firstConcentrationVal } = { val: '' }, ...rest] = filteredConcentrations;
  const amount = filteredConcentrations.length;
  const casedConcentrations = concentrationType.charAt(0).toUpperCase() + concentrationType.slice(1).toLowerCase();
  const title = amount ? `${amount === 1 ? `${casedConcentrations} in` : `${casedConcentrations}s in:`} ` : '';

  return `${title}${firstConcentrationVal}${rest.map(({ val }) => `, ${val}`)}`;
};

export const majorsAsDescription = (studentDegree: GetMyProfile_grad_profile_degrees) => {
  const filteredConcentrations = studentDegree.concentrations.filter(({ type }) => type === ConcentrationTypes.MAJOR);

  return filteredConcentrations.map(({ val }) => val).join(', ');
};
