import gql from 'graphql-tag';
import { TestScoreDetailPart } from './fragments.gql';

const mutation = gql`
  mutation DeleteTestScoreDetail($ids: [String!]!) {
    delete_grad_test_score_detail(where: { test_score_detail_id: { _in: $ids } }) {
      returning {
        ...TestScoreDetailPart
      }
    }
  }
  ${TestScoreDetailPart}
`;

export default mutation;
