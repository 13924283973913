import { useQuery } from 'react-apollo';
import { GetStudentGPA, GetStudentGPAVariables } from '../generated';
import { GET_STUDENT_GPA } from '../gql';

export const useGetStudentGPAQuery = (userId: string) => {
  const { data, loading } = useQuery<GetStudentGPA, GetStudentGPAVariables>(GET_STUDENT_GPA, {
    variables: { userId },
    errorPolicy: 'all',
  });
  const degreesCount = data?.grad_degree?.length || 0;
  const gpa = data?.grad_degree.reduce((sum, current) => sum + current.gpa, 0) || 0;
  return [gpa > 0 && degreesCount > 0 ? gpa / degreesCount : 0, loading];
};
