import gql from 'graphql-tag';
import { ComputarLanguageSelectionParts } from './fragments.gql';

const query = gql`
  query GetProgrammingLanguageSelections {
    grad_computer_language_selection(limit: 1000) {
      ...ComputarLanguageSelectionParts
    }
  }
  ${ComputarLanguageSelectionParts}
`;

export default query;
