import React from 'react';
import { Icon } from './JobBookmark.styled';
import { BookmarkLightGray, BookmarkRed } from '../../../../theme/assets';

interface JobBookmarkProps {
  deleteBookmark: () => void;
  insertBookmard: () => void;
  bookmarked: boolean;
}

const JobBookmark: React.FC<JobBookmarkProps> = ({ deleteBookmark, insertBookmard, bookmarked }) => {
  const bookmarkIcon = bookmarked ? BookmarkRed : BookmarkLightGray;
  const onClick = bookmarked ? () => deleteBookmark() : () => insertBookmard();
  return <Icon onClick={onClick} src={bookmarkIcon} />;
};

export default JobBookmark;
