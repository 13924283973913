import React from 'react';
import { FormContextValues } from 'react-hook-form';
import Selector from './Selector';
import { OptionYear } from './Option';

interface YearPickerProps extends Pick<FormContextValues, 'control'> {
  required: boolean;
  defaultYear: number | null;
  name: string;
  disabled?: boolean;
}

const createYearOption = (year: number): OptionYear => ({
  value: year,
  label: year,
});

export const YearPicker: React.FC<YearPickerProps> = ({ defaultYear, control, name, required, disabled = false }) => {
  const topYear = new Date().getFullYear() + 20;
  const options = Array.from({ length: 76 }, (v, k) => createYearOption(topYear - k));

  return (
    <Selector
      options={options}
      defaultValue={defaultYear ? createYearOption(defaultYear) : null}
      name={name}
      control={control}
      placeholder="Select Year"
      required={required}
      disabled={disabled}
    />
  );
};
