import styled from 'styled-components';
import { palette } from '../../../../../theme';

export const Container = styled.div`
  padding: 1rem;
`;

export const EventInfoContainer = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
`;

export const ItemContainer = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${palette.gray};
  &:after {
    content: ': ';
  }
`;

export const DescriptionContainer = styled.div`
  overflow: scroll;
`;

export const Icon = styled.img`
  display: inline-block;
  width: 2rem;
  height: 2rem;

  margin-right: 0.8rem;

  vertical-align: middle;
`;
