import { useMutation } from '@apollo/react-hooks';
import {
  CancelButton,
  FormValidationMessage,
  SaveButton,
  Spinner,
  StyledButtons,
  StyledForm,
  StyledFormInputs,
  StyledInputLabel,
} from 'components';
import { DeleteInterests, DeleteInterestsVariables } from 'generated/DeleteInterests';
import { GetStudentJobInterests, GetStudentJobInterestsVariables } from 'generated/GetStudentJobInterests';
import { InsertFirmInterests, InsertFirmInterestsVariables } from 'generated/InsertFirmInterests';
import { InsertJobInterests, InsertJobInterestsVariables } from 'generated/InsertJobInterests';
import { InsertRegionInterests, InsertRegionInterestsVariables } from 'generated/InsertRegionInterests';
import { InsertRoleInterests, InsertRoleInterestsVariables } from 'generated/InsertRoleInterests';
import { InsertEmployerPreference, InsertEmployerPreferenceVariables } from 'generated/InsertEmployerPreference';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { EmployerPreferenceSelector } from 'components/EmployerPreferenceSelector';
import RoleInterestPicker from 'components/JobRoleInterestPicker';
import { OptionIdStrict } from 'components/Option';
import FirmInterestsPicker from 'components/FirmInterestsPicker';
import JobTypeInterestPicker from 'components/JobTypeInterestPicker';
import {
  DELETE_INTERESTS,
  GET_STUDENT_JOB_INTERESTS,
  INSERT_EMPLOYER_PREFERENCE,
  INSERT_FIRM_INTERESTS,
  INSERT_JOB_INTERESTS,
  INSERT_REGION_INTERESTS,
  INSERT_ROLE_INTERESTS,
} from 'gql';
import RegionsPicker from 'components/RegionsPicker';
import Disclaimer from 'components/common/Disclaimer';
import { FORM_MESSAGES } from 'utils/constants';

interface FormInterface {
  desiredFirms: { current: OptionIdStrict[] };
  desiredJobType: OptionIdStrict;
  interestRegions: OptionIdStrict[];
  interestRoles: { current: OptionIdStrict[] };
  employerPreferences: { newOptions: OptionIdStrict[]; deletedOptionsIds: string[] };
}
const DEFAULT_INTERESTS = [
  { interest_job_types: [], interest_firms: [], interest_regions: [], interest_roles: [], employer_preferences: [] },
];

const ProfileJobTab: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { handleSubmit, control, setValue, register, errors } = useForm<FormInterface>();

  const { loading: loadingInterests, error: getProfileError, data: interestsData = { grad_profile: [] } } = useQuery<
    GetStudentJobInterests,
    GetStudentJobInterestsVariables
  >(GET_STUDENT_JOB_INTERESTS, { variables: { myUserId: user.sub } });

  const [deleteInterests] = useMutation<DeleteInterests, DeleteInterestsVariables>(DELETE_INTERESTS);
  const [insertFirmInterests] = useMutation<InsertFirmInterests, InsertFirmInterestsVariables>(INSERT_FIRM_INTERESTS);
  const [insertJobInterests] = useMutation<InsertJobInterests, InsertJobInterestsVariables>(INSERT_JOB_INTERESTS);
  const [insertRegionInterests] = useMutation<InsertRegionInterests, InsertRegionInterestsVariables>(
    INSERT_REGION_INTERESTS,
  );
  const [insertEmployerPreference] = useMutation<InsertEmployerPreference, InsertEmployerPreferenceVariables>(
    INSERT_EMPLOYER_PREFERENCE,
  );
  const [insertRoleInterests] = useMutation<InsertRoleInterests, InsertRoleInterestsVariables>(INSERT_ROLE_INTERESTS);

  const [
    {
      interest_job_types = [],
      interest_firms = [],
      interest_regions = [],
      interest_roles = [],
      employer_preferences = [],
    },
  ] = interestsData?.grad_profile?.length ? interestsData.grad_profile : DEFAULT_INTERESTS;

  const currentJobType =
    interest_job_types.map(
      ({ job_type, interest_job_type_id }: { job_type: string; interest_job_type_id: string }) => ({
        value: job_type,
        label: job_type,
        id: interest_job_type_id,
      }),
    )[0] || null;

  const currentFirmInterests = interest_firms.map(
    ({ firm_type, interest_firm_id }: { firm_type: string; interest_firm_id: string }) => ({
      value: firm_type,
      label: firm_type,
      id: interest_firm_id,
    }),
  );

  const currentRegions = interest_regions.map(
    ({ region, interest_region_id }: { region: string; interest_region_id: string }) => ({
      value: region,
      label: region,
      id: interest_region_id,
    }),
  );

  const currentRoles = interest_roles.map(({ role, interest_role_id }: { role: string; interest_role_id: string }) => ({
    value: role,
    label: role,
    id: interest_role_id,
  }));

  const currentEmployerPreferences = employer_preferences.map(({ preference, employer_preference_id }) => ({
    value: preference,
    label: preference,
    id: employer_preference_id,
  }));

  const getEraseOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));
  const getAddOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async ({
    desiredFirms: { current: desiredFirms },
    desiredJobType,
    interestRegions,
    interestRoles: { current: interestRoles },
    employerPreferences,
  }: FormInterface) => {
    const { deletedOptionsIds: erasePreferences, newOptions: newEmployerPreferences } = employerPreferences;

    const addEmployerPreferences = newEmployerPreferences.map(({ value }) => ({
      preference: value,
      user_id: user.sub,
    }));

    const eraseFirms = getEraseOptions(desiredFirms || [], currentFirmInterests || [])
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addFirms = getAddOptions(desiredFirms, currentFirmInterests).map(({ value }) => ({
      firm_type: value,
      user_id: user.sub,
    }));

    const eraseJobTypes = getEraseOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    )
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addJobTypes = getAddOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    ).map(({ value }) => ({
      job_type: value,
      user_id: user.sub,
    }));

    const eraseRegions = getEraseOptions(interestRegions, currentRegions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRegions = getAddOptions(interestRegions, currentRegions).map(({ value }) => ({
      region: value,
      user_id: user.sub,
    }));

    const eraseRoles = getEraseOptions(interestRoles, currentRoles)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRoles = getAddOptions(interestRoles, currentRoles).map(({ value }) => ({
      role: value,
      user_id: user.sub,
    }));

    if (
      eraseJobTypes.length ||
      eraseFirms.length ||
      eraseRegions.length ||
      eraseRoles.length ||
      erasePreferences.length
    ) {
      deleteInterests({
        variables: {
          jobs: eraseJobTypes,
          firms: eraseFirms,
          regions: eraseRegions,
          roles: eraseRoles,
          employerPreferences: erasePreferences,
        },
      });
    }
    if (addFirms.length) {
      await insertFirmInterests({ variables: { firms: addFirms } });
    }
    if (addJobTypes.length) {
      await insertJobInterests({ variables: { jobs: addJobTypes } });
    }
    if (addRegions.length) {
      await insertRegionInterests({ variables: { regions: addRegions } });
    }
    if (addRoles.length) {
      await insertRoleInterests({ variables: { roles: addRoles } });
    }
    if (addEmployerPreferences.length) {
      await insertEmployerPreference({ variables: { preferences: addEmployerPreferences } });
    }

    history.goBack();
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {loadingInterests ? (
        <Spinner />
      ) : getProfileError ? (
        <div>Error</div>
      ) : (
        <>
          <StyledFormInputs>
            <StyledInputLabel>Firms of Interest</StyledInputLabel>
            <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
            <FirmInterestsPicker
              name="desiredFirms"
              defaultValue={currentFirmInterests}
              control={control}
              register={register}
              setValue={setValue}
            />
            {errors.desiredFirms && <FormValidationMessage message="Select desired firms of interest" />}

            <StyledInputLabel>Job Type</StyledInputLabel>
            <JobTypeInterestPicker
              name="desiredJobType"
              defaultValue={currentJobType}
              required
              clearable
              control={control}
            />
            {errors.desiredJobType && <FormValidationMessage message="Select Job Type" />}

            <StyledInputLabel>Desired Locations</StyledInputLabel>
            <RegionsPicker name="interestRegions" control={control} defaultValue={currentRegions} />
            {errors.interestRegions && <FormValidationMessage message="Select desired job locations" />}

            <StyledInputLabel>What I look for in an employer?</StyledInputLabel>
            <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
            <EmployerPreferenceSelector
              name="employerPreferences"
              register={register}
              setValue={setValue}
              values={currentEmployerPreferences}
            />
            {errors.employerPreferences && <FormValidationMessage message="Select What I look for in an employer?" />}

            <StyledInputLabel>Roles of interest</StyledInputLabel>
            <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
            <RoleInterestPicker
              name="interestRoles"
              control={control}
              register={register}
              setValue={setValue}
              defaultValue={currentRoles}
            />
          </StyledFormInputs>
          {errors.interestRoles && <FormValidationMessage message="Select roles of interest" />}

          <StyledButtons>
            <CancelButton onClick={history.goBack}>Cancel</CancelButton>
            <SaveButton>Save</SaveButton>
          </StyledButtons>
        </>
      )}
    </StyledForm>
  );
};

export default ProfileJobTab;
