import {
  DeleteBookmark,
  DeleteBookmarkVariables,
  DeleteHand,
  DeleteHandVariables,
  GetAllJobPostings,
  GetAllJobPostings_recruit_job_posting_bookmark_jobs,
  GetAllJobPostingsVariables,
  InsertBookmark,
  InsertBookmarkVariables,
  InsertHand,
  InsertHandVariables,
  recruit_job_posting_bool_exp,
} from '../../../generated';
import { useMutation, useQuery } from 'react-apollo';
import { DELETE_BOOKMARK, GET_ALL_JOB_POSTINGS, GET_COMPANY_BY_ACCOUNT, INSERT_BOOKMARK } from '../../../gql';
import { DELETE_HAND, INSERT_HAND } from './Job.gql';
import { GetCompanyByAccount, GetCompanyByAccountVariables } from '../../../generated/GetCompanyByAccount';

export const useCompanyQuery = (skip: boolean, accountId: string) => {
  return useQuery<GetCompanyByAccount, GetCompanyByAccountVariables>(GET_COMPANY_BY_ACCOUNT, {
    skip,
    variables: { accountId },
  });
};

export const useInsertBookmarkJobMutation = (
  jobId: string,
  bookmark_jobs: GetAllJobPostings_recruit_job_posting_bookmark_jobs[],
  userId: string,
) => {
  return useMutation<InsertBookmark, InsertBookmarkVariables>(INSERT_BOOKMARK, {
    variables: { userId, jobId },
    errorPolicy: 'all',
    refetchQueries: ['GetAllJobPostings'],
    awaitRefetchQueries: true,
    onError: () => undefined,
  });
};

export const useDeleteBookmarkJobMutation = (
  jobId: string,
  bookmark_jobs: GetAllJobPostings_recruit_job_posting_bookmark_jobs[],
  userId: string,
) => {
  return useMutation<DeleteBookmark, DeleteBookmarkVariables>(DELETE_BOOKMARK, {
    variables: { userId, jobId },
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: ['GetAllJobPostings'],
    onError: () => undefined,
  });
};

export const useLowerHandMutation = (
  userId: string,
  jobId: string,
  dynamicQuery: recruit_job_posting_bool_exp | null | undefined,
) => {
  return useMutation<DeleteHand, DeleteHandVariables>(DELETE_HAND, {
    variables: { userId, jobId },
    errorPolicy: 'all',
    update: (cache, { data: deleteResultData }) => {
      const jobCache = cache.readQuery<GetAllJobPostings, GetAllJobPostingsVariables>({
        query: GET_ALL_JOB_POSTINGS,
        variables: {
          userId,
          dynamicQuery,
        },
      });

      if (!deleteResultData || !deleteResultData.delete_grad_raise_hand) return;
      if (!jobCache || !jobCache.recruit_job_posting) return;

      const oldJob = jobCache.recruit_job_posting[0];
      const deleteId = deleteResultData.delete_grad_raise_hand.returning[0].raise__hand_id;
      const newJob = {
        ...oldJob,
        raise_hands: oldJob.raise_hands.filter(({ raise__hand_id }) => raise__hand_id !== deleteId),
      };

      cache.writeQuery({
        query: GET_ALL_JOB_POSTINGS,
        variables: {
          userId,
          dynamicQuery,
        },
        data: {
          recruit_job_posting: [newJob],
        },
      });
    },
    onError: () => undefined,
  });
};

export const useRaiseHandMutation = (
  userId: string,
  jobId: string,
  dynamicQuery: recruit_job_posting_bool_exp | null | undefined,
) => {
  return useMutation<InsertHand, InsertHandVariables>(INSERT_HAND, {
    variables: { userId, jobId },
    errorPolicy: 'all',
    update: (cache, { data: insertResultData }) => {
      const jobCache = cache.readQuery<GetAllJobPostings, GetAllJobPostingsVariables>({
        query: GET_ALL_JOB_POSTINGS,
        variables: {
          userId,
          dynamicQuery,
        },
      });

      if (!insertResultData || !insertResultData.insert_grad_raise_hand) {
        return;
      }

      if (!jobCache || !jobCache.recruit_job_posting) {
        return;
      }
      const oldJob = jobCache.recruit_job_posting[0];
      const newJob = {
        ...oldJob,
        raise_hands: [...oldJob.raise_hands, ...insertResultData.insert_grad_raise_hand.returning],
      };

      cache.writeQuery({
        query: GET_ALL_JOB_POSTINGS,
        variables: {
          userId,
          dynamicQuery,
        },
        data: {
          recruit_job_posting: [newJob],
        },
      });
    },
    onError: () => undefined,
  });
};
