import gql from 'graphql-tag';

const query = gql`
  query GetCurrentDocName($userId: String!) {
    grad_upload_doc(where: { user_id: { _eq: $userId } }) {
      filename
      upload_doc_id
      user_id
    }
  }
`;

export default query;
