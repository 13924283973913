import React from 'react';
import { ExtraInfoHeader, Header, HeaderInfo, Logo, Tag, TagContainer, TitleDetail } from './DetailViewHeader.styled';
import { EGLogoGrayBackground as JobDefaultLogo } from '../../../theme/assets';

interface Tag {
  text: string;
  color?: string;
}

export interface DetailHeaderProps {
  children?: React.ReactNode;
  logo?: string;
  title: string;
  tags?: Tag[];
}

const DetailViewHeader: React.FC<DetailHeaderProps> = ({ tags, children, logo, title }) => {
  return (
    <Header>
      <Logo src={logo || JobDefaultLogo} />
      <HeaderInfo>
        <TitleDetail>{title}</TitleDetail>
        {children && <ExtraInfoHeader>{children}</ExtraInfoHeader>}
        <TagContainer>
          {tags?.map((tag) => (
            <Tag key={tag.text} color={tag.color}>
              {tag.text}
            </Tag>
          ))}
        </TagContainer>
      </HeaderInfo>
    </Header>
  );
};

export default DetailViewHeader;
