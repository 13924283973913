import React from 'react';
import CompanyDetailHeader, { CompanyDetailHeaderProps } from './CompanyDetailHeader';
import CompanyDetailContent, { CompanyDetailContentProps } from './CompanyDetailContent';
import withDetailView from '../../../../components/common/withDetailView';
import { DetailViewMainButtonProps } from '../../../../components/common/DetailViewMainButton';

interface EventDetailProps extends DetailViewMainButtonProps, CompanyDetailHeaderProps, CompanyDetailContentProps {}

const DetailViewHOC = withDetailView(CompanyDetailHeader, CompanyDetailContent);

const CompanyDetail: React.FC<EventDetailProps> = ({ title, description, logo, address, employeesNumber, website }) => {
  return (
    <DetailViewHOC
      description={description}
      title={title}
      logo={logo}
      address={address}
      employeesNumber={employeesNumber}
      website={website}
    />
  );
};

export default CompanyDetail;
