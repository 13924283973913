import gql from 'graphql-tag';
import { TestScorePart } from './fragments.gql';

const mutation = gql`
  mutation DeleteTestsScores($ids: [String!]!) {
    delete_grad_test_score(where: { test_score_id: { _in: $ids } }) {
      returning {
        ...TestScorePart
      }
    }
  }
  ${TestScorePart}
`;

export default mutation;
