import gql from 'graphql-tag';
import { DegreeParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation DeleteDegree($degreeId: String!) {
    delete_grad_degree(where: { degree_id: { _eq: $degreeId } }) {
      returning {
        ...DegreeParts
      }
    }
  }
  ${DegreeParts}
`;

export default mutation;
