import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { GetEventById, GetEventByIdVariables } from '../../../generated/GetEventById';
import {
  DELETE_EVENT_REGISTRATION,
  GET_EVENT_BY_ID,
  GET_EVENT_REGISTRATION_BY_EVENT_USER,
  INSERT_EVENT_REGISTRATION,
} from './Event.gql';
import {
  DeleteEventRegitration,
  DeleteEventRegitrationVariables,
  GetEventsRegistrationsByUserAndEventId,
  GetEventsRegistrationsByUserAndEventIdVariables,
  InsertEventRegitration,
  InsertEventRegitrationVariables,
} from '../../../generated';
export const useFetchEvent = (eventId: string) => {
  return useQuery<GetEventById, GetEventByIdVariables>(GET_EVENT_BY_ID, {
    variables: { eventId },
    onError: () => undefined,
    fetchPolicy: 'network-only',
  });
};

export const useGetEventsRegistrationsByUserAndEventIdLazyQuery = (eventId: string, userId: string) => {
  return useLazyQuery<GetEventsRegistrationsByUserAndEventId, GetEventsRegistrationsByUserAndEventIdVariables>(
    GET_EVENT_REGISTRATION_BY_EVENT_USER,
    {
      variables: {
        userId,
        eventId,
      },
      fetchPolicy: 'network-only',
      onError: () => undefined,
    },
  );
};

export const useDeleteRegistrationMutation = (eventId: string, userId: string) => {
  return useMutation<DeleteEventRegitration, DeleteEventRegitrationVariables>(DELETE_EVENT_REGISTRATION, {
    variables: {
      userId,
      eventId,
    },
    refetchQueries: [
      {
        query: GET_EVENT_BY_ID,
        variables: {
          eventId,
        },
      },
      {
        query: GET_EVENT_REGISTRATION_BY_EVENT_USER,
        variables: {
          eventId,
          userId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
};

export const useInsertRegistrationMutation = (eventId: string, userId: string) => {
  return useMutation<InsertEventRegitration, InsertEventRegitrationVariables>(INSERT_EVENT_REGISTRATION, {
    variables: {
      userId,
      eventId,
    },
    refetchQueries: [
      {
        query: GET_EVENT_BY_ID,
        variables: {
          eventId,
        },
      },
      {
        query: GET_EVENT_REGISTRATION_BY_EVENT_USER,
        variables: {
          eventId,
          userId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
};
