import gql from 'graphql-tag';
import { AwardParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation WizardRelatedProfile(
    $awardsForUpsert: [grad_award_insert_input!]!
    $awardIdsForDelete: [String!]!
    $funFactsForUpsert: [grad_fun_fact_insert_input!]!
    $funFactIdsForDelete: [String!]!
  ) {
    insert_grad_award(
      objects: $awardsForUpsert
      on_conflict: { constraint: award_award_id_key, update_columns: [year_received, award_name, award_category] }
    ) {
      returning {
        ...AwardParts
      }
    }

    delete_grad_award(where: { award_id: { _in: $awardIdsForDelete } }) {
      returning {
        ...AwardParts
      }
    }

    insert_grad_fun_fact(
      objects: $funFactsForUpsert
      on_conflict: { constraint: fun_fact_fun_fact_id_key, update_columns: [description] }
    ) {
      returning {
        description
        fun_fact_id
        user_id
      }
    }

    delete_grad_fun_fact(where: { fun_fact_id: { _in: $funFactIdsForDelete } }) {
      returning {
        description
        fun_fact_id
        user_id
      }
    }
  }
  ${AwardParts}
`;

export default mutation;
