import React from 'react';
import JobDetail from './JobController';
import DetailViewLayout from '../../../components/common/DetailViewLayout';

const Job: React.FC = () => {
  return (
    <DetailViewLayout>
      <JobDetail />
    </DetailViewLayout>
  );
};

export default Job;
