/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';

import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { palette } from 'theme';

const SEARCH_TIMEOUT_LENGTH = 400;

export interface QueryOption {
  value: string;
  label: string;
}

const QuerySelector: React.FC<{
  searchResults: QueryOption[];
  defaultOptions: QueryOption;
  loading: boolean;
  debounceTime: number;
  callFetchQuery: any;
  name: string;
  control: any;
  required?: boolean;
  placeholder?: string;
  isClearable?: boolean;
}> = ({
  searchResults,
  defaultOptions,
  loading,
  debounceTime = SEARCH_TIMEOUT_LENGTH,
  callFetchQuery,
  name,
  control,
  required = true,
  placeholder = 'Search...',
  isClearable = false,
}) => {
  const defaultValue = defaultOptions.value !== '' ? defaultOptions : null;
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [submitSearchTimer, setSubmitSearchTimer] = useState(0);
  const [localLoading, setLocalLoading] = useState(loading);
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading]);

  useEffect(() => {
    return () => {
      clearTimeout(submitSearchTimer);
    };
  }, [submitSearchTimer]);

  const onInputChange = (displayValue: string, { action }: { action: string }) => {
    if (action !== 'input-change') {
      return;
    }

    setLocalLoading(true);
    setSubmitSearchTimer(
      setTimeout(() => {
        setLocalLoading(loading);
        callFetchQuery({ variables: { searchTerm: `%${displayValue}%` } });
      }, debounceTime),
    );
  };

  const onMenuClose = () => {
    setLocalLoading(false);
  };

  const onMenuOpen = useCallback(() => {
    if (isFirstSearch && (!submitSearchTimer || searchResults.length === 0)) {
      setIsFirstSearch(false);
      callFetchQuery({ variables: { searchTerm: '%%' } });
    }
  }, [isFirstSearch, submitSearchTimer, searchResults, callFetchQuery]);

  const onChange = (newSelectedOption: QueryOption, action: object) => {
    setLocalLoading(false);
    setSelectedOption(newSelectedOption);

    if (!newSelectedOption) {
      return [];
    }

    return [newSelectedOption, action];
  };

  const customControlStyles = (base: any) => ({
    ...base,
    height: 34,
    minHeight: 34,
  });

  return (
    <Controller
      as={
        <StyledSelect
          styles={{ control: customControlStyles }}
          isSearchable={true}
          isLoading={localLoading}
          options={searchResults}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          placeholder={placeholder}
          isClearable={isClearable}
        />
      }
      defaultValue={[selectedOption]}
      name={name}
      control={control}
      rules={{
        validate: ([value]) => !required || (value !== null && value !== undefined),
      }}
      onChange={([selectedOption, action]: [QueryOption, object]) => onChange(selectedOption, action)}
    />
  );
};

const StyledSelect = styled(ReactSelect)`
  margin-bottom: 1.4rem;
  width: 100%;

  & > div {
    box-shadow: none;
    padding: 0;

    border: 1px solid ${palette.lightGray};
    border-radius: 8px;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.7rem;

    ::placeholder {
      color: ${palette.lightGray};
    }
  }
`;

export default QuerySelector;
