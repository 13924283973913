import React from 'react';
import { Header, HeaderTitle, Container } from './MainViewLayout.styled';
import NavBar from '../../NavBar';

interface MainViewProps {
  title: string;
  children: React.ReactNode;
}

const MainViewLayout: React.FC<MainViewProps> = ({ title, children }) => {
  return (
    <Container>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      {children}
      <NavBar />
    </Container>
  );
};

export default MainViewLayout;
