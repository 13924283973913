import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertRegionInterests($regions: [grad_interest_region_insert_input!]!) {
    insert_grad_interest_region(objects: $regions) {
      returning {
        region
        interest_region_id
      }
    }
  }
`;

export default mutation;
