import React from 'react';
import styled from 'styled-components';

import { SpinnerRedImage, SpinnerWhiteImage } from 'theme/assets';

export interface SpinnerProps {
  white?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ white = false, ...props }) => {
  const spinnerImage = white ? SpinnerWhiteImage : SpinnerRedImage;

  return (
    <StyledSpinner {...props}>
      <StyledSpinnerImage src={spinnerImage} />
    </StyledSpinner>
  );
};

const StyledSpinnerImage = styled.img`
  display: block;
  width: 64px;
  height: 64px;

  margin: 0 auto;
`;

const StyledSpinner = styled.div`
  width: 100%;
  margin: 20% auto 0;

  background-color: transparent;
`;

export default Spinner;
