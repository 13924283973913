import React from 'react';
import Select, { mergeStyles } from 'react-select';
import { Controller, FormContextValues } from 'react-hook-form';
import { selectorStyle } from 'theme';
import { Option } from './Option';

export interface Selector extends Pick<FormContextValues, 'control'> {
  options: Option[];
  defaultValue: Option | null;
  name: string;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isSearchable?: boolean;
}

const Selector: React.FC<Selector> = ({
  options,
  defaultValue,
  name,
  control,
  required = true,
  isClearable = false,
  disabled = false,
  placeholder = 'Select...',
  loading = false,
  isSearchable = true,
}) => {
  const overrideStyles = {
    container: (base: object) => ({
      ...base,
      height: '3.4rem',
      minHeight: '3.4rem',
    }),
    control: (base: object) => ({
      ...base,
      height: '3.4rem',
      minHeight: '3.4rem',
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

  const select = (
    <Select
      options={options}
      isClearable={isClearable}
      isMulti={false}
      maxMenuHeight={180}
      styles={selectorOwnStyles}
      placeholder={placeholder}
      isDisabled={disabled}
      isLoading={loading}
      isSearchable={isSearchable}
    />
  );

  return (
    <Controller
      as={select}
      defaultValue={defaultValue}
      name={name}
      control={control}
      onChange={([selected]) => {
        return { value: selected };
      }}
      rules={{ required }}
    />
  );
};

export default Selector;
