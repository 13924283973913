import gql from 'graphql-tag';

export default gql`
  query GetAllCompanies {
    recruit_company {
      logo
      company_id
      name
      industry
      num_employees
      subindustries {
        value
      }
    }
  }
`;
