import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from 'react-auth0-spa';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';

import { palette } from 'theme';
import SpinnerPage from 'components/SpinnerPage';

import { SET_WIZARD_COMPLETE } from './gql';

import { SetWizardComplete, SetWizardCompleteVariables } from 'generated/SetWizardComplete';

import { SaveButton } from 'components';
import { WizardProps } from '../components/interface';
import { DoneCheck } from 'theme/assets';

const DoneScreen: React.FC<WizardProps> = () => {
  const { user } = useAuth0();
  const { handleSubmit } = useForm<SetWizardCompleteVariables>();

  const [completeWizard, { loading: mutationLoading, error: mutationError }] = useMutation<
    SetWizardComplete,
    SetWizardCompleteVariables
  >(SET_WIZARD_COMPLETE, {
    errorPolicy: 'all',
    onError: () => undefined,
    onCompleted: () => {
      // Temporal fix: in order to avoid the routing to wizard after complete it
      // we introduce this temporal fix due of urgency, should work with routings
      // and manage of cache witout reload the application
      !mutationError && window.location.reload();
    },
  });

  const onSubmit = async () => {
    await completeWizard({
      variables: {
        myUserId: user.sub,
      },
    });
  };

  if (mutationLoading) return mutationLoading && <SpinnerPage />;

  return (
    <DoneForm onSubmit={handleSubmit(onSubmit)}>
      <StyledDiv>
        <img color="white" src={DoneCheck} alt="logo" />
        <StyledDone>Done!</StyledDone>
      </StyledDiv>
      <FinishButton type="submit">View and enhance your profile</FinishButton>
    </DoneForm>
  );
};

const FinishButton = styled(SaveButton)`
  position: fixed;
  bottom: 1.6rem;
`;

const StyledDiv = styled.div`
  position: fixed;
  top: 25%;
`;

const DoneForm = styled.form`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledDone = styled.h1`
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  text-align: center;
  color: ${palette.red};
`;

export default DoneScreen;
