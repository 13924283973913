import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from 'react-auth0-spa';
import { useQuery } from 'react-apollo';

import { notify } from 'utils/notify';
import { Spinner } from 'components';
import { Headline, EditButton } from '../library';
import { StudentViewPaths } from '../../StudentViewRoutes';

import { TestTypes } from 'data/tests';

import { GetMyTestScores, GetMyTestScoresVariables, GetMyTestScores_grad_test_score } from 'generated/GetMyTestScores';
import { GET_MY_TEST_SCORES } from 'gql';
import CollapsibleContainer from '../CollapseDiv';
import TestScoreCardSingle from './TestScoreCardSingle';
import TestScoreCardWithDetails from './TestScoreCardWithDetails';

const TestScoreList: React.FC = () => {
  const { user } = useAuth0();

  const { loading, data: rawTestsData = { grad_test_score: [] } } = useQuery<GetMyTestScores, GetMyTestScoresVariables>(
    GET_MY_TEST_SCORES,
    {
      variables: { myUserId: user.sub },
      errorPolicy: 'all',
      onError: (error) => {
        notify('GetMyTestScoresToast', error.message);
      },
    },
  );

  const firstTest = rawTestsData.grad_test_score.find(({ test }: { test: string }) => test === TestTypes.SAT);
  const secondTest = rawTestsData.grad_test_score.find(({ test }: { test: string }) => test === TestTypes.ACT);
  const otherTests = rawTestsData.grad_test_score.filter(
    ({ test }: { test: string }) => test !== TestTypes.SAT && test !== TestTypes.ACT,
  );

  return (
    <StyledTestScoreDetails>
      <Headline>
        <StyledTitle>Test Scores</StyledTitle>
        <EditButton to={StudentViewPaths.testScoresEdit}></EditButton>
      </Headline>
      {loading ? (
        <Spinner />
      ) : (
        <CollapsibleContainer>
          {[
            ...(firstTest ? [<TestScoreCardWithDetails key={firstTest.test_score_id} testScore={firstTest} />] : []),
            ...(secondTest ? [<TestScoreCardSingle key={secondTest.test_score_id} testScore={secondTest} />] : []),
            ...otherTests.map((testScore: GetMyTestScores_grad_test_score) => (
              <TestScoreCardSingle key={testScore.test_score_id} testScore={testScore} />
            )),
          ]}
        </CollapsibleContainer>
      )}
    </StyledTestScoreDetails>
  );
};

const StyledTestScoreDetails = styled.div`
  position: relative;
  margin: 1.6rem 1rem;
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;

  line-height: 2.1rem;
`;

export default TestScoreList;
