import gql from 'graphql-tag';

const query = gql`
  query GetWizardComplete($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      wizard_completed
      user_id
    }
  }
`;

export default query;
