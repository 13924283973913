import gql from 'graphql-tag';

import { EventParts, EventRegistrationParts } from '../../../gql/fragments.gql';

export const GET_EVENT_BY_ID = gql`
  query GetEventById($eventId: String!) {
    recruit_event(where: { event_id: { _eq: $eventId } }) {
      ...EventParts
    }
  }
  ${EventParts}
`;

export const GET_EVENT_REGISTRATION_BY_EVENT_USER = gql`
  query GetEventsRegistrationsByUserAndEventId($eventId: String, $userId: String) {
    grad_event_registration(where: { event_id: { _eq: $eventId }, user_id: { _eq: $userId } }) {
      ...EventRegistrationParts
    }
  }
  ${EventRegistrationParts}
`;

export const INSERT_EVENT_REGISTRATION = gql`
  mutation InsertEventRegitration($eventId: String, $userId: String) {
    insert_grad_event_registration(objects: { event_id: $eventId, user_id: $userId }) {
      returning {
        ...EventRegistrationParts
      }
    }
  }
  ${EventRegistrationParts}
`;

export const DELETE_EVENT_REGISTRATION = gql`
  mutation DeleteEventRegitration($eventId: String, $userId: String) {
    delete_grad_event_registration(where: { event_id: { _eq: $eventId }, user_id: { _eq: $userId } }) {
      returning {
        ...EventRegistrationParts
      }
    }
  }
  ${EventRegistrationParts}
`;
