import gql from 'graphql-tag';
import { GradProfileParts } from 'gql/fragments.gql';

const query = gql`
  query WizardGetMyVisa($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      ...LegalParts
    }
  }
  ${GradProfileParts}
`;

export default query;
