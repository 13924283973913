import * as React from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';
import { palette } from 'theme';
import { Plus, Edit } from 'theme/assets';

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIconNavLink = styled(NavLink)`
  width: 2.4rem;
  height: 2.4rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: ${palette.gray};

  :hover {
    cursor: pointer;
  }
`;

const StyledEditButton = styled(StyledIconNavLink)`
  position: absolute;
  top: 0rem;
  right: 0rem;
`;

const StyledIcon = styled.img`
  height: 1.6rem;

  vertical-align: middle;
`;

export const EditButton: React.FC<{ to: string }> = ({ to, ...props }) => (
  <StyledEditButton to={to} {...props}>
    <StyledIcon src={Edit} />
  </StyledEditButton>
);

export const NewButton: React.FC<{ to: string }> = ({ to, ...props }) => (
  <StyledIconNavLink to={to} {...props}>
    <StyledIcon src={Plus} />
  </StyledIconNavLink>
);

export const BorderDiv = styled.div`
  margin: 2rem;
  height: 0.1rem;
  width: 90%;
  border: 1px solid ${palette.lightGray};
`;

export const LeftColumn = styled.div`
  text-align: left;
  padding-right: 0.8rem;
  width: 50%;
`;

export const RightColumn = styled.div`
  text-align: right;
  min-width: 36%;
`;

export const StyledTitle = styled(LeftColumn)`
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  width: 90%;
`;
