import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertFirmInterests($firms: [grad_interest_firm_insert_input!]!) {
    insert_grad_interest_firm(objects: $firms) {
      returning {
        firm_type
        interest_firm_id
      }
    }
  }
`;

export default mutation;
