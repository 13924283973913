import gql from 'graphql-tag';
import { InterestJobTypeSelectionParts } from './fragments.gql';

const query = gql`
  query GetJobTypeInterestSelections {
    grad_interest_job_type_selection {
      ...InterestJobTypeSelectionParts
    }
  }
  ${InterestJobTypeSelectionParts}
`;

export default query;
