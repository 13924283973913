import { useQuery } from 'react-apollo';
import { GetCompanyByPk, GetCompanyByPkVariables } from '../../../generated/GetCompanyByPk';
import { GET_COMPANY } from './Company.gql';

export const useCompanyQuery = (companyId: number) => {
  return useQuery<GetCompanyByPk, GetCompanyByPkVariables>(GET_COMPANY, {
    skip: !companyId || Number.isNaN(companyId),
    variables: {
      companyId,
    },
  });
};
