import React, { useState } from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';

import { notify } from 'utils/notify';
import { SUBMIT_GO_TO, CANCEL_GO_TO, WizardContainer, WizardFormInputs, WizardFormButtons } from '../components';
import { WizardProps } from '../components/interface';
import { Title, Photo } from './PictureStep.styled';
import PictureEditor from 'components/PictureEditor/PictureEditor';
import { Spinner } from 'components';

import { EGLogo as DefaultAvatar } from 'theme/assets';

import { useQuery, useMutation } from 'react-apollo';
import { GET_PHOTO, UPLOAD_PHOTO } from 'gql';
import { GetPhoto, GetPhotoVariables, UploadPhoto, UploadPhotoVariables } from 'generated';
import AvatarEditor from 'react-avatar-editor';

const PictureStep: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
}) => {
  const history = useHistory();
  const { user } = useAuth0();
  const [image, setImage] = useState<string>();
  const [editor, setEditor] = useState<AvatarEditor>();

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const { loading, error } = useQuery<GetPhoto, GetPhotoVariables>(GET_PHOTO, {
    fetchPolicy: 'no-cache',
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onCompleted: (req) => {
      if (req.grad_profile[0].profile_image) {
        setImage(req?.grad_profile[0].profile_image);
      } else {
        setImage(DefaultAvatar);
      }
    },
  });

  const [uploadPhotoMutation] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO);

  if (error) {
    const queryToastId = 'WizardGetMyPictureToast';
    notify(queryToastId, 'There was an issue uploading your profile picture, please try again later.');
  }

  const updateImage = async (imageFile: string) => {
    setImage(imageFile);
  };

  const onDelete = () => {
    setImage(DefaultAvatar);
  };

  const onNext = async () => {
    const profileImage =
      !image || image === DefaultAvatar ? null : editor ? editor.getImageScaledToCanvas().toDataURL() : image;

    await uploadPhotoMutation({ variables: { userId: user.sub, profileImage } });
    history.push(nextGoTo);
  };

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <WizardFormInputs>
        <Title>Profile picture</Title>
        {loading ? (
          <Spinner />
        ) : (
          <Photo>
            {image && (
              <PictureEditor queryImage={image} onChange={updateImage} onDelete={onDelete} editorRef={editorRef} />
            )}
          </Photo>
        )}
      </WizardFormInputs>
      <WizardFormButtons
        step={step}
        isLastStep={isLastStep}
        cancelSkipGoTo={cancelSkipGoTo}
        onNext={onNext}
        onSkip={() => history.push(nextGoTo)}
      />
    </WizardContainer>
  );
};

export default PictureStep;
