import React from 'react';
import { Card } from '../../../../components/common/CardList';
import { Industry, ItemContainer, Label } from './CompanyListCard.styled';
import { companyPath } from '../../StudentViewRoutes';

export interface CompanyListCardProps {
  logo: string | null;
  companyId: number;
  numEmployees: string | null;
  name: string;
  industry: string | null;
  subIndustries: string[];
}

const CompanyListCard: React.FC<CompanyListCardProps> = ({
  name,
  numEmployees,
  industry,
  companyId,
  logo,
  subIndustries,
}) => {
  const tags = subIndustries.map((sub) => ({ text: sub }));
  return (
    <Card header={name} cardLink={companyPath(companyId)} logo={logo} tags={tags}>
      {industry && (
        <ItemContainer>
          <Industry>{industry}</Industry>
        </ItemContainer>
      )}
      {numEmployees && (
        <ItemContainer>
          <Label>Employer Count</Label>
          {numEmployees}
        </ItemContainer>
      )}
    </Card>
  );
};

export default CompanyListCard;
