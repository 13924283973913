export const languagesNames = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Cantonese',
  'Castilian',
  'Catalan',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chewa',
  'Chichewa',
  'Chuang',
  'Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Dhivehi',
  'Divehi',
  'Dutch',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'Flemish',
  'French',
  'Fulah',
  'Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Gikuyu',
  'Greek (Modern)',
  'Greenlandic',
  'Guarani',
  'Gujarati',
  'Haitian',
  'Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kalaallisut',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kikuyu',
  'Kinyarwanda',
  'Komi',
  'Kongo',
  'Korean',
  'Kuanyama',
  'Kurdish',
  'Kwanyama',
  'Kyrgyz',
  'Lao',
  'Latin',
  'Latvian',
  'Letzeburgesch',
  'Limburgan',
  'Limburger',
  'Limburgish',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Luxembourgish',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maldivian',
  'Maltese',
  'Mandarin',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Moldavian',
  'Moldovan',
  'Mongolian',
  'Nauru',
  'Navaho',
  'Navajo',
  'Ndonga',
  'Nepali',
  'Northern Ndebele',
  'Northern Sami',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Nuosu',
  'Nyanja',
  'Occitan (post 1500)',
  'Ojibwa',
  'Old Bulgarian',
  'Old Church Slavonic',
  'Oriya',
  'Oromo',
  'Ossetian',
  'Ossetic',
  'Pali',
  'Panjabi',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Pushto',
  'Quechua',
  'Romanian',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Scottish Gaelic',
  'Serbian',
  'Shona',
  'Sichuan Yi',
  'Sindhi',
  'Sinhala',
  'Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho',
  'South Ndebele',
  'Southern',
  'Spanish',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur',
  'Ukrainian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Valencian',
  'Venda',
  'Vietnamese',
  'Volap_k',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang',
  'Zulu',
];
