import gql from 'graphql-tag';
import { CountrySelectionParts } from './fragments.gql';

const query = gql`
  query GetCountrySelections {
    grad_country_selection {
      ...CountrySelectionParts
    }
  }
  ${CountrySelectionParts}
`;

export default query;
