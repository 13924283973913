import React, { useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import { useAuth0 } from 'react-auth0-spa';
import styled from 'styled-components';

import { FormValidationMessage, CancelButton, SaveButton, Spinner } from 'components';
import GPAInput from 'components/GPAInput';
import { MonthYearPicker } from 'components/MonthYearPicker';
import GoBackHeader from 'components/GoBackHeader';
import DegreePicker, { DegreePickerOption } from 'components/DegreePicker';
import SelectorCreateV2 from 'components/SelectorCreateV2';
import { OptionId, createOptionId } from 'components/Option';

import { YearPicker } from 'components/YearPicker';
import { MajorSelector } from 'components/MajorMinorSelector/MajorSelector';
import { MinorSelector } from 'components/MajorMinorSelector/MinorSelector';

import {
  StyledInputLabel,
  StyledForm,
  StyledFormInputs,
  StyledLeftH1,
  StyledButtons,
  StyledDeleteButton,
  StyledCheckboxContainer,
} from 'components/library';

import { AwardsTypes, ACADEMIC_HONORS_OPTIONS } from 'data/awards';
import {
  INSERT_AWARDS,
  DELETE_AWARDS,
  DELETE_CONCENTRATIONS,
  INSERT_CONCENTRATIONS,
  INSERT_FILL_UNIVERSITY,
  GET_MY_PROFILE,
  INSERT_MY_DEGREE,
  UPDATE_MY_DEGREE,
  DELETE_MY_DEGREE,
  GET_MY_DEGREE,
} from 'gql';
import { InsertAwards, InsertAwardsVariables } from 'generated/InsertAwards';
import { DeleteAwards, DeleteAwardsVariables } from 'generated/DeleteAwards';
import { InsertDegree, InsertDegreeVariables } from 'generated/InsertDegree';
import { UpdateDegree, UpdateDegreeVariables } from 'generated/UpdateDegree';
import { GetDegree, GetDegreeVariables, GetDegree_grad_degree_awards } from 'generated/GetDegree';
import { GetMyProfile, GetMyProfileVariables } from 'generated/GetMyProfile';
import { DeleteDegree } from 'generated/DeleteDegree';

import { DeleteConcentrations, DeleteConcentrationsVariables } from 'generated/DeleteConcentrations';
import { InsertConcentrations, InsertConcentrationsVariables } from 'generated/InsertConcentrations';
import { ConcentrationTypes } from 'data/degree';
import {
  createConcentrationOptions,
  prepareConcentrationForUpsert,
} from 'components/MajorMinorSelector/concentrationUtils';
import CheckboxWithController from 'components/CheckboxWithController';
import UniversitySelectorCreate from 'components/UniversitySelector/UniversitySelectorCreate';
import { InsertFillUniversity, InsertFillUniversityVariables } from 'generated/InsertFillUniversity';

interface DegreeFormVariables {
  gradEndMonth: { value: number; label: string };
  gradEndYear: { value: number; label: string };
  gradStartMonth: { value: number; label: string };
  gradStartYear: { value: number; label: string };
  GPA: string;
  gpaOutOf: { value: number; label: string };
  degreeSelectionOption: DegreePickerOption[];
  universitySelectionIdOption: [{ id: string; value: string }];
  academicHonors: { optionValue: OptionId | null; deletedOptionId: string | null };
  academicHonorsYearReceived: { value: number; label: string };
  majors: { optionsValue: OptionId[] | null; deletedOptionsIds: string[] | null };
  minors: { optionsValue: OptionId[] | null; deletedOptionsIds: string[] | null };
  gpaNotApplicable: boolean;
}

const StyledAcademicHonors = styled.div`
  display: flex;
  width: 100%;
`;

const StyledAcademicHonorsRow = styled.div`
  display: flex;
  align-items: flex-start;

  width: 100%;

  & > * {
    width: 50%;
  }

  & > *:last-child {
    margin-left: 0.8rem;
  }
`;

const StyledAcademicHonorsSelector = styled(SelectorCreateV2)`
  height: 3.4rem;
`;

const DegreeDetail: React.FC = () => {
  const { register, handleSubmit, errors, control, setValue, setError, clearError, watch } = useForm<
    DegreeFormVariables
  >();

  const { value: watchedOutOf } = watch('gpaOutOf') || { value: null };
  const watchedNotApplicable = !!watch('gpaNotApplicable');

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    user: { sub: myUserId },
  } = useAuth0();
  const [insertDegree] = useMutation<InsertDegree, InsertDegreeVariables>(INSERT_MY_DEGREE, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetMyProfile, GetMyProfileVariables>({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
      });
      if (!myProfileData) {
        return;
      }

      if (!insertResultData || !insertResultData.insert_grad_degree) {
        return;
      }

      const oldGradProfile = myProfileData.grad_profile[0];
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: [...oldGradProfile.degrees, ...insertResultData.insert_grad_degree.returning],
      };

      cache.writeQuery({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
  });

  const [insertFillUniversity] = useMutation<InsertFillUniversity, InsertFillUniversityVariables>(
    INSERT_FILL_UNIVERSITY,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const [updateDegree] = useMutation<UpdateDegree, UpdateDegreeVariables>(UPDATE_MY_DEGREE, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetMyProfile, GetMyProfileVariables>({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
      });

      if (!myProfileData) {
        return;
      }

      if (!insertResultData || !insertResultData.update_grad_degree) {
        return;
      }

      const oldGradProfile = myProfileData.grad_profile[0];
      const returningDegreesIds = insertResultData.update_grad_degree.returning.map(({ degree_id }) => degree_id);
      const oldDegreesWithoutReturning = oldGradProfile.degrees.filter(
        ({ degree_id }) => !returningDegreesIds.includes(degree_id),
      );
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: [...oldDegreesWithoutReturning, ...insertResultData.update_grad_degree.returning],
      };

      cache.writeQuery({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
  });

  const [deleteMyDegree] = useMutation<DeleteDegree>(DELETE_MY_DEGREE, {
    errorPolicy: 'all',
    variables: { degreeId: id },
    update: (cache, { data: deleteResultData }) => {
      const myProfileData = cache.readQuery<GetMyProfile, GetMyProfileVariables>({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
      });

      if (!myProfileData) {
        return;
      }

      if (!deleteResultData || !deleteResultData.delete_grad_degree) {
        return;
      }

      const oldGradProfile = myProfileData.grad_profile[0];
      const deleteId = deleteResultData.delete_grad_degree.returning[0].degree_id;
      const newMyGradProfile = {
        ...oldGradProfile,
        degrees: oldGradProfile.degrees.filter(({ degree_id }) => degree_id !== deleteId),
      };

      cache.writeQuery({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
        data: {
          grad_profile: [newMyGradProfile],
        },
      });
    },
    onError: () => undefined,
  });

  const [insertAwards] = useMutation<InsertAwards, InsertAwardsVariables>(INSERT_AWARDS, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      if (!id) return;

      const myDegreeCache = cache.readQuery<GetDegree, GetDegreeVariables>({
        query: GET_MY_DEGREE,
        variables: { myDegreeId: id },
      });

      if (!insertResultData || !insertResultData.insert_grad_award) return;
      if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const insertedAwards = insertResultData.insert_grad_award.returning;
      const newGradDegree = { ...oldGradDegree, awards: insertedAwards };

      cache.writeQuery({
        query: GET_MY_DEGREE,
        variables: { myDegreeId: id },
        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });

  const [deleteAwards] = useMutation<DeleteAwards, DeleteAwardsVariables>(DELETE_AWARDS, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: deleteReturning }) => {
      if (!id) return;
      const myDegreeCache = cache.readQuery<GetDegree, GetDegreeVariables>({
        query: GET_MY_DEGREE,
        variables: { myDegreeId: id },
      });

      if (!deleteReturning || !deleteReturning.delete_grad_award) return;
      if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

      const oldGradDegree = myDegreeCache.grad_degree[0];
      const deletedIds = deleteReturning?.delete_grad_award.returning?.map(({ award_id }) => award_id);
      const newAwards = oldGradDegree.awards.filter(({ award_id }) => !deletedIds.includes(award_id));
      const newGradDegree = { ...oldGradDegree, awards: newAwards };

      cache.writeQuery({
        query: GET_MY_DEGREE,
        variables: { myDegreeId: id },

        data: {
          grad_degree: [newGradDegree],
        },
      });
    },
  });

  const [insertConcentrations] = useMutation<InsertConcentrations, InsertConcentrationsVariables>(
    INSERT_CONCENTRATIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
      update: (cache, { data: insertResultData }) => {
        if (!id) return;
        const myDegreeCache = cache.readQuery<GetDegree, GetDegreeVariables>({
          query: GET_MY_DEGREE,
          variables: { myDegreeId: id },
        });

        if (!insertResultData || !insertResultData.insert_grad_concentration) return;
        if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

        const oldGradDegree = myDegreeCache.grad_degree[0];
        const insertedConcentrations = insertResultData.insert_grad_concentration.returning;
        const newGradDegree = {
          ...oldGradDegree,
          concentrations: [...oldGradDegree.concentrations, ...insertedConcentrations],
        };

        cache.writeQuery({
          query: GET_MY_DEGREE,
          variables: { myDegreeId: id },
          data: {
            grad_degree: [newGradDegree],
          },
        });
      },
    },
  );

  const [deleteConcentrations] = useMutation<DeleteConcentrations, DeleteConcentrationsVariables>(
    DELETE_CONCENTRATIONS,
    {
      errorPolicy: 'all',
      onError: () => undefined,
      update: (cache, { data: deleteReturning }) => {
        if (!id) return;
        const myDegreeCache = cache.readQuery<GetDegree, GetDegreeVariables>({
          query: GET_MY_DEGREE,
          variables: { myDegreeId: id },
        });

        if (!deleteReturning || !deleteReturning.delete_grad_concentration) return;
        if (!myDegreeCache || !myDegreeCache.grad_degree[0]) return;

        const oldGradDegree = myDegreeCache.grad_degree[0];
        const deletedIds = deleteReturning.delete_grad_concentration.returning.map(
          ({ concentration_id }) => concentration_id,
        );
        const newConcentrations = oldGradDegree.concentrations.filter(
          ({ concentration_id }) => !deletedIds.includes(concentration_id),
        );
        const newGradDegree = { ...oldGradDegree, concentrations: newConcentrations };

        cache.writeQuery({
          query: GET_MY_DEGREE,
          variables: { myDegreeId: id },

          data: {
            grad_degree: [newGradDegree],
          },
        });
      },
    },
  );

  const { loading: loadingMyDegree, error: errorMyDegree, data: dataMyDegree } = useQuery<
    GetDegree,
    GetDegreeVariables
  >(GET_MY_DEGREE, {
    skip: !id,
    variables: { myDegreeId: id },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const defaultDegreeValues = {
    university: { university_selection_id: '', location_name: '' },
    complete_year: null,
    complete_month_numeric: null,
    start_year: null,
    start_month_numeric: null,
    degree_level: '',
    gpa: 0,
    gpa_outof: null,
    degree: '',
    awards: [],
    concentrations: [],
    gpa_not_applicable: false,
    fill_in_university: { institution_name: '' },
  };
  const degreeDetails = (dataMyDegree && dataMyDegree.grad_degree[0]) || defaultDegreeValues;
  const {
    concentrations,
    university = defaultDegreeValues.university,
    complete_year,
    complete_month_numeric,
    start_year,
    start_month_numeric,
    gpa,
    gpa_outof,
    degree = defaultDegreeValues.degree,
    degree_level = defaultDegreeValues.degree_level,
    gpa_not_applicable = defaultDegreeValues.gpa_not_applicable,
    fill_in_university = defaultDegreeValues.fill_in_university,
  } = degreeDetails;

  const universityName = university ? university.location_name : '';
  const universityId = university ? university.university_selection_id : '';
  const fillInUniversity = fill_in_university ? fill_in_university.institution_name : '';

  const majorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MAJOR);
  const minorOptions: OptionId[] = createConcentrationOptions(concentrations, ConcentrationTypes.MINOR);

  const academicHonors: GetDegree_grad_degree_awards = (degreeDetails.awards.length ? degreeDetails.awards : []).filter(
    (award: GetDegree_grad_degree_awards) => award.award_category === AwardsTypes.ACADEMIC_HONOR,
  )[0];

  const academicHonorsOption = useMemo(
    () => (academicHonors ? createOptionId(academicHonors.award_name, academicHonors.award_id) : null),
    [academicHonors],
  );

  let persistedDegreeId = dataMyDegree ? dataMyDegree.grad_degree[0].degree_id : null;

  const watchAcademicHonorsField = watch('academicHonors');

  const onDelete = async () => {
    await deleteMyDegree();
    history.goBack();
  };

  const onSubmit = async (data: DegreeFormVariables) => {
    const {
      gradEndMonth,
      gradEndYear,
      gradStartMonth,
      gradStartYear,
      universitySelectionIdOption: [{ id: universitySelectionId, value: universitySelectionValue }],
      GPA = watchedNotApplicable ? null : data.GPA,
      gpaOutOf = watchedNotApplicable ? null : { value: data.gpaOutOf },
      academicHonors: { optionValue: academicHonorsOption, deletedOptionId: deletedAcademicHonorsId },
      academicHonorsYearReceived,
      majors: { optionsValue: optionsMajors, deletedOptionsIds: deletedMajorsIds },
      minors: { optionsValue: optionsMinors, deletedOptionsIds: deletedMinorsIds },
      degreeSelectionOption: [{ degree, degree_level }],
      gpaNotApplicable,
    } = data;
    const variables = {
      gradEndMonth: gradEndMonth.value,
      gradEndYear: gradEndYear.value,
      gradStartMonth: gradStartMonth.value,
      gradStartYear: gradStartYear.value,
      ...(universitySelectionId !== undefined && { universitySelectionId: universitySelectionId }),
      myGPA: GPA ? Number(GPA) : null,
      gpaOutOf: gpaOutOf ? gpaOutOf.value : null,
      degreeName: degree,
      degreeLevel: degree_level,
      gpaNotApplicable,
    };

    if (universitySelectionId === undefined && !universitySelectionValue.length) return;

    if (!persistedDegreeId) {
      const insertDegreeResult = await insertDegree({ variables: { myUserId, ...variables } });
      persistedDegreeId =
        insertDegreeResult && insertDegreeResult.data && insertDegreeResult.data.insert_grad_degree
          ? insertDegreeResult.data.insert_grad_degree.returning[0].degree_id
          : null;
    } else {
      await updateDegree({ variables: { degreeId: id, ...variables } });
    }

    if (persistedDegreeId) {
      if (universitySelectionId === undefined && universitySelectionValue.length) {
        const fillInUniversity = { name: universitySelectionValue, degreeId: persistedDegreeId, userId: myUserId };
        await insertFillUniversity({ variables: fillInUniversity });
      } else {
      }

      if (academicHonorsOption) {
        const academicHonorsForUpsert = {
          award_category: AwardsTypes.ACADEMIC_HONOR,
          award_name: academicHonorsOption.value,
          year_received: academicHonorsYearReceived.value,
          degree_id: persistedDegreeId,
          user_id: myUserId,
          ...(academicHonorsOption.id ? { award_id: academicHonorsOption.id } : {}),
        };

        await insertAwards({ variables: { awards: [academicHonorsForUpsert] } });
      }

      const majorsForUpsert = prepareConcentrationForUpsert(
        optionsMajors,
        persistedDegreeId,
        myUserId,
        id,
        ConcentrationTypes.MAJOR,
      );
      const minorsForUpsert = prepareConcentrationForUpsert(
        optionsMinors,
        persistedDegreeId,
        myUserId,
        id,
        ConcentrationTypes.MINOR,
      );
      const concentrationsForUpsert = [...majorsForUpsert, ...minorsForUpsert];

      if (concentrationsForUpsert)
        await insertConcentrations({ variables: { concentrations: concentrationsForUpsert } });

      if (deletedAcademicHonorsId) {
        await deleteAwards({ variables: { ids: [deletedAcademicHonorsId] } });
      }

      const deleteConcentrationsIds = [...(deletedMajorsIds || []), ...(deletedMinorsIds || [])];
      if (deleteConcentrationsIds) {
        await deleteConcentrations({ variables: { ids: deleteConcentrationsIds } });
      }
    }

    history.goBack();
  };

  const isAcademicHonorsYearReceivedRequired = !!(watchAcademicHonorsField && watchAcademicHonorsField.optionValue);
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GoBackHeader />
      <StyledLeftH1>Education</StyledLeftH1>
      {loadingMyDegree || (id && (!dataMyDegree || !dataMyDegree.grad_degree.length)) ? (
        <Spinner />
      ) : errorMyDegree ? (
        <div>Error</div>
      ) : (
        <>
          <StyledFormInputs>
            <StyledInputLabel>Name of College or University</StyledInputLabel>
            <UniversitySelectorCreate
              required={true}
              fillInValue={fillInUniversity}
              value={{ id: universityId, label: universityName, value: universityName }}
              name={'universitySelectionIdOption'}
              control={control}
            ></UniversitySelectorCreate>
            {errors.universitySelectionIdOption && <FormValidationMessage message="School is required." />}

            <StyledInputLabel>Start Date</StyledInputLabel>
            <MonthYearPicker
              required={true}
              monthFieldName="gradStartMonth"
              yearFieldName="gradStartYear"
              errors={errors}
              control={control}
              defaultMonth={start_month_numeric}
              defaultYear={start_year}
            ></MonthYearPicker>

            <StyledInputLabel>Graduation Date</StyledInputLabel>
            <MonthYearPicker
              required={true}
              monthFieldName="gradEndMonth"
              yearFieldName="gradEndYear"
              errors={errors}
              control={control}
              defaultMonth={complete_month_numeric}
              defaultYear={complete_year}
            ></MonthYearPicker>

            <StyledInputLabel>What is your Degree?</StyledInputLabel>
            <DegreePicker
              name={'degreeSelectionOption'}
              control={control}
              defaultDegree={degree}
              defaultLevel={degree_level}
              clearable
              required={true}
            />

            <StyledCheckboxContainer>
              <CheckboxWithController name="gpaNotApplicable" control={control} defaultChecked={!!gpa_not_applicable} />
              {'GPA is not applicable'}
            </StyledCheckboxContainer>

            {!watchedNotApplicable && (
              <GPAInput
                required={true}
                defaultOutOf={gpa_outof}
                defaultGPA={gpa ? gpa : null}
                watchedOutOf={watchedOutOf}
                gpaFieldName="GPA"
                outOfFieldName="gpaOutOf"
                control={control}
                register={register}
              />
            )}
            {!watchedNotApplicable && errors.GPA && <FormValidationMessage message={errors.GPA.message || ''} />}

            <StyledInputLabel>Major</StyledInputLabel>
            <MajorSelector
              required={true}
              majorsValue={majorOptions}
              name={'majors'}
              register={register}
              setValue={setValue}
            />
            {errors.majors && <FormValidationMessage message="Major is required." />}

            <StyledInputLabel>Minor</StyledInputLabel>
            <MinorSelector
              required={false}
              minorsValue={minorOptions}
              name={'minors'}
              register={register}
              setValue={setValue}
            />

            <StyledAcademicHonors>
              <StyledAcademicHonorsRow>
                <StyledInputLabel>What are your academic honors?</StyledInputLabel>
                <StyledInputLabel>Year received</StyledInputLabel>
              </StyledAcademicHonorsRow>
            </StyledAcademicHonors>

            <StyledAcademicHonors>
              <StyledAcademicHonorsRow>
                <StyledAcademicHonorsSelector
                  dropDownIndicator={true}
                  dropDownOptions={ACADEMIC_HONORS_OPTIONS}
                  defaultOptionValue={academicHonorsOption}
                  name="academicHonors"
                  placeholder="Type your academic honors"
                  register={register}
                  setValue={setValue}
                  setError={setError}
                  clearError={clearError}
                />
                <div>
                  <YearPicker
                    defaultYear={academicHonors && academicHonors.year_received}
                    name={`academicHonorsYearReceived${isAcademicHonorsYearReceivedRequired ? '' : 'disabled'}`}
                    required={isAcademicHonorsYearReceivedRequired}
                    control={control}
                    disabled={!isAcademicHonorsYearReceivedRequired}
                  />
                  {errors.academicHonorsYearReceived && <FormValidationMessage message="Year received is required." />}
                </div>
              </StyledAcademicHonorsRow>
            </StyledAcademicHonors>

            {persistedDegreeId && <StyledDeleteButton onClick={onDelete}>Remove Education</StyledDeleteButton>}
          </StyledFormInputs>

          <StyledButtons>
            <CancelButton onClick={history.goBack}>Cancel</CancelButton>
            <SaveButton>Save</SaveButton>
          </StyledButtons>
        </>
      )}
    </StyledForm>
  );
};

export default DegreeDetail;
