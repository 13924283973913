import React from 'react';
import styled from 'styled-components';

import { PlaceholderUniversityLogo } from 'theme/assets/';
import { AwardsTypes, ACADEMIC_HONORS_OPTIONS } from 'data/awards';
import { ConcentrationTypes } from 'data/degree';
import { convertMonthToString } from 'utils/dateUtils';
import { findConcentrationFromDegree, convertConcentrationsToString } from 'utils/degreeUtils';
import { palette } from 'theme';
import { DEFAULT_GPA_OUT_OF } from 'components/GPAOutOfPicker';
import { EditButton } from './library';
import { degreeEditPath } from '../StudentViewRoutes';

import { GetMyProfile_grad_profile_degrees } from 'generated/GetMyProfile';
import { StyledCardTextSmall, StyledCardTextMedium, StyledCardTextLarge, StyledCard } from 'components';

export interface UniversityProps {
  studentDegree: GetMyProfile_grad_profile_degrees;
}

const UniversityListCard: React.SFC<UniversityProps> = ({ studentDegree }) => {
  const { gpa_not_applicable } = studentDegree || {};
  const { location_name, logo_url } = studentDegree.university || {};
  const picture = logo_url || PlaceholderUniversityLogo;
  const name =
    location_name !== undefined
      ? location_name
      : studentDegree && studentDegree.fill_in_university && studentDegree.fill_in_university.institution_name;
  const degree = studentDegree.degree;

  const universityDegreeMajorText = `${degree} in ${findConcentrationFromDegree(
    studentDegree,
    ConcentrationTypes.MAJOR,
  )} `;
  const universityDegreeMinorText = `${convertConcentrationsToString(studentDegree, ConcentrationTypes.MINOR)} `;

  const universityDegreeDate = `${convertMonthToString(studentDegree.complete_month_numeric)}
  ${studentDegree.complete_year}`;

  const gpaNumeratorText = studentDegree.gpa ? studentDegree.gpa.toFixed(2) : '0.00';
  const gpaDenominatorText = `/${(studentDegree.gpa_outof ? studentDegree.gpa_outof : DEFAULT_GPA_OUT_OF).toFixed(2)}`;

  const academicHonors = studentDegree.awards.filter(
    (award) =>
      award.award_category === AwardsTypes.ACADEMIC_HONOR &&
      ACADEMIC_HONORS_OPTIONS.some((option) => option.value === award.award_name),
  );
  const awardNameText = academicHonors.length ? academicHonors[0].award_name : null;

  return (
    <StyledCard>
      <StyledImg src={picture} />
      <StyledUniversityDetails>
        <StyledCardTextSmall>{universityDegreeDate}</StyledCardTextSmall>
        <StyledCardTextLarge>{name}</StyledCardTextLarge>
        <StyledCardTextMedium>{universityDegreeMajorText}</StyledCardTextMedium>
        <StyledCardTextMedium>{universityDegreeMinorText}</StyledCardTextMedium>

        <StyledGPA>
          <StyledGPAText>GPA</StyledGPAText>
          {!!gpa_not_applicable ? (
            <GpaNumerator>N/A</GpaNumerator>
          ) : (
            <>
              <GpaNumerator>{gpaNumeratorText}</GpaNumerator>
              <GpaDenominator>{gpaDenominatorText}</GpaDenominator>
            </>
          )}
          <AwardName>{awardNameText}</AwardName>
        </StyledGPA>
      </StyledUniversityDetails>

      <EditButton to={degreeEditPath(studentDegree.degree_id)}></EditButton>
    </StyledCard>
  );
};

const StyledImg = styled.img`
  margin: 1.6rem 1rem auto 1rem;
  width: 7rem;
`;

const StyledUniversityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledGPA = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledGPAText = styled(StyledCardTextLarge)`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${palette.black};
`;

const GpaDenominator = styled.div`
  font-size: 1.2rem;
`;

const GpaNumerator = styled.div`
  padding-left: 0.5rem;

  font-size: 2rem;
  font-weight: 700;
`;

const AwardName = styled.div`
  margin-left: 1rem;

  font-size: 1.2rem;
  text-align: right;
  color: ${palette.black};
`;

export default UniversityListCard;
