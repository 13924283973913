import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

import { GetMyProfile, GetMyProfileVariables } from 'generated/GetMyProfile';

import { HorizontalSeparator, PrimaryButton } from 'components';
import SpinnerPage from 'components/SpinnerPage';
import CardInfo from './CardInfo';
import TestScoreList from './TestScoreList/TestScoreList';
import WorkExperienceList from './WorkExperienceList';
import Questions from 'components/Questions';
import UniversityListController from './UniversityListController';
import { GET_MY_PROFILE } from 'gql';

import MainViewLayout from '../../../components/common/MainViewLayout';

const MyCard: React.FC = () => {
  const { user, logout } = useAuth0();

  const { loading, error, data } = useQuery<GetMyProfile, GetMyProfileVariables>(GET_MY_PROFILE, {
    variables: { myUserId: user.sub },
  });

  if (loading) return <SpinnerPage />;
  if (error || !data) return <div>Error</div>;

  const studentProfile = data.grad_profile[0];

  return (
    <MainViewLayout title="My Card">
      <CardContainer>
        <CardInfo user={user} studentProfile={studentProfile} />

        <HorizontalSeparator />

        <UniversityListController />

        <HorizontalSeparator />

        <TestScoreList />

        <HorizontalSeparator />

        <WorkExperienceList workExperiences={studentProfile.work_experiences} />

        <HorizontalSeparator />

        <LogoutButton onClick={logout}>Log out</LogoutButton>

        <Questions />
      </CardContainer>
    </MainViewLayout>
  );
};

const CardContainer = styled.div`
  flex-grow: 1;

  overflow-y: scroll;
`;
export const LogoutButton = styled(PrimaryButton)`
  display: block;

  width: 70%;
  max-width: 36rem;
  margin: 6rem auto 0;
`;

export default MyCard;
