import gql from 'graphql-tag';
import { TestScorePart } from './fragments.gql';

const query = gql`
  query GetMyTestScores($myUserId: String!) {
    grad_test_score(where: { user_id: { _eq: $myUserId } }) {
      ...TestScorePart
    }
  }
  ${TestScorePart}
`;

export default query;
