import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CountryPicker from 'components/CountryPicker';
import { FormValidationMessage, SaveButton, CancelButton, RadioButtonWithLabel, Spinner } from 'components';
import palette from 'theme/palette';
import { StyledForm, StyledFormInputs, StyledButtons } from './library';

import { GET_STUDENT_PROFILE, UPDATE_STUDENT_LEGAL } from 'gql';
import { UpdateStudentLegal, UpdateStudentLegalVariables } from 'generated/UpdateStudentLegal';
import { GetStudentProfile, GetStudentProfileVariables } from 'generated/GetStudentProfile';

const StyledFieldRow = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${palette.darkGrey};

  &:nth-child(1n) {
    margin-bottom: 4.4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > :nth-child(1n) {
    margin-bottom: 1rem;
  }

  > :last-child {
    margin-bottom: 0rem;
  }
`;

interface ProfileLegalFormVariables {
  authorizedToWork: string;
  needSponsor: string;
  legalResidenceCountry: { label: string; value: string };
}

const DEFAULT_VALUES = {
  authorized_to_work: false,
  need_sponsor: false,
  minority_search: false,
  legal_residence_country: null,
};

const ProfileLegalTab: React.SFC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { handleSubmit, register, control, errors } = useForm<ProfileLegalFormVariables>();

  const [updateStudentLegal, { loading: updateLoading }] = useMutation<UpdateStudentLegal, UpdateStudentLegalVariables>(
    UPDATE_STUDENT_LEGAL,
    {
      update: (cache, { data: updateResultData }) => {
        const cachedGradProfile = cache.readQuery<GetStudentProfile, GetStudentProfileVariables>({
          query: GET_STUDENT_PROFILE,
          variables: { myUserId: user.sub },
        });

        if (!updateResultData || !updateResultData.update_grad_profile) {
          return;
        }

        if (!cachedGradProfile || !cachedGradProfile.grad_profile) {
          return;
        }

        const oldGradProfile = cachedGradProfile.grad_profile[0];
        const newGradProfile = {
          ...oldGradProfile,
          ...updateResultData.update_grad_profile.returning[0],
        };

        cache.writeQuery({
          query: GET_STUDENT_PROFILE,
          variables: { myUserId: user.sub },

          data: {
            grad_profile: [newGradProfile],
          },
        });
      },
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const { loading: getProfileLoading, data: getProfileData } = useQuery<GetStudentProfile, GetStudentProfileVariables>(
    GET_STUDENT_PROFILE,
    {
      variables: { myUserId: user.sub },
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  if (getProfileLoading) return <Spinner />;

  const myInfo = getProfileData && getProfileData.grad_profile[0] ? getProfileData.grad_profile[0] : DEFAULT_VALUES;

  const onSubmit = async (data: ProfileLegalFormVariables) => {
    const { authorizedToWork, needSponsor, legalResidenceCountry } = data;

    const variables = {
      userId: user.sub,
      authorizedToWork: authorizedToWork === 'yes',
      needSponsor: needSponsor === 'yes',
      legalResidenceCountryId: legalResidenceCountry.value,
    };

    await updateStudentLegal({ variables });

    history.goBack();
  };

  if (updateLoading) return <Spinner />;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledFieldRow>
          <div>What is your Country of Legal Residence?</div>
          <CountryPicker
            name="legalResidenceCountry"
            defaultCountryId={myInfo.legal_residence_country && myInfo.legal_residence_country.country_selection_id}
            defaultCountryName={myInfo.legal_residence_country && myInfo.legal_residence_country.country}
            required={true}
            control={control}
          />
          {errors.legalResidenceCountry && <FormValidationMessage message="Country of Legal Residence is required." />}
        </StyledFieldRow>

        <StyledFieldRow>
          <div>Are you authorized to work lawfully in the United States?</div>
          <RadioButtonWithLabel
            label="Yes"
            name="authorizedToWork"
            value="yes"
            defaultChecked={!!myInfo.authorized_to_work}
            inputRef={register({})}
          />
          <RadioButtonWithLabel
            label="No"
            name="authorizedToWork"
            value="no"
            defaultChecked={!myInfo.authorized_to_work}
            inputRef={register({})}
          />
        </StyledFieldRow>

        <StyledFieldRow>
          <div>
            Will you require sponsorship for a work visa in order to work lawfully in the United States for the
            companies you are applying to?
          </div>
          <RadioButtonWithLabel
            label="Yes"
            name="needSponsor"
            value="yes"
            defaultChecked={!!myInfo.need_sponsor}
            inputRef={register({})}
          />
          <RadioButtonWithLabel
            label="No"
            name="needSponsor"
            value="no"
            defaultChecked={!myInfo.need_sponsor}
            inputRef={register({})}
          />
        </StyledFieldRow>
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={history.goBack}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfileLegalTab;
