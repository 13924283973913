import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { FormValidationMessage, Spinner } from 'components';
import FirmInterestsPicker from 'components/FirmInterestsPicker';
import JobTypeInterestPicker from 'components/JobTypeInterestPicker';
import { OptionIdStrict } from 'components/Option';
import Disclaimer from 'components/common/Disclaimer';
import { FORM_MESSAGES } from 'utils/constants';

import {
  DELETE_INTERESTS,
  GET_STUDENT_JOB_INTERESTS,
  INSERT_FIRM_INTERESTS,
  INSERT_JOB_INTERESTS,
  INSERT_REGION_INTERESTS,
  INSERT_ROLE_INTERESTS,
} from 'gql';
import {
  WizardGetStudentJobInterests,
  WizardGetStudentJobInterestsVariables,
} from 'generated/WizardGetStudentJobInterests';
import { WizardInsertFirmInterests, WizardInsertFirmInterestsVariables } from 'generated/WizardInsertFirmInterests';
import { WizardInsertJobInterests, WizardInsertJobInterestsVariables } from 'generated/WizardInsertJobInterests';
import {
  WizardInsertRegionInterests,
  WizardInsertRegionInterestsVariables,
} from 'generated/WizardInsertRegionInterests';
import { WizardDeleteInterests, WizardDeleteInterestsVariables } from 'generated/WizardDeleteInterests';
import {
  WizardFormButtons,
  WizardForm,
  WizardContainer,
  SUBMIT_GO_TO,
  CANCEL_GO_TO,
  WizardFormInputs,
  WizardStepTitle,
  WizardInputLabel,
} from '../components';
import { WizardProps } from '../components/interface';
import { EmployerPreferenceSelector } from 'components/EmployerPreferenceSelector';
import { InsertEmployerPreference, InsertEmployerPreferenceVariables } from 'generated/InsertEmployerPreference';
import { INSERT_EMPLOYER_PREFERENCE } from 'gql';
import RegionsPicker from 'components/RegionsPicker';
import RoleInterestPicker from 'components/JobRoleInterestPicker';
import { InsertRoleInterests, InsertRoleInterestsVariables } from 'generated/InsertRoleInterests';
import { WizardRequiredInputLabel } from '../components/library';

const DEFAULT_INTERESTS = [
  { interest_job_types: [], interest_firms: [], interest_regions: [], employer_preferences: [], interest_roles: [] },
];

interface InterestStepInterface {
  desiredFirms: { current: OptionIdStrict[] };
  desiredJobType: OptionIdStrict;
  interestRegions: OptionIdStrict[];
  employerPreferences: { newOptions: OptionIdStrict[]; deletedOptionsIds: string[] };
  interestRoles: { current: OptionIdStrict[] };
}

const InterestStep: React.FC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
}) => {
  const history = useHistory();
  const { user } = useAuth0();
  const { handleSubmit, control, setValue, register, errors } = useForm<InterestStepInterface>();

  const { loading: loadingInterests, error: getProfileError, data: interestsData = { grad_profile: [] } } = useQuery<
    WizardGetStudentJobInterests,
    WizardGetStudentJobInterestsVariables
  >(GET_STUDENT_JOB_INTERESTS, {
    variables: { myUserId: user.sub },
    fetchPolicy: 'no-cache',
  });

  const [deleteInterests] = useMutation<WizardDeleteInterests, WizardDeleteInterestsVariables>(DELETE_INTERESTS);
  const [insertFirmInterests] = useMutation<WizardInsertFirmInterests, WizardInsertFirmInterestsVariables>(
    INSERT_FIRM_INTERESTS,
  );
  const [insertJobInterests] = useMutation<WizardInsertJobInterests, WizardInsertJobInterestsVariables>(
    INSERT_JOB_INTERESTS,
  );
  const [insertRegionInterests] = useMutation<WizardInsertRegionInterests, WizardInsertRegionInterestsVariables>(
    INSERT_REGION_INTERESTS,
  );

  const [insertEmployerPreference] = useMutation<InsertEmployerPreference, InsertEmployerPreferenceVariables>(
    INSERT_EMPLOYER_PREFERENCE,
  );

  const [insertRoleInterests] = useMutation<InsertRoleInterests, InsertRoleInterestsVariables>(INSERT_ROLE_INTERESTS);

  const [
    {
      interest_job_types = [],
      interest_firms = [],
      interest_regions = [],
      employer_preferences = [],
      interest_roles = [],
    },
  ] = interestsData.grad_profile.length ? interestsData.grad_profile : DEFAULT_INTERESTS;

  const currentEmployerPreferences = employer_preferences.map(({ preference, employer_preference_id }) => ({
    value: preference,
    label: preference,
    id: employer_preference_id,
  }));

  const currentJobType =
    interest_job_types.map(
      ({ job_type, interest_job_type_id }: { job_type: string; interest_job_type_id: string }) => ({
        value: job_type,
        label: job_type,
        id: interest_job_type_id,
      }),
    )[0] || null;

  const currentFirmInterests = interest_firms.map(
    ({ firm_type, interest_firm_id }: { firm_type: string; interest_firm_id: string }) => ({
      value: firm_type,
      label: firm_type,
      id: interest_firm_id,
    }),
  );

  const currentRegions = interest_regions.map(
    ({ region, interest_region_id }: { region: string; interest_region_id: string }) => ({
      value: region,
      label: region,
      id: interest_region_id,
    }),
  );

  const currentRoles = interest_roles.map(({ role, interest_role_id }: { role: string; interest_role_id: string }) => ({
    value: role,
    label: role,
    id: interest_role_id,
  }));

  const getEraseOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !initial
      ? []
      : initial.filter(({ value: i }) => (!selected ? initial : !selected.some(({ value }) => value === i)));
  const getAddOptions = (selected: OptionIdStrict[] = [], initial: OptionIdStrict[] = []) =>
    !selected
      ? []
      : selected.filter(({ value: i }) => (!initial ? selected : !initial.some(({ value }) => value === i)));

  const onSubmit = async ({
    desiredFirms: { current: desiredFirms },
    desiredJobType,
    interestRegions,
    interestRoles: { current: interestRoles },
    employerPreferences: { deletedOptionsIds: erasePreferences, newOptions: newEmployerPreferences },
  }: InterestStepInterface) => {
    const addEmployerPreferences = newEmployerPreferences.map(({ value }) => ({
      preference: value,
      user_id: user.sub,
    }));

    const eraseFirms = getEraseOptions(desiredFirms, currentFirmInterests)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addFirms = getAddOptions(desiredFirms, currentFirmInterests).map<{ firm_type: string; user_id: string }>(
      ({ value }) => ({
        firm_type: value,
        user_id: user.sub,
      }),
    );

    const eraseJobTypes = getEraseOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    )
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addJobTypes = getAddOptions(
      desiredJobType ? [desiredJobType] : [],
      currentJobType ? [currentJobType] : [],
    ).map<{ job_type: string; user_id: string }>(({ value }) => ({
      job_type: value,
      user_id: user.sub,
    }));

    const eraseRegions = getEraseOptions(interestRegions, currentRegions)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRegions = getAddOptions(interestRegions, currentRegions).map<{ region: string; user_id: string }>(
      ({ value }) => ({
        region: value,
        user_id: user.sub,
      }),
    );

    const eraseRoles = getEraseOptions(interestRoles, currentRoles)
      .map(({ id }) => id)
      .filter((id) => id !== undefined) as string[];
    const addRoles = getAddOptions(interestRoles, currentRoles).map(({ value }) => ({
      role: value,
      user_id: user.sub,
    }));

    if (
      eraseJobTypes.length ||
      eraseFirms.length ||
      eraseRegions.length ||
      erasePreferences.length ||
      eraseRoles.length
    ) {
      deleteInterests({
        variables: {
          jobs: eraseJobTypes,
          firms: eraseFirms,
          regions: eraseRegions,
          employerPreferences: erasePreferences,
          roles: eraseRoles,
        },
      });
    }
    if (addFirms.length) {
      await insertFirmInterests({ variables: { firms: addFirms } });
    }
    if (addJobTypes.length) {
      await insertJobInterests({ variables: { jobs: addJobTypes } });
    }
    if (addRegions.length) {
      await insertRegionInterests({ variables: { regions: addRegions } });
    }
    if (addRoles.length) await insertRoleInterests({ variables: { roles: addRoles } });

    if (addEmployerPreferences.length) {
      await insertEmployerPreference({ variables: { preferences: addEmployerPreferences } });
    }

    history.push(nextGoTo);
  };

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <WizardForm onSubmit={handleSubmit(onSubmit)}>
        {loadingInterests ? (
          <Spinner />
        ) : getProfileError ? (
          <div>Error</div>
        ) : (
          <>
            <WizardFormInputs>
              <WizardStepTitle>Job Interests</WizardStepTitle>
              <WizardRequiredInputLabel>Firms of Interest</WizardRequiredInputLabel>
              <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
              <FirmInterestsPicker
                name="desiredFirms"
                defaultValue={currentFirmInterests}
                required
                control={control}
                register={register}
                setValue={setValue}
              />
              {errors.desiredFirms && <FormValidationMessage message="Select a Firm of Interest" />}

              <WizardRequiredInputLabel>Job Type</WizardRequiredInputLabel>
              <JobTypeInterestPicker
                name="desiredJobType"
                defaultValue={currentJobType}
                required
                clearable
                control={control}
              />
              {errors.desiredJobType && <FormValidationMessage message="Select a Job Type" />}

              <WizardInputLabel>Desired Locations</WizardInputLabel>
              <RegionsPicker required={true} name="interestRegions" control={control} defaultValue={currentRegions} />
              {errors.interestRegions && <FormValidationMessage message="Select a desired location to work on" />}

              <WizardRequiredInputLabel>What I look for in an employer?</WizardRequiredInputLabel>
              <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
              <EmployerPreferenceSelector
                name="employerPreferences"
                register={register}
                setValue={setValue}
                values={currentEmployerPreferences}
              />
              {errors.employerPreferences && (
                <FormValidationMessage message="Select what you look for in an employer" />
              )}

              <WizardInputLabel>Roles of interest</WizardInputLabel>
              <Disclaimer content={FORM_MESSAGES.DISCLAIMER.CAPPED_MULTI_SELECT_TO_THREE} />
              <RoleInterestPicker
                name="interestRoles"
                defaultValue={currentRoles}
                control={control}
                register={register}
                setValue={setValue}
              />
            </WizardFormInputs>

            <WizardFormButtons step={step} isLastStep={isLastStep} cancelSkipGoTo={cancelSkipGoTo} />
          </>
        )}
      </WizardForm>
    </WizardContainer>
  );
};

export default InterestStep;
