import React, { useEffect } from 'react';
import EventDetail from './EventDetail';
import { useParams } from 'react-router-dom';
import {
  useDeleteRegistrationMutation,
  useFetchEvent,
  useGetEventsRegistrationsByUserAndEventIdLazyQuery,
  useInsertRegistrationMutation,
} from './Event.hooks';
import { Spinner } from '../../../components';
import { asEvent } from '../../../transformers/event';
import { useGetStudentGPAQuery, useUserHasResume } from '../../../hooks';
import moment from 'moment';
import { useAuth0 } from '../../../react-auth0-spa';

const BUTTON_EVENT_FULL_MESSAGE = 'This event is full';
const BUTTON_NEED_RESUME = 'To subscribe first upload a resume';

const EventController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth0();
  const userHasResume = useUserHasResume();
  const { data: dataEvent, loading: loadingEvent, error, refetch: refetchEvent } = useFetchEvent(id);
  const event = dataEvent?.recruit_event.map(asEvent)[0];
  const [subscribe, { loading: loadingSubscribe }] = useInsertRegistrationMutation(id, user.sub);
  const [unsubscribe, { loading: loadingUnsubscribe }] = useDeleteRegistrationMutation(id, user.sub);
  const [gpa, loadingGPA] = useGetStudentGPAQuery(user.sub);
  const [
    checkIfIsubcribed,
    { data: eventsRegistry, loading: loadingCheckingRegistration },
  ] = useGetEventsRegistrationsByUserAndEventIdLazyQuery(id, user.sub);
  useEffect(() => {
    checkIfIsubcribed();
  }, [id, checkIfIsubcribed]);

  const getSubscribed = async () => {
    const { data: dataEvent2 } = await refetchEvent({
      eventId: id,
    });

    const event = dataEvent2?.recruit_event.map(asEvent)[0];
    if (event && (event.numberParticipants < event.capacity || event.capacity === 0)) {
      return subscribe();
    }
  };

  const isLoading = loadingEvent || loadingUnsubscribe || loadingSubscribe || loadingCheckingRegistration || loadingGPA;

  if (isLoading || !event) return <Spinner />;
  if (!id || !dataEvent || error) return <div>Error</div>;
  const {
    title,
    eventType,
    description,
    endTimestamp,
    startTimestamp,
    capacity,
    address,
    event_url,
    companyName,
    numberParticipants,
  } = event;

  const formattedStartTimestamp = moment(startTimestamp).format('MMMM, DD YYYY  hh:mm A');
  const startFromNow = moment(startTimestamp).fromNow();
  const formattedEndTimestamp = moment(endTimestamp).format('MMMM, DD YYYY  hh:mm A');
  const isSubscribed = Boolean(eventsRegistry?.grad_event_registration?.length);
  const isFull = capacity === 0 ? false : numberParticipants >= capacity;
  return (
    <EventDetail
      companyName={companyName}
      isButtonOn={isSubscribed}
      onButtonOff={unsubscribe}
      onButtonOn={getSubscribed}
      isButtonDisable={!(userHasResume && !isLoading && ((isFull && isSubscribed) || !isFull))}
      isButtonVisible={gpa >= 3}
      buttonTextOn={'Unsubscribe'}
      buttonTextOff={'Subscribe'}
      buttonDisableText={isFull ? BUTTON_EVENT_FULL_MESSAGE : BUTTON_NEED_RESUME}
      event_url={event_url || ''}
      address={address || ''}
      formattedStartTimestamp={formattedStartTimestamp}
      fromNowStartTimeStamp={startFromNow}
      formattedEndTimestamp={formattedEndTimestamp}
      capacity={capacity}
      title={title}
      tags={[{ text: eventType }]}
      description={description || ''}
    />
  );
};

export default EventController;
