import React from 'react';

import JobListController from './JobList';

import MainViewLayout from '../../../components/common/MainViewLayout';

const JobListView: React.FC = () => {
  return (
    <MainViewLayout title="Job Board">
      <JobListController />
    </MainViewLayout>
  );
};

export default JobListView;
