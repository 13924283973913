import React from 'react';
import styled from 'styled-components';

import CollapsibleContainer from './CollapseDiv';
import UniversityListCard from './UniversityListCard';
import { NewButton } from './library';
import { GetMyProfile_grad_profile_degrees } from 'generated/GetMyProfile';
import { StudentViewPaths } from '../StudentViewRoutes';

export interface UniversityProps {
  degrees: GetMyProfile_grad_profile_degrees[];
}

const UniversityList: React.SFC<UniversityProps> = ({ degrees }) => {
  return (
    <StyledUniversityDetailsList>
      <StyledUniversityTitle>
        <StyledTitle>Education</StyledTitle>
        <NewButton to={StudentViewPaths.degreeNew}></NewButton>
      </StyledUniversityTitle>
      <CollapsibleContainer>
        {degrees.map((degree) => (
          <UniversityListCard key={degree.degree_id} studentDegree={degree} />
        ))}
      </CollapsibleContainer>
    </StyledUniversityDetailsList>
  );
};

const StyledUniversityDetailsList = styled.div`
  margin: 1.6rem 1rem;
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;

  line-height: 2.1rem;
`;

const StyledUniversityTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default UniversityList;
