import React from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import { GET_SELECTOR_FUN_FACTS } from 'gql';
import { GetFunFactsSelections } from 'generated/GetFunFactsSelections';
import SelectorMultiCreateV2 from './SelectorMultiCreateV2';
import { OptionId } from './Option';

interface WantToKnowSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  values: OptionId[];
  name: string;
}

export const WantToKnowSelector: React.FC<WantToKnowSelectorProps> = ({ values, name, register, setValue }) => {
  const { loading, data } = useQuery<GetFunFactsSelections>(GET_SELECTOR_FUN_FACTS, {
    errorPolicy: 'all',
    onError: console.warn, // eslint-disable-line no-console
  });
  const selectorOptions = data?.grad_fun_fact_selection.map(({ description: d }) => ({ value: d, label: d }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={values}
      name={name}
      placeholder={'Select or type what you want employeers to know'}
      required={true}
      register={register}
      setValue={setValue}
      maxSelection={Infinity}
    />
  );
};
