/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * unique or primary key constraints on table "grad.test_score_detail"
 */
export enum grad_test_score_detail_constraint {
  test_score_detail_pkey = "test_score_detail_pkey",
  test_score_detail_test_score_detail_id_key = "test_score_detail_test_score_detail_id_key",
  test_score_detail_user_id_test_score_id_detail_key = "test_score_detail_user_id_test_score_id_detail_key",
}

/**
 * update columns of table "grad.test_score_detail"
 */
export enum grad_test_score_detail_update_column {
  detail = "detail",
  outof = "outof",
  score = "score",
  test = "test",
  test_score_detail_id = "test_score_detail_id",
  test_score_id = "test_score_id",
  user_id = "user_id",
}

/**
 * expression to compare columns of type Boolean. All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * expression to compare columns of type Int. All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * expression to compare columns of type String. All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _nlike?: string | null;
  _nsimilar?: string | null;
  _similar?: string | null;
}

/**
 * expression to compare columns of type date. All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * input type for inserting data into table "grad.achievement"
 */
export interface grad_achievement_insert_input {
  achievement_id?: string | null;
  description?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.award"
 */
export interface grad_award_insert_input {
  award_category?: string | null;
  award_id?: string | null;
  award_name?: string | null;
  degree_id?: string | null;
  user_id?: string | null;
  work_experience_id?: string | null;
  year_received?: any | null;
}

/**
 * Boolean expression to filter rows from the table "grad.bookmark_job". All fields are combined with a logical 'AND'.
 */
export interface grad_bookmark_job_bool_exp {
  _and?: (grad_bookmark_job_bool_exp | null)[] | null;
  _not?: grad_bookmark_job_bool_exp | null;
  _or?: (grad_bookmark_job_bool_exp | null)[] | null;
  bookmark_job_id?: String_comparison_exp | null;
  job_posting_id?: String_comparison_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.computer_language"
 */
export interface grad_computer_language_insert_input {
  computer_language_id?: string | null;
  language?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.concentration"
 */
export interface grad_concentration_insert_input {
  concentration_id?: string | null;
  degree_id?: string | null;
  type?: string | null;
  user_id?: string | null;
  val?: string | null;
}

/**
 * input type for inserting data into table "grad.employer_preference"
 */
export interface grad_employer_preference_insert_input {
  employer_preference_id?: string | null;
  preference?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.fun_fact"
 */
export interface grad_fun_fact_insert_input {
  description?: string | null;
  fun_fact_id?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.interest_firm"
 */
export interface grad_interest_firm_insert_input {
  firm_type?: string | null;
  interest_firm_id?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.interest_job_type"
 */
export interface grad_interest_job_type_insert_input {
  interest_job_type_id?: string | null;
  job_type?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.interest_region"
 */
export interface grad_interest_region_insert_input {
  interest_region_id?: string | null;
  region?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.interest_role"
 */
export interface grad_interest_role_insert_input {
  interest_role_id?: string | null;
  role?: string | null;
  user_id?: string | null;
}

/**
 * Boolean expression to filter rows from the table "grad.raise_hand". All fields are combined with a logical 'AND'.
 */
export interface grad_raise_hand_bool_exp {
  _and?: (grad_raise_hand_bool_exp | null)[] | null;
  _not?: grad_raise_hand_bool_exp | null;
  _or?: (grad_raise_hand_bool_exp | null)[] | null;
  job_posting?: recruit_job_posting_bool_exp | null;
  job_posting_id?: String_comparison_exp | null;
  raise__hand_id?: String_comparison_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.spoken_language"
 */
export interface grad_spoken_language_insert_input {
  language?: string | null;
  spoken_language_id?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting array relation for remote table "grad.test_score_detail"
 */
export interface grad_test_score_detail_arr_rel_insert_input {
  data: grad_test_score_detail_insert_input[];
  on_conflict?: grad_test_score_detail_on_conflict | null;
}

/**
 * Boolean expression to filter rows from the table "grad.test_score_detail". All fields are combined with a logical 'AND'.
 */
export interface grad_test_score_detail_bool_exp {
  _and?: (grad_test_score_detail_bool_exp | null)[] | null;
  _not?: grad_test_score_detail_bool_exp | null;
  _or?: (grad_test_score_detail_bool_exp | null)[] | null;
  detail?: String_comparison_exp | null;
  outof?: Int_comparison_exp | null;
  score?: Int_comparison_exp | null;
  test?: String_comparison_exp | null;
  test_score_detail_id?: String_comparison_exp | null;
  test_score_id?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * input type for inserting data into table "grad.test_score_detail"
 */
export interface grad_test_score_detail_insert_input {
  detail?: string | null;
  outof?: number | null;
  score?: number | null;
  test?: string | null;
  test_score_detail_id?: string | null;
  test_score_id?: string | null;
  user_id?: string | null;
}

/**
 * on conflict condition type for table "grad.test_score_detail"
 */
export interface grad_test_score_detail_on_conflict {
  constraint: grad_test_score_detail_constraint;
  update_columns: grad_test_score_detail_update_column[];
  where?: grad_test_score_detail_bool_exp | null;
}

/**
 * input type for inserting data into table "grad.test_score"
 */
export interface grad_test_score_insert_input {
  date?: any | null;
  outof?: number | null;
  score?: number | null;
  test?: string | null;
  test_score_details?: grad_test_score_detail_arr_rel_insert_input | null;
  test_score_id?: string | null;
  user_id?: string | null;
}

/**
 * input type for inserting data into table "grad.volunteer"
 */
export interface grad_volunteer_insert_input {
  description?: string | null;
  user_id?: string | null;
  volunteer_id?: string | null;
}

/**
 * expression to compare columns of type numeric. All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_posting". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_posting_bool_exp {
  _and?: (recruit_job_posting_bool_exp | null)[] | null;
  _not?: recruit_job_posting_bool_exp | null;
  _or?: (recruit_job_posting_bool_exp | null)[] | null;
  account_id?: String_comparison_exp | null;
  active?: Boolean_comparison_exp | null;
  bookmark_jobs?: grad_bookmark_job_bool_exp | null;
  creation_date?: date_comparison_exp | null;
  creator_id?: String_comparison_exp | null;
  deadline?: date_comparison_exp | null;
  description?: String_comparison_exp | null;
  firm?: String_comparison_exp | null;
  job_posting_id?: String_comparison_exp | null;
  job_requirements?: recruit_job_requirement_bool_exp | null;
  job_type?: String_comparison_exp | null;
  modified_date?: date_comparison_exp | null;
  position_title?: String_comparison_exp | null;
  public?: Boolean_comparison_exp | null;
  publishing_date?: date_comparison_exp | null;
  raise_hands?: grad_raise_hand_bool_exp | null;
  region?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_requirement". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_requirement_bool_exp {
  _and?: (recruit_job_requirement_bool_exp | null)[] | null;
  _not?: recruit_job_requirement_bool_exp | null;
  _or?: (recruit_job_requirement_bool_exp | null)[] | null;
  boolean_value?: Boolean_comparison_exp | null;
  job_posting?: recruit_job_posting_bool_exp | null;
  job_posting_id?: String_comparison_exp | null;
  job_requirement_id?: String_comparison_exp | null;
  max_range_value?: numeric_comparison_exp | null;
  min_range_value?: numeric_comparison_exp | null;
  number_value?: numeric_comparison_exp | null;
  requirement_name?: String_comparison_exp | null;
  requirement_type?: String_comparison_exp | null;
  values?: recruit_job_requirement_value_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "recruit.job_requirement_value". All fields are combined with a logical 'AND'.
 */
export interface recruit_job_requirement_value_bool_exp {
  _and?: (recruit_job_requirement_value_bool_exp | null)[] | null;
  _not?: recruit_job_requirement_value_bool_exp | null;
  _or?: (recruit_job_requirement_value_bool_exp | null)[] | null;
  job_requirement?: recruit_job_requirement_bool_exp | null;
  job_requirement_id?: String_comparison_exp | null;
  job_requirement_value_id?: String_comparison_exp | null;
  label?: String_comparison_exp | null;
  value?: String_comparison_exp | null;
  value_id?: String_comparison_exp | null;
}

/**
 * expression to compare columns of type timestamptz. All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: any[] | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: any[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
