import gql from 'graphql-tag';

const query = gql`
  query GetSelectorMajor {
    grad_major_selection {
      major
      major_selection_id
    }
  }
`;

export default query;
