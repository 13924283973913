import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';

import { notify } from 'utils/notify';
import {
  WizardContainer,
  WizardStepTitle as Title,
  WizardFormButtons as NavegationButtons,
  WizardFormInputs as Container,
  WizardForm as Form,
  WizardCheckBoxRow as CheckboxContainer,
  WizardCheckBox as Checkbox,
  WizardInputLabel as Label,
} from '../components';
import { WizardProps } from '../components/interface';
import { HOME_URL } from '../components/constants';
import Spinner from 'components/Spinner';
import TechnicalLanguageSelector from 'components/TechnicalLanguageSelector';

import { Spacing, CheckboxLabel } from './ExtraStep.styled';
import { useExtraStepQueries, useExtraStepMutations } from './ExtraStep.hooks';

import { GetProfileAditionalInfo } from 'generated/GetProfileAditionalInfo';

import SelectorMultiCreateV2 from 'components/SelectorMultiCreateV2';
import { OptionId, createOptionId } from 'components/Option';
import LanguageSelector from 'components/LanguageSelector';

import { multiSelectorToRequestObject, languageToRequestObject } from './ExtraSteps.utils';

interface ExtraStepFormProps {
  studentAthlete: boolean;
  personalAchievements: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  volunteerActivities: { optionsValue: OptionId[]; deletedOptionsIds: string[] };
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
  languages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

const ExtraStep: React.FC<WizardProps> = ({ nextGoTo = HOME_URL, step, totalSteps, isLastStep }) => {
  const history = useHistory();
  const {
    user: { sub: userID },
  } = useAuth0();
  const { handleSubmit, register, reset, setValue, control } = useForm<ExtraStepFormProps>();

  const onCompleted = (_data: GetProfileAditionalInfo) => {
    const { student_athlete } = _data.grad_profile[0];
    reset({ studentAthlete: student_athlete });
  };

  const { loading, error, data } = useExtraStepQueries({ userID, onCompleted });
  const { updateAditionalInfo } = useExtraStepMutations();

  const redirectToNextPage = () => {
    history.push(nextGoTo);
  };

  const onSkip = () => {
    redirectToNextPage();
  };

  const onSubmit = (formData: ExtraStepFormProps) => {
    const newAchivements = formData.personalAchievements?.optionsValue || [];
    const insertNewAchivements = multiSelectorToRequestObject(newAchivements, userID, 'achievement_id');
    const deleteAchivements = formData.personalAchievements?.deletedOptionsIds || [];

    const newVolunteersActivities = formData.volunteerActivities?.optionsValue || [];
    const insertVolunteersActivities = multiSelectorToRequestObject(newVolunteersActivities, userID, 'volunteer_id');
    const deleteVolunteersActivities = formData.volunteerActivities?.deletedOptionsIds || [];

    const newComputerLanguages = formData.computerLanguages?.newOptions || [];
    const insertComputerLanguages = languageToRequestObject(newComputerLanguages, userID, 'computer_language_id');
    const deleteComputerLanguages = formData.computerLanguages?.deletedOptionsIds || [];

    const newSpokenLanguages = formData.languages?.newOptions || [];
    const insertSpokenLanguage = languageToRequestObject(newSpokenLanguages, userID, 'spoken_language_id');
    const deleteSpokenLanguages = formData.languages?.deletedOptionsIds || [];

    updateAditionalInfo({
      variables: {
        userID,
        studentAthlete: formData.studentAthlete,
        addAchievements: insertNewAchivements,
        deleteAchievementsIDs: deleteAchivements,
        addVolunteers: insertVolunteersActivities,
        deleteVolunteersIDs: deleteVolunteersActivities,
        addComputerLanguages: insertComputerLanguages,
        deleteComputerLanguagesIDs: deleteComputerLanguages,
        addSpokenLanguages: insertSpokenLanguage,
        deleteSpokenLanguagesIDs: deleteSpokenLanguages,
      },
    });

    redirectToNextPage();
  };

  if (error) {
    const queryToastId = 'GetProfileAditionalInfoToast';
    notify(queryToastId, error.message);
  }

  const achievementsOptions = (data?.grad_profile[0]?.achievements || []).map((achievement) =>
    createOptionId(achievement.description, achievement.achievement_id),
  );

  const volunteerOptions = (data?.grad_profile[0]?.volunteers || []).map((volunteer) =>
    createOptionId(volunteer.description, volunteer.volunteer_id),
  );

  const computerLanguagesOptions = (data?.grad_profile[0]?.computer_languages || []).map((programingLanguage) =>
    createOptionId(programingLanguage.language, programingLanguage.computer_language_id),
  );

  const languagesOptions = (data?.grad_profile[0]?.spoken_languages || []).map((language) =>
    createOptionId(language.language, language.spoken_language_id),
  );

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Title>Additional information</Title>
          <Spacing property="4" />
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CheckboxContainer>
                <CheckboxLabel>Student Athlete</CheckboxLabel>
                <Checkbox type="checkbox" name="studentAthlete" ref={register} />
              </CheckboxContainer>
              <Spacing property="2.5" />

              <Label>Personal Achievements/Awards/Scholarships</Label>
              <SelectorMultiCreateV2
                name="personalAchievements"
                defaultOptionsValue={achievementsOptions}
                placeholder="John Harvard Scholar, Scholarship Athlete - Tennis"
                showMenu={true}
                dropDownIndicator={false}
                setValue={setValue}
                register={register}
              />
              <Spacing />

              <Label>Volunteer/Clubs/Extracurricular Activities</Label>
              <SelectorMultiCreateV2
                name="volunteerActivities"
                defaultOptionsValue={volunteerOptions}
                placeholder="Big Brothers Big Sisters, Camillus House, Salvation Army"
                showMenu={true}
                dropDownIndicator={false}
                setValue={setValue}
                register={register}
              />
              <Spacing />

              <Label>Languages</Label>
              <LanguageSelector
                name={'languages'}
                defaultValue={languagesOptions}
                control={control}
                setValue={setValue}
                register={register}
              />
              <Spacing />

              <Label>Technical Languages</Label>
              <TechnicalLanguageSelector
                name="computerLanguages"
                defaultValue={computerLanguagesOptions}
                control={control}
                setValue={setValue}
                register={register}
              />
              <Spacing />
            </>
          )}
        </Container>
        <NavegationButtons step={step} isLastStep={isLastStep} onSkip={onSkip} />
      </Form>
    </WizardContainer>
  );
};

export default ExtraStep;
