import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateRelatedSkillsProfile(
    $technicalLanguagesForUpsert: [grad_computer_language_insert_input!]!
    $technicalLanguagesForDelete: [String!]!
  ) {
    insert_grad_computer_language(objects: $technicalLanguagesForUpsert) {
      returning {
        computer_language_id
        language
        user_id
      }
    }

    delete_grad_computer_language(where: { computer_language_id: { _in: $technicalLanguagesForDelete } }) {
      returning {
        computer_language_id
        language
        user_id
      }
    }
  }
`;

export default mutation;
