import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';

import { Spinner } from 'components';

import { GET_ALL_JOB_POSTINGS } from 'gql';
import { GetAllJobPostings, GetAllJobPostingsVariables } from 'generated/GetAllJobPostings';
import { asCompany } from 'transformers/company';
import JobDetail from './JobDetail';
import { useUserHasResume } from '../../../hooks';
import { useRaiseHandMutation, useLowerHandMutation, useCompanyQuery } from './Job.hooks';

const JobController: React.FC = () => {
  const { user } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const getPostingQuery = { userId: user.sub, dynamicQuery: { job_posting_id: { _eq: id } } };

  const { loading, error, data } = useQuery<GetAllJobPostings, GetAllJobPostingsVariables>(GET_ALL_JOB_POSTINGS, {
    skip: !id,
    variables: getPostingQuery,
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const [raiseHandMutation] = useRaiseHandMutation(user.sub, id, { job_posting_id: { _eq: id } });

  const [lowerHandMutation] = useLowerHandMutation(user.sub, id, { job_posting_id: { _eq: id } });

  const { data: dataCompany, loading: loadingCompany } = useCompanyQuery(
    !data?.recruit_job_posting[0].account_id,
    data?.recruit_job_posting[0].account_id || '',
  );

  const {
    job_posting_id = '',
    region = '',
    position_title = '',
    job_type = '',
    description = '',
    raise_hands = [],
    bookmark_jobs = [],
    publishing_date,
  } = (data && data.recruit_job_posting[0]) || {};
  const handRaised = raise_hands.length > 0;
  const company = dataCompany?.recruit_company.map(asCompany)?.[0];

  const userHasResume = useUserHasResume();

  if (loading || loadingCompany) return <Spinner />;
  if (!id || !data || error) return <div>Error</div>;

  return (
    <JobDetail
      company_name={company?.name || ''}
      company_id={company?.id || 0}
      tags={[{ text: job_type }]}
      title={position_title}
      logo={company?.logo || undefined}
      jobId={job_posting_id}
      bookmark_jobs={bookmark_jobs}
      description={description}
      isButtonOn={handRaised}
      buttonTextOff={'Raise Hand to Apply'}
      buttonDisableText="To raise a hand first upload a resume"
      buttonTextOn={'Stop raising hand'}
      isButtonVisible={true}
      isButtonDisable={!userHasResume}
      onButtonOff={lowerHandMutation}
      onButtonOn={raiseHandMutation}
      publishingDate={publishing_date}
      location={region}
    />
  );
};

export default JobController;
