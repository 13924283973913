import gql from 'graphql-tag';

const mutation = gql`
  mutation DeleteMyWork($workId: String!) {
    delete_grad_work_experience(where: { work_experience_id: { _eq: $workId } }) {
      returning {
        work_experience_id
      }
    }
  }
`;

export default mutation;
