import { useQuery } from 'react-apollo';
import { GetEventListActive, GetEventListActiveVariables } from '../../../../generated';
import {
  GetRegistrationsEventListActiveByUser,
  GetRegistrationsEventListActiveByUserVariables,
} from '../../../../generated';
import { GET_EVENT_LIST, GET_REGISTRATIONS_EVENT_LIST_ACTIVE_BY_USER } from './EventList.gql';

export const useFetchEvents = (today: Date) =>
  useQuery<GetEventListActive, GetEventListActiveVariables>(GET_EVENT_LIST, {
    errorPolicy: 'all',
    variables: { today },
    fetchPolicy: 'no-cache',
    onError: () => undefined,
  });

export const useFetchRegistrationsEventListByUser = (today: Date, userId: string) =>
  useQuery<GetRegistrationsEventListActiveByUser, GetRegistrationsEventListActiveByUserVariables>(
    GET_REGISTRATIONS_EVENT_LIST_ACTIVE_BY_USER,
    {
      errorPolicy: 'all',
      variables: { today, userId },
      fetchPolicy: 'no-cache',
      onError: () => undefined,
    },
  );
