import React, { useState, useEffect } from 'react';
import Select, { mergeStyles } from 'react-select';
import { FormContextValues } from 'react-hook-form';
import { selectorStyle } from 'theme';
import { OptionId } from './Option';

const reachMaxSelectedOptions = (currentSelected: number, maxSelected: number): boolean => {
  return currentSelected >= maxSelected;
};

export interface SelectorMultiControlledProps extends Pick<FormContextValues, 'control' | 'setValue' | 'register'> {
  options: OptionId[];
  defaultValue: OptionId[] | null;
  name: string;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  maxSelection?: number;
}

const SelectorMultiControlled: React.FC<SelectorMultiControlledProps> = ({
  options,
  defaultValue = [],
  name,
  required = false,
  isClearable = false,
  isLoading = false,
  placeholder = 'Select...',
  setValue,
  register,
  maxSelection = Infinity,
}) => {
  const initialOptionsValues = (defaultValue || []).map(({ value }) => value);
  const [inputValue, setInputValue] = useState<string>();

  const [currentValues, setCurrentValues] = useState(defaultValue || []);
  const [newOptions, setNewOptions] = useState<OptionId[]>([]);
  const [newOptionsValues, setNewOptionsValues] = useState<string[]>([]);
  const [deletedOptionsValues, setDeletedOptionsValues] = useState<string[]>([]);
  const [deletedOptionsIds, setDeletedOptionsIds] = useState<string[]>([]);

  useEffect(() => {
    register(name, {
      ...(required && {
        validate: {
          required: () => currentValues && currentValues.length > 0,
        },
      }),
    });
  }, [name, register, required, currentValues]);

  useEffect(() => {
    setValue(name, { current: currentValues, newOptions, deletedOptionsValues, deletedOptionsIds });
  }, [newOptions, setValue, name, currentValues, newOptionsValues, deletedOptionsValues, deletedOptionsIds, required]);
  const handleInputChange = (typedValue: string) => setInputValue(typedValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any, { action, removedValue: removedOption, option: addedOption }: any) => {
    switch (action) {
      case 'select-option':
        if (!initialOptionsValues.includes(addedOption.value)) {
          setNewOptionsValues([...newOptionsValues, addedOption.value]);
          setNewOptions([...newOptions, addedOption]);
        } else {
          setDeletedOptionsValues(deletedOptionsValues.filter((deletedValue) => deletedValue !== addedOption.value));
          setDeletedOptionsIds(deletedOptionsIds.filter((deletedId) => deletedId !== addedOption.id));
        }
        setCurrentValues([...currentValues, addedOption]);
        break;
      case 'remove-value':
        if (initialOptionsValues.includes(removedOption.value)) {
          setDeletedOptionsValues([...deletedOptionsValues, removedOption.value]);
          setDeletedOptionsIds([...deletedOptionsIds, removedOption.id]);
        } else {
          setNewOptionsValues(newOptionsValues.filter((newValue) => removedOption.value !== newValue));
          setNewOptions(newOptions.filter(({ value: newValue }) => removedOption.value !== newValue));
        }
        setCurrentValues(currentValues.filter(({ value: currentValue }) => currentValue !== removedOption.value));
        break;
    }
  };

  const selectorConfig = {
    isClearable,
    isMulti: true,
    isSearchable: true,
    ...(currentValues.length >= maxSelection && { components: { DropdownIndicator: null } }),
  };

  const selectorLimitStyle = mergeStyles(selectorStyle, {
    input: (base: object) => ({
      ...base,
      display: currentValues && currentValues.length < maxSelection ? 'block' : 'none',
    }),
  });

  return (
    <Select
      value={currentValues}
      options={!reachMaxSelectedOptions(currentValues?.length, maxSelection) ? options : []}
      isLoading={isLoading}
      maxMenuHeight={180}
      styles={selectorLimitStyle}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      {...selectorConfig}
    />
  );
};

export default SelectorMultiControlled;
