import React from 'react';
import DetailViewHeader, { DetailHeaderProps } from '../../../../../components/common/DetailViewHeader';
import JobBookmark, { JobBookmarkProps } from '../../JobBookmark';
import { PublishTime, Location, BookmarkIcon } from './JobDetailHeader.styled';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { companyPath } from '../../../StudentViewRoutes';

export interface JobDetailHeaderProps extends JobBookmarkProps, DetailHeaderProps {
  location: string;
  company_name: string;
  company_id: number;
  publishingDate?: Date;
}

const JobDetailHeader: React.FC<JobDetailHeaderProps> = ({
  publishingDate,
  location,
  jobId,
  bookmark_jobs,
  title,
  logo,
  tags,
  company_name,
  company_id,
}) => {
  return (
    <DetailViewHeader tags={tags} logo={logo} title={title}>
      {company_name && <Link to={companyPath(company_id)}>{company_name}</Link>}
      <Location>{location}</Location>
      <BookmarkIcon>
        <JobBookmark jobId={jobId} bookmark_jobs={bookmark_jobs} />
      </BookmarkIcon>
      {publishingDate && <PublishTime>Published {moment(publishingDate).fromNow()}</PublishTime>}
    </DetailViewHeader>
  );
};

export default JobDetailHeader;
