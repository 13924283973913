// colors
export default {
  black: '#000000',
  white: '#ffffff',

  blue: '#347eff',
  watermelon: '#fc405b',

  // red + variations
  red: '#ee151b',
  darkRed: '#b62d40',

  // gray + variations
  gray: '#848484',
  darkGrey: '#4a4a4a',
  brownGrey: '#9b9b9b',
  lightGray: '#dddddd',
  clearGray: '#f6f6f6',
};
