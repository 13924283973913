import { GetDegree_grad_degree_concentrations } from 'generated/GetDegree';
import { OptionId, createOptionId } from '../Option';

export const createConcentrationOptions = (
  concentrations: GetDegree_grad_degree_concentrations[],
  concentrationType: 'minor' | 'major',
): OptionId[] => {
  return concentrations
    .filter(({ type }) => type === concentrationType)
    .map(({ concentration_id, val }) => createOptionId(val, concentration_id));
};

export const prepareConcentrationForUpsert = (
  optionsMajors: OptionId[] | null,
  persistedDegreeId: string | null,
  myUserId: string,
  id: string,
  type: 'minor' | 'major',
) => {
  return (optionsMajors || []).map(({ value, id: concentration_id }) => ({
    degree_id: persistedDegreeId,
    user_id: myUserId,
    ...(id && { concentration_id }),
    type,
    val: value,
  }));
};
