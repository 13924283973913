import gql from 'graphql-tag';
import { UniversitySelectionParts } from 'gql/fragments.gql';

const query = gql`
  query DegreeQueryUniversities($searchTerm: String!) {
    grad_university_selection(where: { location_name: { _ilike: $searchTerm } }, limit: 10) {
      ...UniversitySelectionParts
    }
  }
  ${UniversitySelectionParts}
`;

export default query;
