import gql from 'graphql-tag';
import { DegreeParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation WizardUpdateMyDegree(
    $degreeId: String!
    $gradEndMonth: Int!
    $gradEndYear: numeric!
    $universitySelectionId: String
    $degreeName: String!
    $degreeLevel: String!
    $myGPA: numeric
    $gpaOutOf: numeric
    $completed: Boolean
    $gpaNotApplicable: Boolean!
  ) {
    update_grad_degree(
      where: { degree_id: { _eq: $degreeId } }
      _set: {
        degree_level: $degreeLevel
        complete_month_numeric: $gradEndMonth
        complete_year: $gradEndYear
        university_selection_id: $universitySelectionId
        degree: $degreeName
        gpa: $myGPA
        gpa_outof: $gpaOutOf
        completed: $completed
        gpa_not_applicable: $gpaNotApplicable
      }
    ) {
      returning {
        ...DegreeParts
      }
    }
  }
  ${DegreeParts}
`;

export default mutation;
