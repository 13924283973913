import { EventParts } from '../generated/EventParts';
import Event from '../types/Event';

const asEvent = (eventParts: EventParts): Event => {
  const {
    event_id,
    event_type,
    capacity,
    image,
    title,
    timezone,
    start_timestamp,
    end_timestamp,
    account: { company },
    end_datetime,
    address,
    description,
    event_url,
    start_datetime,
  } = eventParts;
  return {
    id: event_id,
    eventType: event_type,
    capacity: capacity,
    image: image,
    title: title,
    timezone: timezone,
    startDatetime: start_datetime,
    startTimestamp: start_timestamp,
    endDatetime: end_datetime,
    endTimestamp: end_timestamp,
    description: description ? description : '',
    event_url: event_url ? event_url : '',
    address: address ? address : '',
    companyName: company?.name || '',
    numberParticipants: eventParts.registration_count,
  };
};

export { asEvent };
