import React from 'react';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';

import { EGLogoGrayBackground as ProfilePicture, Camera } from 'theme/assets';
import { NavButtonLight } from 'components';
import { palette } from 'theme';

import { GetPhoto, GetPhotoVariables } from 'generated/GetPhoto';
import { GET_PHOTO } from 'gql';

import { StudentViewPaths } from '../StudentViewRoutes';

const ProfilePhoto: React.FC = () => {
  const { user } = useAuth0();

  const { data } = useQuery<GetPhoto, GetPhotoVariables>(GET_PHOTO, {
    variables: { userId: user.sub },
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const { grad_profile: [{ profile_image = null } = {}] = [] } = data || {};
  const picture = profile_image || ProfilePicture;
  return (
    <StyledProfilePhotoDetails>
      <RouterNavLink to={StudentViewPaths.profilePhoto}>
        <StyledEditUserImage>
          <StyledUserImage src={picture} />
          <StyledEditIcon src={Camera}></StyledEditIcon>
        </StyledEditUserImage>
      </RouterNavLink>
      <NavButtonLight text={'Manage Resume'} to={StudentViewPaths.profileCV} />
    </StyledProfilePhotoDetails>
  );
};

const StyledEditIcon = styled.img`
  position: absolute;

  width: 1.6rem;

  vertical-align: middle;

  fill: ${palette.darkGrey};
`;

const StyledEditUserImage = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledUserImage = styled.img`
  width: 6.4rem;
  height: 6.4rem;

  border-radius: 50%;
  border: 8px solid ${palette.lightGray};

  background: ${palette.watermelon};
  opacity: 0.5;
`;

const StyledProfilePhotoDetails = styled.div`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 2rem 1.6rem;
`;

export default ProfilePhoto;
