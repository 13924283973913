import React from 'react';
import GoBackHeader from 'components/GoBackHeader';
import ProfileJob from './ProfileJobTab';
import ProfilePersonal from './ProfilePersonalTab';
import ProfileLegalTab from './ProfileLegalTab';
import styled from 'styled-components';
import ProfilePhoto from './ProfilePhoto';
import { StyledLeftH1 } from 'components/library';
import Tabs from 'components/Tabs';
import ProfileSkillsTab from './ProfileSkillsTab';

const Profile: React.FC = () => {
  return (
    <Container>
      <GoBackHeader />
      <StyledLeftH1>Profile</StyledLeftH1>
      <ProfilePhoto />
      <Tabs
        tabs={[
          { component: ProfilePersonal, tabName: 'Personal Info' },
          { component: ProfileJob, tabName: 'Career Goals' },
          { component: ProfileSkillsTab, tabName: 'Skills' },
          { component: ProfileLegalTab, tabName: 'Legal' },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;
`;

export default Profile;
