export default [
  'Any',
  'US - East',
  'US - Northeast',
  'US - South',
  'US - Southwest',
  'US - West',
  'Canada',
  'South America',
  'Asia',
  'Middle East',
  'Other',
];
