import { CompanyParts } from '../../../gql/fragments.gql';
import gql from 'graphql-tag';

export const GET_COMPANY = gql`
  query GetCompanyByPk($companyId: Int!) {
    recruit_company_by_pk(company_id: $companyId) {
      ...CompanyParts
    }
  }
  ${CompanyParts}
`;
