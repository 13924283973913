import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import SpinnerPage from 'components/SpinnerPage';
import {
  ProfileStep,
  AcademicStep,
  PictureStep,
  LegalStep,
  TestStep,
  InterestStep,
  DoneStep,
  AlmostThereStep,
  ExtraStep,
  ResumeStep,
} from '.';

export interface StudentRoutesProps {
  match?: { path: string };
}

const WizardRoutes: React.FC<StudentRoutesProps> = ({ match }) => {
  if (!match) return <SpinnerPage />;

  const wizardSequence = [
    ProfileStep,
    AcademicStep,
    TestStep,
    LegalStep,
    InterestStep,
    AlmostThereStep,
    PictureStep,
    ResumeStep,
    ExtraStep,
    DoneStep,
  ];
  const wizardRoute = `${match.path}/wizard/step-`;
  const profilePath = `${match.path}/home`;

  const wizardPath = wizardSequence.map((Component, index, array) => {
    const isFirstStep = index === 0;
    const totalSteps = array.length - 1; // Do not count DoneStep as a separate step
    const step = index + 1;
    const isLastStep = index === array.length - 1;
    const doneWizard = array.length;

    const previousStep = `${wizardRoute}${index}`;
    const currentStep = `${wizardRoute}${index + 1}`;
    const nextStep = `${wizardRoute}${index + 2}`;
    const goToDone = `${wizardRoute}${doneWizard}`;

    const cancelSkipGoTo = isLastStep ? profilePath : nextStep;
    const nextGoTo = isLastStep ? profilePath : nextStep;
    const backGoTo = isFirstStep ? profilePath : previousStep;

    return {
      Component,
      path: currentStep,
      customProps: {
        wizardModeOn: true,
        cancelSkipGoTo,
        nextGoTo,
        isLastStep,
        backGoTo,
        step,
        totalSteps,
        goToDone,
      },
    };
  });

  return (
    <Switch>
      {wizardPath.map(({ Component, customProps, path }) => (
        <Route
          exact
          key={Component.name}
          path={path}
          render={(props) => <Component {...props} {...customProps}></Component>}
        />
      ))}
      <Redirect to={`${wizardRoute}1`}></Redirect>
    </Switch>
  );
};

export default WizardRoutes;
