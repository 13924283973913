import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';

import palette from 'theme/palette';
import { EGLogo as ProfilePicture, Trash } from 'theme/assets';
import { CancelButton, SaveButton, Spinner } from 'components';
import GoBackHeader from 'components/GoBackHeader';
import { StyledNoWrapContainer, StyledFormInputs, StyledButtons, StyledLeftH1 } from 'components/library';

import { GetPhoto, GetPhotoVariables } from 'generated/GetPhoto';
import { UploadPhoto, UploadPhotoVariables } from 'generated/UploadPhoto';
import { GET_PHOTO, UPLOAD_PHOTO } from 'gql';

const PhotoUploader: React.FC = () => {
  const {
    user: { sub: userId },
  } = useAuth0();
  const history = useHistory();

  const [image, setImage] = useState<string>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [editor, setEditor] = useState<AvatarEditor>();
  const [zoom, setZoom] = useState('1');

  const editorRef = (editor: AvatarEditor) => setEditor(editor);

  const { loading } = useQuery<GetPhoto, GetPhotoVariables>(GET_PHOTO, {
    variables: { userId },
    errorPolicy: 'all',
    onCompleted: (data) => {
      const {
        grad_profile: [{ profile_image }],
      } = data;
      if (profile_image) {
        setImage(profile_image);
        setImageLoaded(true);
      } else {
        setImage(ProfilePicture);
      }
    },
    onError: () => undefined,
  });

  const [uploadPhotoMutation] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const onDelete = () => {
    setImageLoaded(false);
    setImage(ProfilePicture);
  };

  const onFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          setImage((img as HTMLCanvasElement).toDataURL(file.type));
          setImageLoaded(true);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  const onSubmit = async () => {
    if (imageLoaded && editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const dataUrl = canvasScaled.toDataURL();
      await uploadPhotoMutation({ variables: { userId, profileImage: dataUrl } });
    } else {
      await uploadPhotoMutation({ variables: { userId, profileImage: null } });
    }
    history.goBack();
  };

  return (
    <StyledNoWrapContainer>
      <GoBackHeader />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledFormInputsFlex>
            <StyledLeftH1>Manage Profile Photo</StyledLeftH1>
            <StyledPhotoEditor>
              <AvatarEditor
                ref={editorRef}
                borderRadius={1000}
                color={[0, 0, 0, 0.6]} // RGBA
                scale={parseFloat(zoom)}
                image={image}
                style={{ width: '100%', height: 'auto' }}
              />
              {imageLoaded && (
                <StyledRemovePhotoButton onClick={onDelete}>
                  <TrashIcon src={Trash} />
                </StyledRemovePhotoButton>
              )}
            </StyledPhotoEditor>

            <StyledScaleInput onChange={onZoom} value={zoom} disabled={!imageLoaded}></StyledScaleInput>

            <StyledCustomUpload>
              <StyledUploadFile onChange={onFile} />
              upload photo
            </StyledCustomUpload>
          </StyledFormInputsFlex>

          <StyledButtons>
            <CancelButton onClick={history.goBack}>Cancel</CancelButton>
            <SaveButton onClick={onSubmit}>Save</SaveButton>
          </StyledButtons>
        </>
      )}
    </StyledNoWrapContainer>
  );
};

const StyledCustomUpload = styled.label`
  margin-top: 1.5rem;
  height: 3.2rem;
  width: 12.8rem;

  border-radius: 10px;
  border: 1px solid ${palette.red};
  background: ${palette.white};

  font-weight: 600;
  font-size: 1.4rem;
  line-height: 3.2rem;
  color: ${palette.red};

  text-align: center;
  text-transform: capitalize;

  cursor: pointer;
`;
const StyledUploadFile = styled.input.attrs({
  type: 'file',
  accept: '.png, .jpg, .jpeg',
})`
  display: none;
`;

const StyledPhotoEditor = styled.div`
  position: relative;

  width: 100%;
`;
const StyledScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin-top: 1rem;
  width: 100%;
`;

const StyledFormInputsFlex = styled(StyledFormInputs)`
  flex-direction: column;
  align-items: center;

  display: flex;
`;

const TrashIcon = styled.img`
  display: inline-block;

  height: inherit;
`;

const StyledRemovePhotoButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: inline-block;

  padding: 0.5rem 0.5rem 0 0;
  height: 3rem;
`;

export default PhotoUploader;
