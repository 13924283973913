import gql from 'graphql-tag';
import { EmployerPreferenceSelectionParts } from './fragments.gql';

const query = gql`
  query GetEmployeerPreferenceSelection {
    grad_employer_preference_selection {
      ...EmployerPreferenceSelectionParts
    }
  }
  ${EmployerPreferenceSelectionParts}
`;

export default query;
