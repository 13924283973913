import React from 'react';
import { Container } from './DetailViewLayout.styled';
import GoBackHeader from '../../GoBackHeader';
import NavBar from '../../NavBar';

interface DetailViewLayoutProps {
  children: React.ReactNode;
}

const DetailViewLayout: React.FC<DetailViewLayoutProps> = ({ children }) => {
  return (
    <Container>
      <GoBackHeader />
      {children}
      <NavBar />
    </Container>
  );
};

export default DetailViewLayout;
