import gql from 'graphql-tag';
import { CityStateSelectionParts } from './fragments.gql';

const query = gql`
  query GetCityStateSelections($searchTerm: String!) {
    grad_city_state_selection(where: { city: { _ilike: $searchTerm } }, limit: 10) {
      ...CityStateSelectionParts
    }
  }
  ${CityStateSelectionParts}
`;

export default query;
