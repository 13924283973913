import React from 'react';
import { GetAllJobPostings_recruit_job_posting } from 'generated';
import moment from 'moment';

import { HandRedSmall } from 'theme/assets';
import { jobPath } from '../../StudentViewRoutes';
import { asCompany } from 'transformers/company';
import { Spinner } from 'components';

import { useDeleteBookmarkJobMutation, useInsertBookmarkJobMutation } from '../../Job/Job.hooks';
import { useCompanyQuery } from './JobsListCard.hooks';

import { Card } from '../../../../components/common/CardList';
import { useAuth0 } from '../../../../react-auth0-spa';

import { Region, PublishTime, Company, HandIcon, RaiseHand } from './JobsListCard.styled';

const JobsListCard: React.FC<{ job: GetAllJobPostings_recruit_job_posting }> = ({
  job: { account_id, job_posting_id, position_title, job_type, region, raise_hands, bookmark_jobs, publishing_date },
}) => {
  const { user } = useAuth0();

  const { data, loading } = useCompanyQuery(!account_id, account_id);
  const [insertBookmark] = useInsertBookmarkJobMutation(job_posting_id, bookmark_jobs, user.sub);
  const [deleteBookmark] = useDeleteBookmarkJobMutation(job_posting_id, bookmark_jobs, user.sub);

  const company = data?.recruit_company.map(asCompany)?.[0];

  if (!company || loading) return <Spinner />;

  const tags = [{ text: job_type }];
  const hasRaiseHand = !!raise_hands.length;

  return (
    <Card
      bookmarked={bookmark_jobs.length > 0}
      insertBookmark={insertBookmark}
      deleteBookmark={deleteBookmark}
      cardLink={jobPath(job_posting_id)}
      header={position_title}
      tags={tags}
      logo={company?.logo}
    >
      {hasRaiseHand && (
        <RaiseHand>
          <HandIcon src={HandRedSmall} />
          You raised your hand
        </RaiseHand>
      )}
      <Company>{company.name}</Company> <Region>{region}</Region>
      {publishing_date && <PublishTime>{`Published ${moment(publishing_date).fromNow()}`}</PublishTime>}
    </Card>
  );
};

export default JobsListCard;
