import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from 'theme';

const StyledCheckBox = styled.span`
  position: relative;

  width: 2rem;
  height: 2rem;
  margin: 0 0.5em 0 0;
`;

interface StyledCustomCheckBoxProps {
  checked?: boolean;
}

const StyledCustomCheckBox = styled.span<StyledCustomCheckBoxProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: 2rem;
  height: 2rem;

  border-radius: 2px;
  border: solid 2px ${palette.red};

  :after {
    position: absolute;
    content: '';
    top: 0rem;
    left: 0.4rem;
    height: 0;
    width: 0;

    border-radius: 4px;
    border: solid ${palette.red};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;

    ${({ checked }) =>
      checked &&
      css`
        transform: rotate(45deg) scale(1);
        width: 0.7rem;
        height: 1.4rem;
        background-color: transparent;
        border-radius: 0;
      `}
  }
`;

export interface CheckBoxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange = () => undefined, ...props }) => {
  return (
    <StyledCheckBox {...props}>
      <StyledCustomCheckBox checked={checked} onClick={() => onChange(!checked)} />
    </StyledCheckBox>
  );
};

export default CheckBox;
