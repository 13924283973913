import React from 'react';
import { useCompaniesQuery } from './CompanyList.hooks';
import { ErrorToastMessage, SpinnerPage } from '../../../../components';
import { Redirect } from 'react-router-dom';
import CompanyList from './CompanyList';
import { StudentViewPaths } from '../../StudentViewRoutes';

const CompanyListController: React.FC = () => {
  const { loading, error, data: { recruit_company: companies } = { recruit_company: [] } } = useCompaniesQuery();
  if (loading) return <SpinnerPage />;
  if (error) {
    return (
      <>
        <ErrorToastMessage message={error.message} />
        <Redirect to={StudentViewPaths.home} />
      </>
    );
  }
  return <CompanyList companies={companies} />;
};

export default CompanyListController;
