import { format, parse } from 'date-fns';

import { GetMyProfile_grad_profile_work_experiences } from 'generated/GetMyProfile';

export const convertMonthToString = (numberMonth: number | null) => {
  return numberMonth ? format(parse(`${numberMonth}`, 'M', new Date()), 'MMMM') : '';
};

export const abbreviateMonth = (fullMonth: number | null) => {
  return fullMonth ? format(parse(`${fullMonth}`, 'M', new Date()), 'MMM') : '';
};

export const sortWorkExperiences = (workExperiences: GetMyProfile_grad_profile_work_experiences[] = []) => {
  return workExperiences.sort((a, b) => {
    if (a.end_year === null && b.end_year === null) {
      const aDateStart = new Date(a.start_year, a.start_month_numeric || 12);
      const bDateStart = new Date(b.start_year, b.start_month_numeric || 12);
      return bDateStart.getTime() - aDateStart.getTime();
    }
    if (a.end_year === null) return -1;
    if (b.end_year === null) return 1;

    const aDateEnd = new Date(a.end_year, a.end_month_numeric || 12);
    const bDateEnd = new Date(b.end_year, b.end_month_numeric || 12);
    return bDateEnd.getTime() - aDateEnd.getTime();
  });
};
