import gql from 'graphql-tag';
import { RaisedHandParts } from 'gql/fragments.gql';

export const DELETE_HAND = gql`
  mutation DeleteHand($userId: String!, $jobId: String!) {
    delete_grad_raise_hand(where: { job_posting_id: { _eq: $jobId }, user_id: { _eq: $userId } }) {
      returning {
        ...RaisedHandParts
      }
    }
  }
  ${RaisedHandParts}
`;

export const INSERT_HAND = gql`
  mutation InsertHand($userId: String!, $jobId: String!) {
    insert_grad_raise_hand(objects: { job_posting_id: $jobId, user_id: $userId }) {
      returning {
        ...RaisedHandParts
      }
    }
  }
  ${RaisedHandParts}
`;
