import React, { useState } from 'react';
import { useAuth0 } from 'react-auth0-spa';

import JobList from './JobList';

import { recruit_job_posting_bool_exp } from 'generated/globalTypes';
import { generateWhereQuery, WhereQueryInterface } from 'utils/queryUtils';

import { SpinnerPage } from '../../../../components';
import { useJobsQuery } from './JobList.hooks';

const JobListController: React.FC = () => {
  const { user } = useAuth0();
  const [onlyMyJobs, setOnlyMyJobs] = useState(false);
  const [onlyBookmarkedJobs, setOnlyBookmarkedJobs] = useState(false);

  const raisedHandQuery: WhereQueryInterface<recruit_job_posting_bool_exp> = {
    enabled: onlyMyJobs,
    operator: '_and',
    whereQuery: { raise_hands: { user_id: { _eq: user.sub } } },
  };
  const bookmarkedQuery: WhereQueryInterface<recruit_job_posting_bool_exp> = {
    enabled: onlyBookmarkedJobs,
    operator: '_and',
    whereQuery: { bookmark_jobs: { user_id: { _eq: user.sub } } },
  };
  const dynamicQuery = generateWhereQuery([raisedHandQuery, bookmarkedQuery]);

  const {
    loading: allJobsLoading,
    error: allError,
    data: { recruit_job_posting: allJobs } = { recruit_job_posting: [] },
  } = useJobsQuery(dynamicQuery, user.sub);

  const jobs = allJobs;
  const loading = allJobsLoading;
  const error = allError;

  const onChangeHand = (checked: boolean) => setOnlyMyJobs(checked);
  const onChangeBookMark = (checked: boolean) => setOnlyBookmarkedJobs(checked);
  if (loading) return <SpinnerPage />;
  if (error) return <div>Error</div>;
  return (
    <JobList
      jobs={jobs}
      onChangeHand={onChangeHand}
      onlyMyJobs={onlyMyJobs}
      onChangeBookMark={onChangeBookMark}
      onlyBookmarkedJobs={onlyBookmarkedJobs}
    />
  );
};
export default JobListController;
