import React from 'react';

import { Trash } from 'theme/assets';

import { RemovePhotoButton, TrashIcon } from './DeletePicture.styled';

interface DeletePictureProps {
  loading: boolean;
  onDelete: () => void;
}

const DeletePicture: React.FC<DeletePictureProps> = ({ loading, onDelete }) => {
  return loading ? (
    <RemovePhotoButton onClick={onDelete}>
      <TrashIcon src={Trash} />
    </RemovePhotoButton>
  ) : null;
};

export default DeletePicture;
