import React from 'react';
import EventDetail from './EventController';
import DetailViewLayout from '../../../components/common/DetailViewLayout';

const Event: React.FC = () => {
  return (
    <DetailViewLayout>
      <EventDetail />
    </DetailViewLayout>
  );
};

export default Event;
