import React from 'react';
import { useQuery } from 'react-apollo';
import { FormContextValues } from 'react-hook-form';

import SelectorMultiCreateV2 from '../SelectorMultiCreateV2';
import { OptionId } from '../Option';

import { GET_SELECTOR_MAJORS } from 'gql';
import { GetSelectorMajor } from 'generated/GetSelectorMajor';

interface MajorSelectorProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  required: boolean;
  majorsValue: OptionId[];
  name: string;
}

export const MajorSelector: React.FC<MajorSelectorProps> = ({ majorsValue, name, required, register, setValue }) => {
  const { loading, error, data } = useQuery<GetSelectorMajor>(GET_SELECTOR_MAJORS, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  const selectorOptions = ((!error && data && data.grad_major_selection) || []).map(({ major }) => ({
    value: major,
    label: major,
  }));
  return (
    <SelectorMultiCreateV2
      loading={loading}
      dropDownOptions={selectorOptions}
      defaultOptionsValue={majorsValue}
      name={name}
      placeholder={'e.g: Computer Technician'}
      required={required}
      register={register}
      setValue={setValue}
      maxSelection={2}
    />
  );
};
