import React from 'react';
import CardList from '../../../../components/common/CardList';
import Event from '../../../../types/Event';
import EventListCard from '../EventListCard';

interface EventListProps {
  events: Event[] | undefined;
  registratedEvents: String[];
}

const EventList: React.FC<EventListProps> = ({ events, registratedEvents }) => {
  return (
    <CardList>
      {events?.map((eve) => {
        return (
          <EventListCard
            isRegistrated={registratedEvents.includes(eve.id)}
            endTimestamp={eve.endTimestamp}
            startTimestamp={eve.startTimestamp}
            key={eve.id}
            capacity={eve.capacity}
            eventType={eve.eventType}
            id={eve.id}
            image={eve.image}
            title={eve.title}
          />
        );
      })}
    </CardList>
  );
};

export default EventList;
