import React, { useState } from 'react';

import AvatarEditor from 'react-avatar-editor';
import loadImage from 'blueimp-load-image';

import DeletePicture from './DeletePicture/DeletePicture';
import UploadButton from './UploadButton/UploadButton';
import { AvatarContainer, ScaleInput } from './PictureEditor.styled';

interface PictureEditor {
  queryImage?: string;
  onChange: (image: string) => void;
  onDelete: () => void;
  editorRef?: (editor: AvatarEditor) => void;
}

const PictureEditor: React.FC<PictureEditor> = ({ queryImage, onChange, onDelete, editorRef = null }) => {
  const [zoom, setZoom] = useState('1');

  const onZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(e.target.value);
  };

  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const file = e.target.files[0];
      loadImage(
        e.target.files[0],
        (img) => {
          const dataUrl = (img as HTMLCanvasElement).toDataURL(file.type);
          onChange(dataUrl);
        },
        { orientation: true, canvas: true },
      );
    }
  };

  return (
    <AvatarContainer>
      <AvatarEditor
        ref={editorRef}
        borderRadius={1000}
        color={[0, 0, 0, 0.6]}
        scale={parseFloat(zoom)}
        image={queryImage}
        style={{ width: '100%', height: 'auto' }}
      />
      <DeletePicture loading={!!queryImage} onDelete={onDelete} />
      <ScaleInput onChange={onZoom} value={zoom} disabled={!queryImage}></ScaleInput>
      <UploadButton onFile={onUploadFile} />
    </AvatarContainer>
  );
};

export default PictureEditor;
