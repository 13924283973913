import React from 'react';
import {
  Container,
  SubTitle,
  Info,
  InfoItemLabel,
  AboutCompany,
  AboutCompanyDescription,
} from './CompanyDetailContent.styled';

export interface CompanyDetailContentProps {
  employeesNumber: string | null;
  address: string | null;
  website: string | null;
  description: string;
}

const CompanyDetailContent: React.FC<CompanyDetailContentProps> = ({
  description,
  employeesNumber,
  address,
  website,
}) => {
  return (
    <Container>
      <Info>
        {employeesNumber && (
          <div>
            <InfoItemLabel>Employer count</InfoItemLabel> {employeesNumber}
          </div>
        )}
        {address && (
          <div>
            <InfoItemLabel>Company address</InfoItemLabel>
            {address}
          </div>
        )}
        {website && (
          <div>
            <InfoItemLabel>Company website</InfoItemLabel> {website}
          </div>
        )}
      </Info>
      <AboutCompany>
        <SubTitle>About the company</SubTitle>
        <AboutCompanyDescription>{description}</AboutCompanyDescription>
      </AboutCompany>
    </Container>
  );
};

export default CompanyDetailContent;
