import styled from 'styled-components';

export const Icon = styled.img`
  display: inline-block;

  margin-left: auto;
  height: 1.8rem;

  vertical-align: middle;
`;
