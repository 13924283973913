import gql from 'graphql-tag';
import { AwardParts, GradProfileParts } from 'gql/fragments.gql';

const query = gql`
  query WizardGetProfile($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      ...GradProfileParts

      awards {
        ...AwardParts
      }

      fun_facts {
        description
        fun_fact_id
      }
    }
  }
  ${AwardParts}
  ${GradProfileParts}
`;

export default query;
