import gql from 'graphql-tag';
import { GradProfileParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation UpdateStudentProfile(
    $firstName: String!
    $lastName: String!
    $fullName: String!
    $userId: String!
    $email: String!
    $title: String!
    $mobile: String!
    $hometown: String
    $yearsOfRelevantWork: String
    $studentAthlete: Boolean!
  ) {
    update_grad_profile(
      where: { user_id: { _eq: $userId } }
      _set: {
        firstname: $firstName
        lastname: $lastName
        fullname: $fullName
        email: $email
        title: $title
        mobile: $mobile
        hometown: $hometown
        years_of_relevant_work: $yearsOfRelevantWork
        student_athlete: $studentAthlete
      }
    ) {
      returning {
        ...GradProfileParts
        fun_facts {
          description
          fun_fact_id
        }
      }
    }
  }
  ${GradProfileParts}
`;

export default mutation;
