import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'App.css';
import { useAuth0 } from 'react-auth0-spa';

import { PrivateRoute, SpinnerPage } from 'components';
import useInsertPageViewOnRouteChange from 'utils/pageViews';
import Routes from 'views/routes';

import { StudentViewPaths } from './views/Student/StudentViewRoutes';

const App: React.FC = () => {
  const { loading } = useAuth0();

  useInsertPageViewOnRouteChange();

  if (loading) {
    return <SpinnerPage />;
  }

  return (
    <div className="app-container">
      <Switch>
        <PrivateRoute path="/app" component={Routes} />
        <Redirect to={StudentViewPaths.root}></Redirect>
      </Switch>
      <ToastContainer />
    </div>
  );
};

export default App;
