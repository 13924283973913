import gql from 'graphql-tag';
import { ConcentrationPart } from './fragments.gql';

const mutation = gql`
  mutation InsertConcentrations($concentrations: [grad_concentration_insert_input!]!) {
    insert_grad_concentration(
      objects: $concentrations
      on_conflict: { constraint: concentration_user_id_degree_id_type_val_key, update_columns: [] }
    ) {
      returning {
        ...ConcentrationPart
      }
    }
  }
  ${ConcentrationPart}
`;
export default mutation;
