import gql from 'graphql-tag';
import { GradProfileParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation WizardUpdateProfile(
    $firstName: String!
    $lastName: String!
    $fullName: String!
    $userId: String!
    $email: String!
    $mobile: String!
    $hometown: String
    $title: String!
    $years_of_relevant_work: String!
  ) {
    update_grad_profile(
      where: { user_id: { _eq: $userId } }
      _set: {
        firstname: $firstName
        lastname: $lastName
        fullname: $fullName
        email: $email
        mobile: $mobile
        hometown: $hometown
        title: $title
        years_of_relevant_work: $years_of_relevant_work
      }
    ) {
      returning {
        ...GradProfileParts
      }
    }
  }
  ${GradProfileParts}
`;

export default mutation;
