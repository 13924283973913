import gql from 'graphql-tag';

const query = gql`
  query GetStudentJobInterests($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      interest_job_types {
        job_type
        interest_job_type_id
      }
      interest_firms {
        firm_type
        interest_firm_id
      }
      interest_regions {
        region
        interest_region_id
      }
      interest_roles {
        interest_role_id
        role
      }

      employer_preferences {
        employer_preference_id
        preference
      }
    }
  }
`;

export default query;
