import gql from 'graphql-tag';
import { AwardParts, GradProfileParts } from 'gql/fragments.gql';

const query = gql`
  query GetStudentProfile($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      ...GradProfileParts

      interest_job_types {
        job_type
        interest_job_type_id
      }

      interest_firms {
        firm_type
        interest_firm_id
      }

      fun_facts {
        description
        fun_fact_id
      }

      volunteers {
        description
        volunteer_id
      }

      awards {
        ...AwardParts
      }

      achievements {
        description
        achievement_id
      }

      spoken_languages {
        spoken_language_id
        language
      }

      computer_languages {
        computer_language_id
        language
      }

      legal_residence_country {
        country
        country_selection_id
      }
    }
  }
  ${AwardParts}
  ${GradProfileParts}
`;

export default query;
