import React from 'react';
import withDetailView from '../../../../components/common/withDetailView';
import JobDetailContent, { JobDetailContentProps } from './JobDetailContent';
import JobDetailHeader, { JobDetailHeaderProps } from './JobDetailHeader';
import { DetailViewMainButtonProps } from '../../../../components/common/DetailViewMainButton';
import { HandRed, HandWhite } from '../../../../theme/assets';

const DetailViewHOC = withDetailView<JobDetailHeaderProps, JobDetailContentProps>(JobDetailHeader, JobDetailContent);

interface JobDetailProps extends DetailViewMainButtonProps, JobDetailContentProps, JobDetailHeaderProps {}

const JobDetail: React.FC<JobDetailProps> = ({
  jobId,
  bookmark_jobs,
  description,
  onButtonOn,
  isButtonVisible,
  isButtonDisable,
  onButtonOff,
  title,
  logo,
  location,
  publishingDate,
  tags,
  isButtonOn,
  buttonDisableText,
  buttonTextOff,
  buttonTextOn,
  company_id,
  company_name,
}) => {
  return (
    <DetailViewHOC
      iconButtonOnSrc={HandWhite}
      iconButtonOffSrc={HandRed}
      company_name={company_name}
      company_id={company_id}
      tags={tags}
      location={location}
      publishingDate={publishingDate}
      title={title}
      logo={logo}
      bookmark_jobs={bookmark_jobs}
      jobId={jobId}
      description={description}
      isButtonVisible={isButtonVisible}
      onButtonOn={onButtonOn}
      onButtonOff={onButtonOff}
      buttonTextOff={buttonTextOff}
      buttonDisableText={buttonDisableText}
      buttonTextOn={buttonTextOn}
      isButtonOn={isButtonOn}
      isButtonDisable={isButtonDisable}
    />
  );
};

export default JobDetail;
