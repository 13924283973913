import React from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth0 } from 'react-auth0-spa';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

import { UPSERT_TESTS_SCORES, GET_MY_TEST_SCORES, DELETE_MY_TEST_SCORES, DELETE_TEST_SCORES_DETAIL } from 'gql';
import {
  GetMyTestScores,
  GetMyTestScoresVariables,
  UpsertTestsScores,
  UpsertTestsScoresVariables,
  DeleteTestScoreDetail,
  DeleteTestScoreDetailVariables,
  DeleteTestsScores,
  DeleteTestsScoresVariables,
} from 'generated';

import GoBackHeader from 'components/GoBackHeader';
import { CancelButton, SaveButton, Spinner } from 'components';
import {
  StyledForm,
  StyledFormInputs,
  StyledLeftH1,
  StyledInputLabel,
  StyledInput,
  StyledButtons,
  StyledInputSmallLabel,
  StyledCheckboxContainer,
} from 'components/library';
import { parseTests, generateTests } from 'utils/testUtils';
import { TestOutOf, TestSATDetailsOutof } from 'data/tests';
import { generateErrorMessages } from 'components/FormValidationMessage';
import { palette } from 'theme';
import CheckboxWithController from 'components/CheckboxWithController';

const StyledLabelWrite = styled.div`
  grid-area: labelW;

  color: ${palette.gray};
  font-size: 0.8rem;
`;

const StyledSatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1rem;
  grid-template-areas:
    'math read write'
    'labelM labelR labelW';

  grid-gap: 0.8rem;

  margin-bottom: 1.4rem;

  & label > input {
    margin-bottom: 0;
  }
`;

interface TestsScoresFormVariables {
  [name: string]: number;
}
const TestScoresDetail: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { register, control, handleSubmit, errors, watch } = useForm<Partial<TestsScoresFormVariables>>();

  const { loading, data: rawTestsData = { grad_test_score: [] } } = useQuery<GetMyTestScores, GetMyTestScoresVariables>(
    GET_MY_TEST_SCORES,
    {
      variables: { myUserId: user.sub },
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const testsData = parseTests(rawTestsData);

  const [upsertTestsScoresMutation] = useMutation<UpsertTestsScores, UpsertTestsScoresVariables>(UPSERT_TESTS_SCORES, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: insertResultData }) => {
      const testsCache = cache.readQuery<GetMyTestScores, GetMyTestScoresVariables>({
        query: GET_MY_TEST_SCORES,
        variables: { myUserId: user.sub },
      });

      if (!insertResultData || !insertResultData.insert_grad_test_score) {
        return;
      }

      if (!testsCache || !testsCache.grad_test_score) {
        return;
      }

      const updatedTestsIds = insertResultData.insert_grad_test_score.returning.map(
        ({ test_score_id }: { test_score_id: string }) => test_score_id,
      );
      const notUpdatedTests = testsCache.grad_test_score.filter(
        ({ test_score_id }: { test_score_id: string }) => !updatedTestsIds.includes(test_score_id),
      );

      cache.writeQuery({
        query: GET_MY_TEST_SCORES,
        variables: { myUserId: user.sub },
        data: {
          grad_test_score: [...notUpdatedTests, ...insertResultData.insert_grad_test_score.returning],
        },
      });
    },
  });

  const [deleteTestsDetailMutation] = useMutation<DeleteTestScoreDetail, DeleteTestScoreDetailVariables>(
    DELETE_TEST_SCORES_DETAIL,
    {
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const [deleteTestsScoresMutation] = useMutation<DeleteTestsScores, DeleteTestsScoresVariables>(
    DELETE_MY_TEST_SCORES,
    {
      errorPolicy: 'all',
      onError: () => undefined,
      update: (cache, { data: deleteResultData }) => {
        const testsCache = cache.readQuery<GetMyTestScores, GetMyTestScoresVariables>({
          query: GET_MY_TEST_SCORES,
          variables: { myUserId: user.sub },
        });

        if (!deleteResultData || !deleteResultData.delete_grad_test_score) {
          return;
        }

        if (!testsCache || !testsCache.grad_test_score) {
          return;
        }

        const updatedTestsIds = deleteResultData.delete_grad_test_score.returning.map(
          ({ test }: { test: string }) => test,
        );
        const newTests = testsCache.grad_test_score.filter(
          ({ test }: { test: string }) => !updatedTestsIds.includes(test),
        );

        cache.writeQuery({
          query: GET_MY_TEST_SCORES,
          variables: { myUserId: user.sub },
          data: {
            grad_test_score: newTests,
          },
        });
      },
    },
  );
  const onSubmit = async ({
    gmatScore,
    greScore,
    lsatScore,
    mcatScore,
    satReadingScore,
    satWritingScore,
    satMathScore,
    actScore,
  }: Partial<TestsScoresFormVariables>) => {
    const { toDelete, toUpsert, toDeleteDetail } = generateTests({
      testsData,
      user_id: user.sub,
      satMathScore: Number(satMathScore),
      satReadingScore: Number(satReadingScore),
      satWritingScore: Number(satWritingScore),
      gmatScore: Number(gmatScore),
      greScore: Number(greScore),
      lsatScore: Number(lsatScore),
      mcatScore: Number(mcatScore),
      actScore: Number(actScore),
    });

    if (!!toDeleteDetail.length) await deleteTestsDetailMutation({ variables: { ids: toDeleteDetail } });
    if (!!toUpsert.length) await upsertTestsScoresMutation({ variables: { tests: toUpsert } });
    if (!!toDelete.length) await deleteTestsScoresMutation({ variables: { ids: toDelete } });

    history.goBack();
  };

  const actValue = watch('actScore', '');
  const satMathScore = watch('satMathScore', '');
  const satWritingScore = watch('satWritingScore', '');
  const satReadingScore = watch('satReadingScore', '');
  const watchedSatNotApplicable = !!watch('satNotApplicable');

  const satIncomplete = !satMathScore && !satWritingScore && satReadingScore;
  const satNotApplicableDefault = !testsData || !(testsData.SAT || testsData.ACT);
  const satRequired = watchedSatNotApplicable ? false : !actValue || satIncomplete;
  const actRequired = !satRequired;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GoBackHeader />
      <StyledLeftH1>Tests Results</StyledLeftH1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledFormInputs>
            <StyledCheckboxContainer>
              <CheckboxWithController
                name="satNotApplicable"
                control={control}
                defaultChecked={satNotApplicableDefault}
              />
              SAT and ACT are not applicable
            </StyledCheckboxContainer>
            {!watchedSatNotApplicable && (
              <>
                <StyledInputLabel>SAT</StyledInputLabel>
                <StyledSatContainer>
                  <StyledInputSmallLabel>
                    Math
                    <StyledInput
                      defaultValue={
                        testsData.SAT && testsData.SAT.test_score_details.math
                          ? testsData.SAT.test_score_details.math.score
                          : undefined
                      }
                      type="number"
                      name="satMathScore"
                      placeholder="Math"
                      ref={register({
                        required: satRequired ? 'Math is required if no ACT value' : false,
                        max: {
                          value: TestSATDetailsOutof.MATH,
                          message: `The maximum value for this score is ${TestSATDetailsOutof.MATH}`,
                        },
                      })}
                    />
                  </StyledInputSmallLabel>

                  <StyledInputSmallLabel>
                    Reading
                    <StyledInput
                      defaultValue={
                        testsData.SAT && testsData.SAT.test_score_details.reading
                          ? testsData.SAT.test_score_details.reading.score
                          : undefined
                      }
                      type="number"
                      name="satReadingScore"
                      placeholder="Reading"
                      ref={register({
                        required: satRequired ? 'Reading is required if no ACT value' : false,
                        max: {
                          value: TestSATDetailsOutof.READING,
                          message: `The maximum value for this score is ${TestSATDetailsOutof.READING}`,
                        },
                      })}
                    />
                  </StyledInputSmallLabel>

                  <StyledInputSmallLabel>
                    Writing
                    <StyledInput
                      defaultValue={
                        testsData.SAT && testsData.SAT.test_score_details.writing
                          ? testsData.SAT.test_score_details.writing.score
                          : undefined
                      }
                      type="number"
                      name="satWritingScore"
                      placeholder="Writing"
                      ref={register({
                        max: {
                          value: TestSATDetailsOutof.WRITING,
                          message: `The maximum value for this score is ${TestSATDetailsOutof.WRITING}`,
                        },
                      })}
                    />
                  </StyledInputSmallLabel>
                  <StyledLabelWrite>Optional test score</StyledLabelWrite>
                </StyledSatContainer>
                {generateErrorMessages(errors.satWritingScore)}
                {generateErrorMessages(errors.satMathScore)}
                {generateErrorMessages(errors.satReadingScore)}

                <StyledInputLabel>ACT</StyledInputLabel>
                <StyledInput
                  type="number"
                  name="actScore"
                  placeholder="Enter your ACT Score score"
                  defaultValue={testsData.ACT ? testsData.ACT.score : undefined}
                  ref={register({
                    required: actRequired ? 'ACT is required if no SAT value' : false,
                    max: { value: TestOutOf.ACT, message: `The maximum value for this score is ${TestOutOf.ACT}` },
                  })}
                />
                {generateErrorMessages(errors.actScore)}
              </>
            )}
            <StyledInputLabel>GMAT</StyledInputLabel>
            <StyledInput
              type="number"
              name="gmatScore"
              placeholder="Enter your GMAT score"
              defaultValue={testsData.GMAT ? testsData.GMAT.score : undefined}
              ref={register({
                max: { value: TestOutOf.GMAT, message: `The maximum value for this score is ${TestOutOf.GMAT}` },
              })}
            />
            {generateErrorMessages(errors.gmatScore)}

            <StyledInputLabel>GRE</StyledInputLabel>
            <StyledInput
              type="number"
              name="greScore"
              placeholder="Enter your GRE score"
              defaultValue={testsData.GRE ? testsData.GRE.score : undefined}
              ref={register({
                max: { value: TestOutOf.GRE, message: `The maximum value for this score is ${TestOutOf.GRE}` },
              })}
            />
            {generateErrorMessages(errors.greScore)}

            <StyledInputLabel>LSAT</StyledInputLabel>
            <StyledInput
              type="number"
              name="lsatScore"
              placeholder="Enter your LSAT score"
              defaultValue={testsData.LSAT ? testsData.LSAT.score : undefined}
              ref={register({
                max: { value: TestOutOf.LSAT, message: `The maximum value for this score is ${TestOutOf.LSAT}` },
              })}
            />
            {generateErrorMessages(errors.lsatScore)}

            <StyledInputLabel>MCAT</StyledInputLabel>
            <StyledInput
              type="number"
              name="mcatScore"
              placeholder="Enter your MCAT score"
              defaultValue={testsData.MCAT ? testsData.MCAT.score : undefined}
              ref={register({
                max: { value: TestOutOf.MCAT, message: `The maximum value for this score is ${TestOutOf.MCAT}` },
              })}
            />
            {generateErrorMessages(errors.mcatScore)}
          </StyledFormInputs>
          <StyledButtons>
            <CancelButton onClick={history.goBack}>Cancel</CancelButton>
            <SaveButton>Save</SaveButton>
          </StyledButtons>
        </>
      )}
    </StyledForm>
  );
};

export default TestScoresDetail;
