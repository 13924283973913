import React from 'react';

import UniversityList from './UniversityList';
import { GetMyProfileDegrees, GetMyProfileDegreesVariables } from 'generated/GetMyProfileDegrees';
import { useAuth0 } from 'react-auth0-spa';
import { Spinner } from 'components';
import { useQuery } from 'react-apollo';
import { GET_MY_PROFILE_DEGREE } from 'gql';

const UniversityListController: React.FC = () => {
  const { user } = useAuth0();

  const { loading, error, data } = useQuery<GetMyProfileDegrees, GetMyProfileDegreesVariables>(GET_MY_PROFILE_DEGREE, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: { myUserId: user.sub },
  });

  if (loading) return <Spinner />;
  if (error || !data) return <div>Error</div>;
  const degrees = data.grad_degree;

  return <UniversityList degrees={degrees} />;
};

export default UniversityListController;
