import gql from 'graphql-tag';

const mutation = gql`
  mutation UploadPhoto($userId: String!, $profileImage: String) {
    update_grad_profile(where: { user_id: { _eq: $userId } }, _set: { profile_image: $profileImage }) {
      returning {
        profile_image
        user_id
      }
    }
  }
`;

export default mutation;
