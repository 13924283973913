import styled from 'styled-components';

export const StyledBookmarkIcon = styled.img`
  display: inline-block;

  margin-left: auto;
  width: 1.9rem;
  height: 2.1rem;

  vertical-align: middle;
`;
