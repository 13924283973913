import gql from 'graphql-tag';
import { DegreeParts, TestScorePart, WorkExperienceParts } from 'gql/fragments.gql';

const query = gql`
  query GetMyProfile($myUserId: String!) {
    grad_profile(where: { user_id: { _eq: $myUserId } }) {
      user_id
      fullname
      title
      wizard_completed
      profile_image
      degrees(order_by: { complete_year: desc, complete_month_numeric: desc }) {
        ...DegreeParts
      }
      test_scores {
        ...TestScorePart
      }
      work_experiences(order_by: { end_year: desc, end_month_numeric: desc }) {
        ...WorkExperienceParts
      }
    }
  }
  ${WorkExperienceParts}
  ${DegreeParts}
  ${TestScorePart}
`;

export default query;
