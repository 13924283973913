/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useAuth0 } from 'react-auth0-spa';

import INSERT_PAGE_VIEW from './insertPageView.gql';
import { InsertPageView, InsertPageViewVariables } from '../generated/InsertPageView';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

const useInsertPageViewOnRouteChange = () => {
  const history = useHistory();
  const { loading, user } = useAuth0();

  const [InsertPageView] = useMutation<InsertPageView, InsertPageViewVariables>(INSERT_PAGE_VIEW, {
    errorPolicy: 'all',
    onError: () => undefined,
  });

  useEffect(() => {
    return history.listen((location: { pathname: string }, action: string) => {
      const pathName = location.pathname;
      ReactGA.pageview(pathName);
      if (user && user.email_verified) {
        InsertPageView({ variables: { userId: user.sub, pathName, json: { action } } });
      }
    });
  }, [history, InsertPageView, loading, user]);
};
export default useInsertPageViewOnRouteChange;
