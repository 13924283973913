import React from 'react';
import { FormContextValues } from 'react-hook-form';

import Selector from './Selector';

const YEARS_OF_RELEVANT_WORK_OPTIONS = [
  { value: 'None', label: 'None' },
  { value: '1-3', label: '1-3' },
  { value: '3-5', label: '3-5' },
  { value: '5+', label: '5+' },
];

export interface RelevantYearsSelectorProps extends Pick<FormContextValues, 'control'> {
  defaultValue: string | null;
  required?: boolean;
  name: string;
}

const RelevantYearsSelector: React.FC<RelevantYearsSelectorProps> = ({
  defaultValue = null,
  required,
  control,
  name,
}) => {
  return (
    <Selector
      options={YEARS_OF_RELEVANT_WORK_OPTIONS}
      defaultValue={defaultValue ? { value: defaultValue, label: defaultValue } : null}
      name={name}
      control={control}
      required={required}
      placeholder="Select your years of relevant work experience/internship"
    />
  );
};

export default RelevantYearsSelector;
