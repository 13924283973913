import gql from 'graphql-tag';

const mutation = gql`
  mutation UpdateUploadDoc($uploadDocId: String!, $fileName: String!, $hexContent: String!) {
    update_grad_upload_doc(
      where: { upload_doc_id: { _eq: $uploadDocId } }
      _set: { filename: $fileName, hex_content: $hexContent }
    ) {
      returning {
        upload_doc_id
        user_id
        filename
        hex_content
      }
    }
  }
`;

export default mutation;
