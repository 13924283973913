import React, { useState, useEffect } from 'react';
import { FormContextValues } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { mergeStyles, StylesConfig } from 'react-select';

import { OptionId, createOptionId } from './Option';
import { selectorStyle } from 'theme';

export interface SelectorMultiCreateProps extends Pick<FormContextValues, 'register' | 'setValue'> {
  defaultOptionsValue: OptionId[];
  dropDownOptions?: OptionId[];
  name: string;
  loading?: boolean;
  placeholder?: string;
  required?: boolean;
  showMenu?: boolean;
  dropDownIndicator?: boolean;
  requiredMessage?: string;
  maxSelection?: number;
  customStyle?: StylesConfig;
}

const SelectorMultiCreateV2: React.FC<SelectorMultiCreateProps> = ({
  defaultOptionsValue,
  dropDownOptions,
  name,
  loading = false,
  placeholder = 'Select...',
  register,
  setValue,
  showMenu = true,
  dropDownIndicator = true,
  required = false,
  requiredMessage = 'Select at least one option',
  maxSelection = Infinity,
  customStyle = {},
}) => {
  const [inputValue, setInputValue] = useState('');
  const [optionsValue, setOptionsValue] = useState(defaultOptionsValue);
  const [deletedOptions, setDeletedOptions] = useState<OptionId[]>([]);

  useEffect(() => {
    register(name, {
      ...(required && {
        validate: {
          required: (value) => value && value.optionsValue.length > 0,
        },
      }),
    });
  }, [name, register, required]);

  useEffect(() => {
    const newOptions = optionsValue.filter(({ id }: OptionId): boolean => id === undefined);
    const deletedOptionsIds = deletedOptions.map(({ id }) => id);

    setValue(name, { optionsValue, newOptions, deletedOptions, deletedOptionsIds });
  }, [optionsValue, deletedOptions, name, setValue, requiredMessage, required]);

  const newOptionFlow = () => {
    if (optionsValue.length <= maxSelection && inputValue && inputValue.length) {
      setInputValue('');
      const exists = optionsValue.some(({ label }) => label === inputValue);
      const newOptionsValue = [...optionsValue, ...(exists ? [] : [createOptionId(inputValue)])];
      setOptionsValue(newOptionsValue);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any, { action, removedValue }: any) => {
    if (action === 'remove-value' && removedValue && removedValue.id) {
      setDeletedOptions([...deletedOptions, removedValue]);
    }
    const newOptionsValue = value ? value : [];
    setOptionsValue(newOptionsValue);
  };

  const handleInputChange = (typedValue: string) => setInputValue(typedValue);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        newOptionFlow();
        event.preventDefault();
    }
  };

  const selectorConfig = {
    isClearable: false,
    isMulti: true,
    isSearchable: true,
    ...(!showMenu && { menuIsOpen: false }),
    ...(dropDownOptions && { options: dropDownOptions }),
    ...((!dropDownIndicator || optionsValue.length >= maxSelection) && { components: { DropdownIndicator: null } }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, customStyle);
  const selectorLimitStyle = mergeStyles(selectorOwnStyles, {
    input: (base: object) => ({
      ...base,
      display: optionsValue && optionsValue.length < maxSelection ? 'block' : 'none',
    }),
  });
  return (
    <CreatableSelect
      value={optionsValue}
      inputValue={inputValue}
      loading={loading}
      placeholder={placeholder}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      {...selectorConfig}
      styles={selectorLimitStyle}
    />
  );
};

export default SelectorMultiCreateV2;
