import React from 'react';
import { FormContextValues } from 'react-hook-form';
import styled from 'styled-components';

import { GPAPattern } from 'utils/validations';
import GPAOutOfPicker, { DEFAULT_GPA_OUT_OF } from './GPAOutOfPicker';
import { StyledInput, StyledInputLabel } from './library';

const StyledGPAInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > * {
    width: 100%;
  }

  & > * + * {
    margin-left: 0.8rem;
  }
`;

interface Props extends Pick<FormContextValues, 'control' | 'register'> {
  defaultGPA: number | null;
  defaultOutOf: number | null;
  watchedOutOf: number | null;
  gpaFieldName: string;
  outOfFieldName: string;
  required: boolean;
}

const GPAInput: React.FC<Props> = ({
  defaultGPA,
  defaultOutOf,
  watchedOutOf,
  outOfFieldName,
  gpaFieldName,
  control,
  register,
  required,
}) => {
  const maxGPA = watchedOutOf === null ? DEFAULT_GPA_OUT_OF : watchedOutOf;

  return (
    <StyledGPAInput>
      <div>
        <StyledInputLabel>GPA</StyledInputLabel>
        <StyledInput
          type="text"
          name={gpaFieldName}
          placeholder="e.g: 3.91"
          ref={register({
            pattern: {
              value: GPAPattern,
              message: 'GPA must be a numeric value greater than 0',
            },
            validate: {
              min: (value) => parseFloat(value) > 0 || 'GPA must be greater than 0',
              max: (value) => {
                return parseFloat(value) <= maxGPA || `GPA must be less or equal than ${maxGPA}`;
              },
            },
          })}
          defaultValue={defaultGPA ? defaultGPA.toFixed(2) : ''}
        />
      </div>
      <div>
        <StyledInputLabel>out of</StyledInputLabel>
        <GPAOutOfPicker
          name={outOfFieldName}
          required={required}
          defaultValue={defaultOutOf || DEFAULT_GPA_OUT_OF}
          control={control}
        />
      </div>
    </StyledGPAInput>
  );
};

export default GPAInput;
