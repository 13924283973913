import gql from 'graphql-tag';

export const Achievement = gql`
  fragment Achievement on grad_achievement {
    achievement_id
    description
    user_id
  }
`;

export const Volunteer = gql`
  fragment Volunteer on grad_volunteer {
    volunteer_id
    description
    user_id
  }
`;

export const ComputerLanguage = gql`
  fragment ComputerLanguage on grad_computer_language {
    computer_language_id
    language
    user_id
  }
`;

export const SpokenLanguage = gql`
  fragment SpokenLanguage on grad_spoken_language {
    spoken_language_id
    language
    user_id
  }
`;
