import React from 'react';
import { useQuery } from 'react-apollo';
import { useAuth0 } from 'react-auth0-spa';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { notify } from 'utils/notify';

import { UPDATE_MY_WORK, GET_MY_WORK, INSERT_MY_WORK, DELETE_MY_WORK, GET_MY_PROFILE } from 'gql';

import WorkDetail, { WorkFormVariables } from './WorkDetail';
import {
  GetMyWork,
  GetMyWorkVariables,
  InsertMyWork,
  InsertMyWorkVariables,
  GetMyProfile,
  GetMyProfileVariables,
  UpdateMyWork,
  UpdateMyWorkVariables,
  DeleteMyWork,
  DeleteMyWorkVariables,
} from 'generated';

const WorkDetailController: React.FC = () => {
  const history = useHistory();
  const { id: myWorkId } = useParams<{ id: string }>();
  const {
    user: { sub: myUserId },
  } = useAuth0();

  const { loading: loadingGetMyWork, error: errorGetWork, data: dataGetWork } = useQuery<GetMyWork, GetMyWorkVariables>(
    GET_MY_WORK,
    { skip: !myWorkId, variables: { myWorkId } },
  );

  const [insertWorkExperience, { loading: loadingInsert, error: insertError }] = useMutation<
    InsertMyWork,
    InsertMyWorkVariables
  >(INSERT_MY_WORK, {
    update: (cache, { data: insertResultData }) => {
      const myProfileData = cache.readQuery<GetMyProfile, GetMyProfileVariables>({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
      });

      if (!myProfileData || !insertResultData?.insert_grad_work_experience) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const newMyGradProfile = {
        ...oldGradProfile,
        work_experiences: [
          ...oldGradProfile.work_experiences,
          ...insertResultData.insert_grad_work_experience.returning,
        ],
      };

      cache.writeQuery({
        query: GET_MY_PROFILE,
        variables: { myUserId },
        data: { grad_profile: [newMyGradProfile] },
      });
    },
  });

  const [updateWorkExperience, { loading: loadingUpdate, error: updateError }] = useMutation<
    UpdateMyWork,
    UpdateMyWorkVariables
  >(UPDATE_MY_WORK);

  const [deleteMyWork, { loading: loadingDelete }] = useMutation<DeleteMyWork, DeleteMyWorkVariables>(DELETE_MY_WORK, {
    errorPolicy: 'all',
    update: (cache, { data: deleteResultData }) => {
      const myProfileData = cache.readQuery<GetMyProfile, GetMyProfileVariables>({
        query: GET_MY_PROFILE,
        variables: {
          myUserId,
        },
      });

      if (!myProfileData || !deleteResultData?.delete_grad_work_experience) return;

      const oldGradProfile = myProfileData.grad_profile[0];
      const deleteId = deleteResultData.delete_grad_work_experience.returning[0].work_experience_id;
      const newMyGradProfile = {
        ...oldGradProfile,
        work_experiences: (oldGradProfile.work_experiences || []).filter(
          ({ work_experience_id }) => work_experience_id !== deleteId,
        ),
      };

      cache.writeQuery({
        query: GET_MY_PROFILE,
        variables: { myUserId },
        data: { grad_profile: [newMyGradProfile] },
      });
    },
  });

  if (updateError || insertError || errorGetWork) notify('noDataToastId', 'Error');
  if (!loadingGetMyWork && myWorkId && (!dataGetWork || !dataGetWork.grad_work_experience.length))
    notify('noDataToastId', 'No data returned');

  const workDetailsData = dataGetWork?.grad_work_experience[0];
  const persistedWorkId = dataGetWork?.grad_work_experience?.[0]?.work_experience_id;

  const onDelete = async () => {
    if (persistedWorkId) await deleteMyWork({ variables: { workId: persistedWorkId } });
    history.goBack();
  };

  const onSubmit = async (data: WorkFormVariables) => {
    const {
      position,
      division,
      startMonth,
      startYear,
      endMonth,
      endYear,
      internship,
      current,
      cityStateOption: [cityStateOption] = [null],
      companyOption: [companyOption] = [null],
    } = data;

    const variables = {
      position,
      division,
      startMonth: startMonth.value,
      startYear: startYear.value,
      endMonth: current ? null : endMonth.value,
      endYear: current ? null : endYear.value,
      jobType: internship ? 'internship' : 'full time',
      current,
      city: cityStateOption ? cityStateOption.city : null,
      state: cityStateOption ? cityStateOption.state : null,
      company: companyOption ? companyOption.value : null,
    };
    if (!persistedWorkId) await insertWorkExperience({ variables: { myUserId: myUserId, ...variables } });
    else await updateWorkExperience({ variables: { workId: persistedWorkId, ...variables } });
    history.goBack();
  };

  return (
    <WorkDetail
      loading={loadingGetMyWork || loadingInsert || loadingUpdate || loadingDelete}
      onSubmit={onSubmit}
      onDelete={onDelete}
      workData={workDetailsData}
      isNew={!persistedWorkId}
    />
  );
};

export default WorkDetailController;
