import gql from 'graphql-tag';

const mutation = gql`
  mutation InsertRoleInterests($roles: [grad_interest_role_insert_input!]!) {
    insert_grad_interest_role(objects: $roles) {
      returning {
        role
      }
    }
  }
`;

export default mutation;
