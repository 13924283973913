import { OptionId } from 'components';

export const multiSelectorToRequestObject = (options: OptionId[], userID: string, key: string) => {
  return options?.map(({ id, value }) => ({
    description: value,
    user_id: userID,
    ...(id && { [key]: id }),
  }));
};

export const languageToRequestObject = (options: OptionId[], userID: string, key: string) => {
  return options?.map(({ id, label }) => ({
    user_id: userID,
    language: label,
    ...(id && { [key]: id }),
  }));
};
