import styled from 'styled-components';
import { palette } from '../../../../theme';

export const HandIcon = styled.img`
  display: inline-block;
  margin-right: 0.7rem;
`;

export const RaiseHand = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 40px;
  padding-top: 10px;

  height: 1.5rem;
  margin-bottom: 0.7rem;

  font-size: 1rem;
  line-height: 1.8rem;
  color: ${palette.red};
`;

export const PublishTime = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${palette.gray};
`;

export const Company = styled.span`
  display: inline-block;
  font-weight: bold;
  color: ${palette.gray};
  font-size: 1.3rem;
`;

export const Region = styled.span`
  display: inline-block;

  font-size: 1rem;
  line-height: 1.2rem;

  color: ${palette.gray};
`;
