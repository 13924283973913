import styled from 'styled-components';
import { WizardStepTitle } from '../components';

export const Title = styled(WizardStepTitle)`
  margin-bottom: 4.8rem;
`;

export const Photo = styled.div`
  width: 100%;
  height: 80%;
`;
