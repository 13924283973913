import React from 'react';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { FormValidationMessage, CancelButton, SaveButton, Spinner } from 'components';
import GoBackHeader from 'components/GoBackHeader';
import CityStatePicker, { CityStatePickerOption } from 'components/CityStatePicker';
import CompanyPicker from 'components/CompanyPicker';
import { MonthYearPicker } from 'components/MonthYearPicker';
import {
  StyledInputLabel,
  StyledFormInputs,
  StyledForm,
  StyledInput,
  StyledButtons,
  StyledLeftH1,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledDeleteButton,
} from 'components/library';

import { GetMyWork_grad_work_experience } from 'generated/GetMyWork';

export interface WorkFormVariables {
  position: string | null;
  division: string | null;
  startMonth: { value: number; label: string };
  startYear: { value: number; label: string };
  endMonth: { value: number; label: string };
  endYear: { value: number; label: string };
  jobType: string;
  current: boolean;
  internship: boolean;
  cityStateOption: CityStatePickerOption[];
  companyOption: { value: string; label: string }[];
}

export interface WorkDetailInterface {
  loading: boolean;
  onSubmit: (data: WorkFormVariables) => Promise<void>;
  onDelete: () => Promise<void>;
  workData?: GetMyWork_grad_work_experience;
  isNew: boolean;
}
const WorkDetail: React.FC<WorkDetailInterface> = ({ loading, onSubmit, onDelete, workData, isNew }) => {
  const history = useHistory();

  const { register, control, watch, handleSubmit, errors } = useForm<WorkFormVariables>();

  const {
    end_year = null,
    end_month_numeric = null,
    start_year = null,
    start_month_numeric = null,
    company = null,
    position = null,
    division = null,
    current = true,
    state = null,
    city = null,
    job_type = 'internship',
  } = workData || {};

  const internship = job_type === 'internship';
  const currentJobPosition = !!watch('current', 'true');

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <GoBackHeader />
      <StyledLeftH1>Relevant Work Experience/Internship</StyledLeftH1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <StyledFormInputs>
            <StyledInputLabel>Company</StyledInputLabel>
            <CompanyPicker defaultValue={company} name="companyOption" control={control} clearable required />
            {errors.companyOption && <FormValidationMessage message="Company is required." />}

            <StyledInputLabel>Position</StyledInputLabel>
            <StyledInput
              type="text"
              name="position"
              placeholder="Accountant"
              ref={register({ required: true })}
              defaultValue={position || ''}
            />
            {errors.position && <FormValidationMessage message="Position is required." />}

            <StyledInputLabel>Division</StyledInputLabel>
            <StyledInput
              type="text"
              name="division"
              placeholder="Energy Team"
              ref={register({ required: false })}
              defaultValue={division || ''}
            />

            <StyledCheckboxContainer>
              <StyledCheckbox type="checkbox" ref={register} name="internship" defaultChecked={internship || false} />
              <StyledInputLabel>Internship experience</StyledInputLabel>
            </StyledCheckboxContainer>

            <StyledCheckboxContainer>
              <StyledCheckbox type="checkbox" ref={register} name="current" defaultChecked={current || false} />
              <StyledInputLabel>Current position</StyledInputLabel>
            </StyledCheckboxContainer>

            <StyledInputLabel>Start Date</StyledInputLabel>
            <MonthYearPicker
              required={true}
              control={control}
              monthFieldName="startMonth"
              yearFieldName="startYear"
              errors={errors}
              defaultMonth={start_month_numeric || null}
              defaultYear={start_year || null}
            ></MonthYearPicker>

            {!currentJobPosition && (
              <>
                <StyledInputLabel>End Date</StyledInputLabel>
                <MonthYearPicker
                  required={!currentJobPosition}
                  monthFieldName="endMonth"
                  yearFieldName="endYear"
                  errors={errors}
                  control={control}
                  defaultMonth={end_month_numeric || null}
                  defaultYear={end_year || null}
                ></MonthYearPicker>
              </>
            )}

            <StyledInputLabel>City</StyledInputLabel>
            <CityStatePicker
              defaultCity={city}
              defaultState={state}
              name="cityStateOption"
              control={control}
              clearable
              required={false}
            />

            {!isNew && (
              <StyledDeleteButton onClick={onDelete}>Remove Relevant Work Experience/Internship</StyledDeleteButton>
            )}
          </StyledFormInputs>
          <StyledButtons>
            <CancelButton onClick={history.goBack}>Cancel</CancelButton>
            <SaveButton>Save</SaveButton>
          </StyledButtons>
        </>
      )}
    </StyledForm>
  );
};

export default WorkDetail;
