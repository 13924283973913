import React from 'react';
import styled from 'styled-components';

import { AcademicHonor } from 'theme/assets';
import { palette } from 'theme';

export interface AcademicHonorTagProps {
  awardName: string;
}

const AcademicHonorTag: React.FC<AcademicHonorTagProps> = ({ awardName }) => {
  return (
    <StyledAcademicHonorTag>
      <StyledIcon src={AcademicHonor} />
      <StyledLabel>{awardName}</StyledLabel>
    </StyledAcademicHonorTag>
  );
};

const StyledAcademicHonorTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0.3rem 0.5rem;

  border: 1px solid ${palette.lightGray};
  border-radius: 4px;
  background-color: ${palette.clearGray};

  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${palette.brownGrey};
`;

const StyledLabel = styled.span`
  margin-left: 0.4rem;
`;

const StyledIcon = styled.img`
  width: 1.4rem;
  height: 1.4rem;

  vertical-align: middle;
`;

export default AcademicHonorTag;
