import React from 'react';
import { GetAllCompanies_recruit_company } from '../../../../generated/GetAllCompanies';
import CompanyListCard from '../CompanyListCard';
import CardList from '../../../../components/common/CardList';

interface CompanyListProps {
  companies: GetAllCompanies_recruit_company[];
}

const CompanyList: React.FC<CompanyListProps> = ({ companies }) => {
  return (
    <CardList>
      {companies.map((company) => (
        <CompanyListCard
          key={company.company_id}
          numEmployees={company.num_employees}
          name={company.name}
          industry={company.industry}
          logo={company.logo}
          companyId={company.company_id}
          subIndustries={Array.from(new Set(company.subindustries.map((subindustry) => subindustry.value)))}
        />
      ))}
    </CardList>
  );
};

export default CompanyList;
