import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';
import { StudentViewPaths } from '../views/Student/StudentViewRoutes';

import { Jobs, Profile, Company, Events } from 'theme/assets';
import { palette } from 'theme';

const NavBar: React.FC = () => (
  <StyledFooterContainer>
    <StyledFooter>
      <StyledNavLink to={StudentViewPaths.jobs}>
        <StyledIcon src={Jobs} alt="Jobs" />
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.home}>
        <StyledIcon src={Profile} alt="Profile" />
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.companies}>
        <StyledIcon src={Company} alt="company" />
      </StyledNavLink>
      <StyledNavLink to={StudentViewPaths.events}>
        <StyledIcon src={Events} alt="event" />
      </StyledNavLink>
    </StyledFooter>
  </StyledFooterContainer>
);

const StyledNavLink = styled(RouterNavLink).attrs({ activeStyle: { opacity: 1 } })`
  opacity: 0.5;

  text-decoration: none;

  :focus {
    outline: none;
  }
`;

const StyledFooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  height: 4.8rem;

  background-color: ${palette.red};

  text-align: center;
  color: ${palette.white};
`;

const StyledIcon = styled.img`
  height: 2rem;
  width: 2rem;
`;

export default NavBar;
