import React from 'react';
import { FormContextValues } from 'react-hook-form';
import styled from 'styled-components';

import { FormValidationMessage } from 'components';
import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';

interface MonthYearPickerProps extends Pick<FormContextValues, 'control' | 'errors'> {
  required?: boolean;
  defaultMonth: number | null;
  defaultYear: number | null;
  monthFieldName: string;
  yearFieldName: string;
}

export const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  required = false,
  defaultMonth = null,
  defaultYear = null,
  monthFieldName,
  yearFieldName,
  control,
  errors,
}) => {
  return (
    <>
      <DoubleDropDown>
        <MonthPicker defaultMonth={defaultMonth} name={monthFieldName} required={required} control={control} />
        <YearPicker defaultYear={defaultYear} name={yearFieldName} required={required} control={control} />
      </DoubleDropDown>
      {(errors[yearFieldName] || errors[monthFieldName]) && (
        <FormValidationMessage message="Month and Year are required" />
      )}
    </>
  );
};

const DoubleDropDown = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin-bottom: 1.4rem;
  height: 3.4rem;
  width: 100%;

  line-height: 1.9rem;
  font-size: 1.4rem;
  font-weight: 300;

  & > * + * {
    margin-left: 0.8rem;
  }
`;
