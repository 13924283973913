import gql from 'graphql-tag';
import { InterestFirmSelectionParts } from './fragments.gql';

const query = gql`
  query GetFirmInterestSelections {
    grad_interest_firm_selection {
      ...InterestFirmSelectionParts
    }
  }
  ${InterestFirmSelectionParts}
`;

export default query;
