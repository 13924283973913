import React from 'react';
import CheckBox from '../../../../components/CheckBox';
import { StyledInputLabel } from '../../../../components';
import CardList from '../../../../components/common/CardList';
import JobsListCard from '../JobsListCard';

import { CheckboxContainerPadded } from './JobList.styled';
import { GetAllJobPostings_recruit_job_posting } from '../../../../generated';

interface JobListProps {
  onlyMyJobs: boolean;
  onlyBookmarkedJobs: boolean;
  onChangeHand: (status: boolean) => void;
  onChangeBookMark: (status: boolean) => void;
  jobs: GetAllJobPostings_recruit_job_posting[];
}

const JobList: React.FC<JobListProps> = ({ onlyMyJobs, onChangeHand, onChangeBookMark, onlyBookmarkedJobs, jobs }) => {
  return (
    <>
      <CheckboxContainerPadded>
        <CheckBox checked={onlyMyJobs} onChange={onChangeHand} />
        <StyledInputLabel>Filter by raised hands jobs</StyledInputLabel>
      </CheckboxContainerPadded>

      <CheckboxContainerPadded>
        <CheckBox checked={onlyBookmarkedJobs} onChange={onChangeBookMark} />
        <StyledInputLabel>Filter by bookmarked</StyledInputLabel>
      </CheckboxContainerPadded>

      <CardList>
        {jobs.map((job, index) => (
          <JobsListCard key={job.job_posting_id + index} job={job} />
        ))}
      </CardList>
    </>
  );
};

export default JobList;
