import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from 'react-auth0-spa';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';

import { FormValidationMessage, RadioButtonWithLabel } from 'components';
import palette from 'theme/palette';
import { notify } from 'utils/notify';
import { SpinnerPage } from 'components';
import CountryPicker from 'components/CountryPicker';

import { UPDATE_MY_VISA, GET_MY_VISA } from './gql';
import { WizardUpdateMyVisa, WizardUpdateMyVisaVariables } from 'generated/WizardUpdateMyVisa';
import { WizardGetMyVisa, WizardGetMyVisaVariables } from 'generated/WizardGetMyVisa';
import {
  SUBMIT_GO_TO,
  CANCEL_GO_TO,
  WizardContainer,
  WizardForm,
  WizardFormInputs,
  WizardFormButtons,
  WizardStepTitle,
  WizardInputLabel,
} from '../components';
import { WizardProps } from '../components/interface';
import { WizardRequiredInputLabel } from '../components/library';

const StyledFieldRow = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${palette.darkGrey};

  &:nth-child(1n) {
    margin-bottom: 4.4rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > :nth-child(1n) {
    margin-bottom: 1rem;
  }

  > :last-child {
    margin-bottom: 0rem;
  }
`;

interface LegalFormVariables {
  authorizedToWork: string;
  needSponsor: string;
  legalResidenceCountry: { label: string; value: string };
}

const LegalStep: React.SFC<WizardProps> = ({
  isLastStep = false,
  nextGoTo = SUBMIT_GO_TO,
  cancelSkipGoTo = CANCEL_GO_TO,
  step,
  totalSteps,
}) => {
  const history = useHistory();
  const { user } = useAuth0();
  const { handleSubmit, register, control, errors } = useForm<LegalFormVariables>();

  const [updateVisa, { loading: mutationLoading, error: mutationError }] = useMutation<
    WizardUpdateMyVisa,
    WizardUpdateMyVisaVariables
  >(UPDATE_MY_VISA, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    update: (cache, { data: mutationData }) => {
      if (mutationData && mutationData.update_grad_profile) {
        cache.writeQuery({
          query: GET_MY_VISA,
          variables: {
            myUserId: user.sub,
          },
          data: { grad_profile: mutationData.update_grad_profile.returning },
        });
      }
    },
  });

  const { loading, error, data } = useQuery<WizardGetMyVisa, WizardGetMyVisaVariables>(GET_MY_VISA, {
    variables: { myUserId: user.sub },
    errorPolicy: 'all',
  });

  if (loading) return <SpinnerPage />;
  if (error) {
    const queryToastId = 'WizardGetMyVisaToast';
    notify(queryToastId, error.message);
    return <div>Error</div>;
  }

  const myVisaInfo = data?.grad_profile[0];

  const onSubmit = (data: LegalFormVariables) => {
    const { authorizedToWork, needSponsor, legalResidenceCountry } = data;

    updateVisa({
      variables: {
        myUserId: user.sub,
        authorizedToWork: authorizedToWork === 'yes',
        needSponsor: needSponsor === 'yes',
        legalResidenceCountryId: legalResidenceCountry.value,
      },
    }).then(() => history.push(nextGoTo));
  };

  if (mutationLoading) return mutationLoading && <SpinnerPage />;

  if (mutationError) {
    const mutationToastId = 'updateVisaToast';
    notify(mutationToastId, mutationError.message);
  }

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <WizardForm onSubmit={handleSubmit(onSubmit)}>
        <WizardFormInputs>
          <WizardStepTitle>Work Authorization</WizardStepTitle>
          <WizardRequiredInputLabel>What is your Country of Legal Residence?</WizardRequiredInputLabel>
          <CountryPicker
            name="legalResidenceCountry"
            defaultCountryId={myVisaInfo?.legal_residence_country?.country_selection_id || null}
            defaultCountryName={myVisaInfo?.legal_residence_country?.country || null}
            required={true}
            control={control}
          />
          {errors.legalResidenceCountry && <FormValidationMessage message="Country of Legal Residence is required." />}

          <StyledFieldRow>
            <WizardInputLabel>Are you authorized to work lawfully in the United States?</WizardInputLabel>
            <RadioButtonWithLabel
              label="Yes"
              name="authorizedToWork"
              value="yes"
              defaultChecked={!!myVisaInfo?.authorized_to_work}
              inputRef={register({})}
            />
            <RadioButtonWithLabel
              label="No"
              name="authorizedToWork"
              value="no"
              defaultChecked={!myVisaInfo?.authorized_to_work}
              inputRef={register({})}
            />
          </StyledFieldRow>

          <StyledFieldRow>
            <div>
              Will you require sponsorship for a work visa in order to work lawfully in the United States for the
              companies you are applying to?
            </div>
            <RadioButtonWithLabel
              label="Yes"
              name="needSponsor"
              value="yes"
              defaultChecked={!!myVisaInfo?.need_sponsor}
              inputRef={register({})}
            />
            <RadioButtonWithLabel
              label="No"
              name="needSponsor"
              value="no"
              defaultChecked={!myVisaInfo?.need_sponsor}
              inputRef={register({})}
            />
          </StyledFieldRow>
        </WizardFormInputs>
        <WizardFormButtons step={step} isLastStep={isLastStep} cancelSkipGoTo={cancelSkipGoTo} />
      </WizardForm>
    </WizardContainer>
  );
};

export default LegalStep;
