import styled from 'styled-components';

import { palette } from 'theme';

export const WizardStepTitle = styled.h1`
  margin-top: 2.8rem;
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 2.8rem;
  text-align: left;
  color: ${palette.red};
`;

export const WizardForm = styled.form.attrs({
  noValidate: true, // Prevent browser validations and browser error displaying
})`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

export const WizardContainer = styled.div`
  align-items: center;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

export const WizardInputLabel = styled.label`
  box-sizing: border-box;
  margin: 0 0 0.8rem;
  width: 100%;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGrey};
`;

export const WizardRequiredInputLabel = styled.label`
  &:after {
    content: ' *';
    color: ${palette.red}
  },
  box-sizing: border-box;
  margin: 0 0 0.8rem;
  width: 100%;

  font-size: 1.4rem;
  font-weight: 600;

  color: ${palette.darkGrey};
`;

export const WizardRequiredMessageForm = styled.span`
  &:before {
    content: '* ';
    color: ${palette.red};
  }

  color: ${palette.red};
  font-size: 1rem;
`;

export const WizardInput = styled.input`
  margin-bottom: 1.4rem;
  box-sizing: border-box;
  border: 1px solid ${palette.lightGray};
  border-radius: 8px;
  width: 100%;
  height: 3.4rem;
  padding: 1rem;

  line-height: 1.9rem;
  font-size: 1.4rem;
  font-weight: 300;

  ::placeholder {
    color: ${palette.brownGrey};
  }

  :focus {
    outline: none;
  }
`;

export const WizardFormInputs = styled.div`
  overflow-y: scroll;

  width: 100%;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
`;

export const WizardCheckBox = styled.input`
  box-sizing: border-box;
  border: 1px solid ${palette.black};
  border-radius: 8px;
  padding: 0.6rem;

  background-color: ${palette.white};
`;

export const WizardCheckBoxRow = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 1.4rem;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  color: ${palette.darkGrey};
`;

export const StepLegend = styled.div`
  margin-bottom: 1.4rem;
  width: 100%;

  font-size: 1.3rem;
  text-align: left;
`;
