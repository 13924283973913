import React from 'react';
import { Container } from '../EventDetail.styled';
import { ItemContainer, Label, DescriptionContainer, EventInfoContainer, Icon } from './EventDetailContent.styled';
import { MapMarker, Video } from '../../../../../theme/assets';

export interface EventDetailContentProps {
  description: string;
  address: string;
  event_url: string;
  formattedStartTimestamp: string;
  formattedEndTimestamp: string;
  companyName: string;
  capacity: number;
}

const EventDetailContent: React.FC<EventDetailContentProps> = ({
  description,
  event_url,
  address,
  formattedStartTimestamp,
  capacity,
  formattedEndTimestamp,
  companyName,
}) => {
  return (
    <Container>
      <EventInfoContainer>
        {companyName}
        <ItemContainer>
          <Label>Start date</Label> {formattedStartTimestamp}
        </ItemContainer>
        <ItemContainer>
          <Label>End date</Label> {formattedEndTimestamp}
        </ItemContainer>
        {capacity > 0 && (
          <ItemContainer>
            <Label>Capacity</Label> {capacity}
          </ItemContainer>
        )}
        {address && (
          <div>
            <Icon src={MapMarker} />
            {address}
          </div>
        )}
        {event_url && (
          <div>
            <Icon src={Video} />
            <a href={`http://${event_url}`} target="_blank" rel="noopener noreferrer">
              {event_url}
            </a>
          </div>
        )}
      </EventInfoContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
    </Container>
  );
};

export default EventDetailContent;
