import gql from 'graphql-tag';

const mutation = gql`
  mutation DeleteInterests(
    $jobs: [String!]!
    $firms: [String!]!
    $regions: [String!]!
    $roles: [String!]!
    $employerPreferences: [String!]!
  ) {
    delete_grad_interest_job_type(where: { interest_job_type_id: { _in: $jobs } }) {
      returning {
        job_type
        interest_job_type_id
      }
    }
    delete_grad_interest_firm(where: { interest_firm_id: { _in: $firms } }) {
      returning {
        firm_type
        interest_firm_id
      }
    }
    delete_grad_interest_region(where: { interest_region_id: { _in: $regions } }) {
      returning {
        region
        interest_region_id
      }
    }
    delete_grad_interest_role(where: { interest_role_id: { _in: $roles } }) {
      returning {
        role
        interest_role_id
      }
    }

    delete_grad_employer_preference(where: { employer_preference_id: { _in: $employerPreferences } }) {
      returning {
        employer_preference_id
        preference
      }
    }
  }
`;

export default mutation;
