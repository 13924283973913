import React from 'react';
import Select, { mergeStyles } from 'react-select';
import { Controller, FormContextValues } from 'react-hook-form';
import { selectorStyle } from 'theme';
import { OptionId } from './Option';

export interface SelectorMultiProps extends Pick<FormContextValues, 'control'> {
  options: OptionId[];
  defaultValue: OptionId[] | null;
  name: string;
  placeholder?: string;
  required?: boolean;
  isClearable?: boolean;
}

const SelectorMulti: React.FC<SelectorMultiProps> = ({
  options,
  defaultValue,
  name,
  control,
  required = true,
  isClearable = false,
  placeholder = 'Select...',
}) => {
  const overrideStyles = {
    container: (base: object) => ({
      ...base,
      height: 'auto',
      minHeight: '3.4rem',
    }),
    control: (base: object) => ({
      ...base,
      height: 'auto',
      minHeight: '3.4rem',
    }),
  };

  const selectorOwnStyles = mergeStyles(selectorStyle, overrideStyles);

  const select = (
    <Select
      options={options}
      isClearable={isClearable}
      isMulti={true}
      maxMenuHeight={180}
      styles={selectorOwnStyles}
      placeholder={placeholder}
    />
  );

  return (
    <Controller
      as={select}
      defaultValue={defaultValue}
      name={name}
      control={control}
      onChange={([selected]) => {
        return { value: selected };
      }}
      rules={{ required }}
    />
  );
};

export default SelectorMulti;
