import { useQuery } from 'react-apollo';
import { GetCompanyByAccount, GetCompanyByAccountVariables } from '../../../../generated/GetCompanyByAccount';
import { GET_COMPANY_BY_ACCOUNT } from '../../../../gql';

export const useCompanyQuery = (skip: boolean, accountId: string) => {
  return useQuery<GetCompanyByAccount, GetCompanyByAccountVariables>(GET_COMPANY_BY_ACCOUNT, {
    skip,
    variables: { accountId },
  });
};
