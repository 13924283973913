import React from 'react';
import CompanyDetail from './CompanyController';
import DetailViewLayout from '../../../components/common/DetailViewLayout';

const Event: React.FC = () => {
  return (
    <DetailViewLayout>
      <CompanyDetail />
    </DetailViewLayout>
  );
};

export default Event;
