import gql from 'graphql-tag';
import { GradProfileParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation WizardCreateProfile(
    $userId: String!
    $firstName: String!
    $lastName: String!
    $fullName: String!
    $email: String!
    $mobile: String!
    $hometown: String
    $title: String!
    $years_of_relevant_work: String!
  ) {
    insert_grad_profile(
      objects: {
        user_id: $userId
        firstname: $firstName
        lastname: $lastName
        fullname: $fullName
        email: $email
        mobile: $mobile
        hometown: $hometown
        title: $title
        years_of_relevant_work: $years_of_relevant_work
      }
      on_conflict: {
        constraint: profile_userid_key
        update_columns: [title, mobile, firstname, lastname, fullname, email, years_of_relevant_work]
      }
    ) {
      returning {
        ...GradProfileParts
      }
    }
  }
  ${GradProfileParts}
`;

export default mutation;
