import React from 'react';
import {
  CardTag,
  CardTagContainer,
  Container,
  Info,
  BookmarkIcon,
  Logo,
  NavLink,
  ChildrenContainer,
} from './Card.styled';
import { EGLogoGrayBackground as DefaultLogo } from '../../../theme/assets';

import JobBookmark from './JobBookmark';

interface Tag {
  text: string;
  color?: string;
}

export interface CardProps {
  header: string;
  tags?: Tag[];
  bookmarked?: boolean;
  deleteBookmark?: () => void;
  insertBookmark?: () => void;
  cardLink: string;
  logo?: string | null;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  logo,
  cardLink,
  bookmarked = false,
  insertBookmark,
  deleteBookmark,
  tags,
  header,
  children,
}) => {
  return (
    <Container>
      {deleteBookmark && insertBookmark && (
        <BookmarkIcon>
          <JobBookmark deleteBookmark={deleteBookmark} insertBookmard={insertBookmark} bookmarked={bookmarked} />
        </BookmarkIcon>
      )}
      <NavLink to={cardLink}>
        <Logo src={logo || DefaultLogo} />
      </NavLink>
      <Info>
        <NavLink to={cardLink}>
          {header}
          <ChildrenContainer>{children}</ChildrenContainer>
          <CardTagContainer>
            {tags?.map((tag) => (
              <CardTag key={tag.text} color={tag.color}>
                {tag.text}
              </CardTag>
            ))}
          </CardTagContainer>
        </NavLink>
      </Info>
    </Container>
  );
};

export default Card;
