import React, { Ref } from 'react';
import styled from 'styled-components';

import palette from 'theme/palette';

const RADIO_BUTTON_SIZE = '2rem';

const StyledRadioButton = styled.div`
  position: relative;
  z-index: 0;

  display: inline-block;

  & > input[type='radio'] {
    position: absolute;
    z-index: 1;

    width: ${RADIO_BUTTON_SIZE};
    height: ${RADIO_BUTTON_SIZE};

    opacity: 0;
  }

  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: ${RADIO_BUTTON_SIZE};
    height: ${RADIO_BUTTON_SIZE};

    border-radius: 50%;
    border-width: 2px;
    border-color: ${palette.red};
    border-style: solid;

    background-color: transparent;

    vertical-align: top;
  }

  & > span > span {
    display: inline-block;

    width: calc(${RADIO_BUTTON_SIZE} - 1.2rem);
    height: calc(${RADIO_BUTTON_SIZE} - 1.2rem);

    border-radius: 50%;
    border-width: 0px;

    background-color: ${palette.red};
    opacity: 0;
  }

  & > input[type='radio']:checked ~ span > span {
    opacity: 1;
  }
`;

export interface RadioButtonProps {
  defaultChecked?: boolean;
  name: string;
  value: string;
  inputRef?: Ref<HTMLInputElement>;
}

const RadioButton: React.FC<RadioButtonProps> = ({ inputRef, ...props }) => {
  return (
    <StyledRadioButton>
      <input type="radio" ref={inputRef} {...props} />
      <span>
        <span />
      </span>
    </StyledRadioButton>
  );
};

export default RadioButton;
