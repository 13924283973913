import styled from 'styled-components';
import { palette } from '../../../../../theme';

export const TimeLeft = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${palette.gray};
`;
