import gql from 'graphql-tag';

const query = gql`
  mutation SetWizardComplete($myUserId: String!) {
    update_grad_profile(where: { user_id: { _eq: $myUserId } }, _set: { wizard_completed: true }) {
      returning {
        wizard_completed
        user_id
      }
    }
  }
`;

export default query;
