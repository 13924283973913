export enum AwardsTypes {
  ACADEMIC_HONOR = 'Academic honor',
  PROFESSIONAL_DESIGNATION = 'Professional designation',
}

export const ACADEMIC_HONORS_OPTIONS = [
  { value: 'Cum Laude', label: 'Cum Laude' },
  { value: 'Magna Cum Laude', label: 'Magna Cum Laude' },
  { value: 'Summa Cum Laude', label: 'Summa Cum Laude' },
];

export const PROFESSIONAL_DESIGNATIONS_OPTIONS = [
  { value: 'CPA', label: 'CPA' },
  { value: 'CAIA', label: 'CAIA' },
  { value: 'CFA', label: 'CFA' },
  { value: 'CQF', label: 'CQF' },
  { value: 'FRM', label: 'FRM' },
  { value: 'CRM', label: 'CRM' },
];
