import gql from 'graphql-tag';

import { CompanyParts } from './fragments.gql';

export default gql`
  query GetCompanyByAccount($accountId: String!) {
    recruit_company(where: { account_id: { _eq: $accountId } }) {
      ...CompanyParts
    }
  }
  ${CompanyParts}
`;
