import React from 'react';
import styled from 'styled-components';
import { palette } from 'theme';
import { GetMyTestScores_grad_test_score } from 'generated/GetMyTestScores';

export interface TestProps {
  testScore: GetMyTestScores_grad_test_score;
}

const TestScoreCardSingle: React.FC<TestProps> = ({ testScore }) => {
  return (
    <StyledTestScoreCard>
      <TestScoreTitle>{testScore.test}:</TestScoreTitle>
      <Numerator>{testScore.score}</Numerator>
      <Denominator>{`/${testScore.outof}`}</Denominator>
    </StyledTestScoreCard>
  );
};

const StyledTestScoreCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  margin: 0.6rem 0;
  padding: 0.8rem;
  border-radius: 8px;

  background: ${palette.clearGray};
`;

const TestScoreTitle = styled.div`
  width: 10rem;

  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2rem;
`;

const Numerator = styled.span`
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2rem;
`;

const Denominator = styled.span`
  color: ${palette.brownGrey};
`;

export default TestScoreCardSingle;
