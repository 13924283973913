import styled from 'styled-components';

export const AvatarContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;
`;

export const ScaleInput = styled.input.attrs({
  type: 'range',
  step: '0.01',
  min: '1',
  max: '2',
  name: 'scale',
})`
  margin-top: 1rem;
  width: 100%;
`;
