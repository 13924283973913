import {
  GetMyTestScores_grad_test_score,
  GetMyTestScores_grad_test_score_test_score_details,
  GetMyTestScores,
} from 'generated/GetMyTestScores';
import {
  grad_test_score_detail_insert_input,
  grad_test_score_insert_input,
  grad_test_score_detail_constraint,
  grad_test_score_detail_update_column,
} from 'generated/globalTypes';
import { isNullOrUndefined } from 'util';
import { TestTypes, TestSATDetailsOutof, TestSATDetailsTypes, TestOutOf } from 'data/tests';

export interface ParsedTests {
  [key: string]: GetMyTestScores_grad_test_score & {
    test_score_details?: { [key: string]: GetMyTestScores_grad_test_score_test_score_details };
  };
}

export const parseDetails = (testDetails: GetMyTestScores_grad_test_score_test_score_details[]) =>
  testDetails.reduce((obj, testDetailData) => ({ ...obj, [testDetailData.detail]: testDetailData }), {});

export const parseTestsObj = (tests: GetMyTestScores): { [key: string]: GetMyTestScores_grad_test_score } =>
  tests.grad_test_score.reduce(
    (obj, testData) => ({
      ...obj,
      [testData.test]: testData,
    }),
    {},
  );

export const parseTests = (tests: GetMyTestScores): ParsedTests =>
  tests.grad_test_score.reduce(
    (obj, testData) => ({
      ...obj,
      [testData.test]:
        testData.test === TestTypes.SAT
          ? { ...testData, test_score_details: parseDetails(testData.test_score_details) }
          : testData,
    }),
    {},
  );

const generateSAT = (
  satMathScore: number | undefined,
  testsData: ParsedTests,
  satReadingScore: number | undefined,
  satWritingScore: number | undefined,
  user_id: string,
) => {
  const SATMath: grad_test_score_detail_insert_input = {
    score: satMathScore,
    detail: TestSATDetailsTypes.MATH,
    outof: TestSATDetailsOutof.MATH,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.math && {
        test_score_detail_id: testsData.SAT.test_score_details.math.test_score_detail_id,
      }),
  };
  const SATReading: grad_test_score_detail_insert_input = {
    score: satReadingScore,
    detail: TestSATDetailsTypes.READING,
    outof: TestSATDetailsOutof.READING,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.reading && {
        test_score_detail_id: testsData.SAT.test_score_details.reading.test_score_detail_id,
      }),
  };
  const SATWriting: grad_test_score_detail_insert_input = {
    score: satWritingScore,
    detail: TestSATDetailsTypes.WRITING,
    outof: TestSATDetailsOutof.WRITING,
    ...(testsData.SAT &&
      testsData.SAT.test_score_details.writing && {
        test_score_detail_id: testsData.SAT.test_score_details.writing.test_score_detail_id,
      }),
  };
  const SATDetails: grad_test_score_detail_insert_input[] = [SATMath, SATReading, SATWriting].filter(
    ({ score }) => score,
  );

  const SATOutof: number = SATDetails.reduce((acc, { score, outof = 0 }) => {
    return score && outof ? acc + outof : acc;
  }, 0);

  const SAT: grad_test_score_insert_input = {
    test: TestTypes.SAT,
    ...(testsData.SAT && { test_score_id: testsData.SAT.test_score_id }),
    outof: SATOutof,
    user_id,
    score: (satMathScore || 0) + (satReadingScore || 0) + (satWritingScore || 0),
    test_score_details: {
      data: SATDetails,
      on_conflict: {
        constraint: grad_test_score_detail_constraint.test_score_detail_test_score_detail_id_key,
        update_columns: [grad_test_score_detail_update_column.score],
      },
    },
  };
  return SAT;
};

export const generateTests = ({
  testsData,
  satMathScore,
  satReadingScore,
  satWritingScore,
  user_id,
  gmatScore,
  greScore,
  lsatScore,
  mcatScore,
  actScore,
}: {
  testsData: ParsedTests;
  user_id: string;
  satMathScore: number | undefined;
  satReadingScore: number | undefined;
  satWritingScore: number | undefined;
  gmatScore: number | undefined;
  greScore: number | undefined;
  lsatScore: number | undefined;
  mcatScore: number | undefined;
  actScore: number | undefined;
}) => {
  const SAT: grad_test_score_insert_input = generateSAT(
    satMathScore,
    testsData,
    satReadingScore,
    satWritingScore,
    user_id,
  );
  const GMAT: grad_test_score_insert_input = {
    ...(testsData.GMAT && { test_score_id: testsData.GMAT.test_score_id }),
    test: TestTypes.GMAT,
    outof: TestOutOf.GMAT,
    user_id,
    score: gmatScore,
  };
  const GRE: grad_test_score_insert_input = {
    ...(testsData.GRE && { test_score_id: testsData.GRE.test_score_id }),
    test: TestTypes.GRE,
    outof: TestOutOf.GRE,
    user_id,
    score: greScore,
  };
  const LSAT: grad_test_score_insert_input = {
    ...(testsData.LSAT && { test_score_id: testsData.LSAT.test_score_id }),
    test: TestTypes.LSAT,
    outof: TestOutOf.LSAT,
    user_id,
    score: lsatScore,
  };
  const MCAT: grad_test_score_insert_input = {
    ...(testsData.MCAT && { test_score_id: testsData.MCAT.test_score_id }),
    test: TestTypes.MCAT,
    outof: TestOutOf.MCAT,
    user_id,
    score: mcatScore,
  };

  const ACT: grad_test_score_insert_input = {
    ...(testsData.ACT && { test_score_id: testsData.ACT.test_score_id }),
    test: TestTypes.ACT,
    outof: TestOutOf.ACT,
    user_id,
    score: actScore,
  };

  const formTests = [SAT, GRE, LSAT, MCAT, GMAT, ACT];

  const toUpsert = formTests.filter(({ score }) => score);

  const SATReadingId =
    testsData.SAT &&
    testsData.SAT.test_score_details.reading &&
    testsData.SAT.test_score_details.reading.test_score_detail_id;

  const toDeleteDetail = !satReadingScore && SATReadingId ? [SATReadingId] : [];

  const toDelete = formTests.reduce<string[]>(
    (accum, test) =>
      !test.score && !isNullOrUndefined(test.test_score_id) ? (accum = [...accum, test.test_score_id]) : accum,
    [],
  );

  return { toUpsert, toDelete, toDeleteDetail };
};
