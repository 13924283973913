import styled from 'styled-components';
import { palette } from '../../../theme';

export const Header = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 1rem;
`;
export const Logo = styled.img`
  border-radius: 50%;
  width: 8rem;
  height: 8rem;

  border: 2px solid ${palette.watermelon};
`;

export const HeaderInfo = styled.div`
  flex: 1;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > span {
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.3rem;
  }
`;

export const TagContainer = styled.div`
  display: flex;
`;

interface TagProps {
  color?: string;
}

export const Tag = styled.div<TagProps>`
  display: inline-block;

  padding: 0.1rem 0.5rem;
  margin-right: 0.7rem;
  border-radius: 6px;

  background: ${(props) => props.color || palette.blue};

  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${palette.white};
`;

export const TitleDetail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;

  font-size: 2.3rem;
  line-height: 2.7rem;
`;

export const ExtraInfoHeader = styled.div`
  padding: 0.4rem;
`;
