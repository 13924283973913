import gql from 'graphql-tag';
import { TestScorePart } from './fragments.gql';

const mutation = gql`
  mutation UpsertTestsScores($tests: [grad_test_score_insert_input!]!) {
    insert_grad_test_score(
      on_conflict: { constraint: test_score_user_id_test_score_id_key, update_columns: [score, outof] }
      objects: $tests
    ) {
      returning {
        ...TestScorePart
      }
    }
  }
  ${TestScorePart}
`;

export default mutation;
