import React from 'react';
import { useHistory } from 'react-router-dom';

import WizardContainer from '../components/WizardContainer';
import { WizardStepTitle as Title } from '../components';
import { WizardProps } from '../components/interface';

import { HOME_URL } from '../components/constants';

import { Button, Container, Text } from './AlmostThereStep.styled';

const AlmostThereStep: React.FC<WizardProps> = ({ goToDone = HOME_URL, nextGoTo = HOME_URL, step, totalSteps }) => {
  const history = useHistory();

  return (
    <WizardContainer step={step} totalSteps={totalSteps}>
      <Container>
        <Title>Almost There!</Title>
        <Text>The following information is optional for your profile.</Text>
        <Text>Complete profiles are 67% more likely to be clicked on by a Hiring Manager!</Text>
        <Button onClick={() => history.push(nextGoTo)}>Click Here to Continue</Button>
        <Text>Your profile can always be modified/edited by visiting students.elitegrad.com</Text>
        <Button onClick={() => history.push(goToDone)}>Create Basic Account and Skip for Now</Button>
      </Container>
    </WizardContainer>
  );
};

export default AlmostThereStep;
