import gql from 'graphql-tag';
import { WorkExperienceParts } from 'gql/fragments.gql';

const mutation = gql`
  mutation UpdateMyWork(
    $workId: String!
    $company: String
    $position: String
    $division: String
    $jobType: String
    $current: Boolean
    $startMonth: Int
    $startYear: numeric
    $endMonth: Int
    $endYear: numeric
    $city: String
    $state: String
  ) {
    update_grad_work_experience(
      where: { work_experience_id: { _eq: $workId } }
      _set: {
        company: $company
        end_month_numeric: $endMonth
        end_year: $endYear
        job_type: $jobType
        position: $position
        division: $division
        current: $current
        start_month_numeric: $startMonth
        start_year: $startYear
        city: $city
        state: $state
      }
    ) {
      returning {
        ...WorkExperienceParts
      }
    }
  }
  ${WorkExperienceParts}
`;

export default mutation;
