import styled from 'styled-components';
import { palette } from '../../../../theme';

export const TimeLeft = styled.div`
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${palette.gray};
`;

export const ItemContainer = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${palette.gray};
  padding: 0.2rem;
`;

export const Label = styled.span`
  font-weight: bold;
  color: ${palette.gray};
`;

export const RegisteredTag = styled.span`
  padding: 0.1rem 0.5rem;
  border-radius: 6px;
  background-color: ${palette.red};
  color: ${palette.white};
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: bold;
  text-align: center;
`;
