import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { StyledForm, StyledFormInputs, StyledInputLabel, StyledButtons } from './library';

import { OptionId, createOptionId } from 'components/Option';

import { GET_STUDENT_PROFILE, UPDATE_RELATED_PROFILE_SKILLS } from 'gql';
import { GetStudentProfile, GetStudentProfileVariables } from 'generated/GetStudentProfile';

import TechnicalLanguageSelector from 'components/TechnicalLanguageSelector';
import { Spinner, CancelButton, SaveButton } from 'components';
import { UpdateRelatedSkillsProfile, UpdateRelatedSkillsProfileVariables } from 'generated/UpdateRelatedSkillsProfile';

interface ProfileSkillsTabVariables {
  computerLanguages: { current: OptionId[]; newOptions: OptionId[]; deletedOptionsIds: string[] };
}

const ProfileSkillsTab: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const { control, register, handleSubmit, setValue } = useForm<ProfileSkillsTabVariables>();

  const { loading: getProfileLoading, data: getProfileData } = useQuery<GetStudentProfile, GetStudentProfileVariables>(
    GET_STUDENT_PROFILE,
    {
      variables: { myUserId: user.sub },
      errorPolicy: 'all',
      onError: () => undefined,
    },
  );

  const [updateRelatedProfileSkills, { loading: updateRelatedProfileLoading }] = useMutation<
    UpdateRelatedSkillsProfile,
    UpdateRelatedSkillsProfileVariables
  >(UPDATE_RELATED_PROFILE_SKILLS, {
    errorPolicy: 'all',
    onError: () => undefined,
    update: (cache, { data: updateRelatedData }) => {
      const profileCache = cache.readQuery<GetStudentProfile, GetStudentProfileVariables>({
        query: GET_STUDENT_PROFILE,
        variables: { myUserId: user.sub },
      });

      const [oldGradProfile] = profileCache?.grad_profile || [];

      if (!oldGradProfile) return;

      if (!updateRelatedData?.insert_grad_computer_language || !updateRelatedData?.delete_grad_computer_language) {
        return;
      }

      const deletedLanguageIds = updateRelatedData.delete_grad_computer_language.returning.map(
        ({ computer_language_id }: { computer_language_id: string }) => computer_language_id,
      );
      const newLanguages = [
        ...oldGradProfile.computer_languages,
        ...updateRelatedData.insert_grad_computer_language.returning,
      ].filter(({ computer_language_id }) => !deletedLanguageIds.includes(computer_language_id));

      const newGradProfile = {
        ...oldGradProfile,
        computer_languages: newLanguages,
      };

      cache.writeQuery({
        query: GET_STUDENT_PROFILE,
        variables: { myUserId: user.sub },
        data: {
          grad_profile: [newGradProfile],
        },
      });
    },
  });

  const myInfo = getProfileData?.grad_profile[0];

  if (getProfileLoading) return <Spinner />;

  const computerLanguagesOptions = (myInfo?.computer_languages || []).map((language) =>
    createOptionId(language.language, language.computer_language_id),
  );

  const onSubmit = async (data: ProfileSkillsTabVariables) => {
    const {
      computerLanguages: { newOptions: newComputerLanguagesOptions, deletedOptionsIds: deleteComputerLanguagesIds },
    } = data;

    const technicalLanguagesForUpsert = newComputerLanguagesOptions.map(({ label }) => ({
      user_id: user.sub,
      language: label,
    }));

    await updateRelatedProfileSkills({
      variables: {
        technicalLanguagesForDelete: deleteComputerLanguagesIds,
        technicalLanguagesForUpsert,
      },
    });

    history.goBack();
  };

  if (updateRelatedProfileLoading) return <Spinner />;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledFormInputs>
        <StyledInputLabel>Technical Languages</StyledInputLabel>
        <TechnicalLanguageSelector
          name="computerLanguages"
          control={control}
          register={register}
          setValue={setValue}
          defaultValue={computerLanguagesOptions}
        />
      </StyledFormInputs>
      <StyledButtons>
        <CancelButton onClick={history.goBack}>Cancel</CancelButton>
        <SaveButton>Save</SaveButton>
      </StyledButtons>
    </StyledForm>
  );
};

export default ProfileSkillsTab;
